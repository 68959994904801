import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.css']
})
export class SharedComponent implements OnInit {

  state_list: any;
  profile_list: string[];
  PROFILE_ID = '[A-Za-z0-9_\\-\\.]+';

  constructor() { }

  ngOnInit(): void {
  }

  stateList() {
    this.state_list = [{ "code": "AN", "name": "Andaman and Nicobar Islands" },
    { "code": "AP", "name": "Andhra Pradesh" },
    { "code": "AR", "name": "Arunachal Pradesh" },
    { "code": "AS", "name": "Assam" },
    { "code": "BR", "name": "Bihar" },
    { "code": "CG", "name": "Chandigarh" },
    { "code": "CH", "name": "Chhattisgarh" },
    { "code": "DH", "name": "Dadra and Nagar Haveli" },
    { "code": "DD", "name": "Daman and Diu" },
    { "code": "DL", "name": "Delhi" },
    { "code": "GA", "name": "Goa" },
    { "code": "GJ", "name": "Gujarat" },
    { "code": "HR", "name": "Haryana" },
    { "code": "HP", "name": "Himachal Pradesh" },
    { "code": "JK", "name": "Jammu and Kashmir" },
    { "code": "JH", "name": "Jharkhand" },
    { "code": "KA", "name": "Karnataka" },
    { "code": "KL", "name": "Kerala" },
    { "code": "LD", "name": "Lakshadweep" },
    { "code": "MP", "name": "Madhya Pradesh" },
    { "code": "MH", "name": "Maharashtra" },
    { "code": "MN", "name": "Manipur" },
    { "code": "ML", "name": "Meghalaya" },
    { "code": "MZ", "name": "Mizoram" },
    { "code": "NL", "name": "Nagaland" },
    { "code": "OR", "name": "Odisha" },
    { "code": "PY", "name": "Puducherry" },
    { "code": "PB", "name": "Punjab" },
    { "code": "RJ", "name": "Rajasthan" },
    { "code": "SK", "name": "Sikkim" },
    { "code": "TN", "name": "Tamil Nadu" },
    { "code": "TS", "name": "Telangana" },
    { "code": "TR", "name": "Tripura" },
    { "code": "UK", "name": "Uttarakhand" },
    { "code": "UP", "name": "Uttar Pradesh" },
    { "code": "WB", "name": "West Bengal" }]

    return this.state_list;
  }

  getAge(born) {
    let today = new Date();
    let birthDate = new Date(born);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  socailProfileList() {
    this.profile_list = ['Facebook', 'YouTube', 'WhatsApp', 'WeChat', 'Instagram', 'Tumblr', 'Twitter', 'Reddit', 'Linkedin', 'Snapchat', 'Pinterest'];
    return this.profile_list;
  }



  validateSocialProfiles(profile: string, url: string) {
    let arrayToMatch = [];
    switch (profile) {
      case 'Facebook':
        arrayToMatch = ['https://www.facebook.com/' + this.PROFILE_ID, 'https://m.facebook.com/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }


      case 'YouTube':
        arrayToMatch = ['https://www.youtube.com/channel/' + this.PROFILE_ID, 'https://m.youtube.com/c/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'Twitter':
        arrayToMatch = ['https://twitter.com/' + this.PROFILE_ID, 'https://mobile.twitter.com/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }


      case 'Linkedin':
        arrayToMatch = ['https://www.linkedin.com/in/' + this.PROFILE_ID, 'https://www.linkedin.com/mwlite/in/' + this.PROFILE_ID, 'https://in.linkedin.com/company/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'Instagram':
        arrayToMatch = ['https://www.instagram.com/' + this.PROFILE_ID, 'https://m.instagram.com/' + this.PROFILE_ID]
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'WhatsApp':
        arrayToMatch = ['https://wa.me/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }


      case 'WeChat':
        arrayToMatch = ['https://u.wechat.com/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }


      case 'Tumblr':
        arrayToMatch = ['https://' + this.PROFILE_ID + '.tumblr.com'];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'Reddit':
        arrayToMatch = ['https://www.reddit.com/user/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'Pinterest':
        arrayToMatch = ['https://www.pinterest.com/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }

      case 'Snapchat':
        arrayToMatch = ['https://www.snapchat.com/add/' + this.PROFILE_ID];
        var result = arrayToMatch.reduce((a, b) => a || RegExp(b).test(url), false)
        if (result == true) {
          return true;
        } else {
          return false;
        }
    }
  }


  languagesList() {
    let langugages = ['English', 'Hindi', 'Punjabi', 'Tamil', 'Mandarin Chinese', 'Spanish', 'Bengali', 'Portuguese', 'Russian', 'Japanese', 'Marathi', 'Telugu', 'Korean', 'French', 'Italian', 'Gujarati'];
    return langugages;
  }

  departmentList() {
    let list = ['Developer', 'Sales', 'Blogger', 'Dentist'];
    return list;
  }
  locationList() {
    let list = ['Delhi', 'Pune', 'Mumbai', 'Dehradun', 'Bheemtal'];
    return list;
  }

}
