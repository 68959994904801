import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';


@Component({
  selector: 'app-course-suggestions',
  templateUrl: './course-suggestions.component.html',
  styleUrls: ['./course-suggestions.component.css']
})
export class CourseSuggestionsComponent implements OnInit {

  isLoading: boolean = false;
  suggested_courses: any = [];
  config: any;
  totalRecords: number = 0;
  allDiscipline: any = [];
  discipline_list;

  constructor(private _header: HeaderComponent) { }

  async ngOnInit() {

    this.isLoading = true;
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.totalRecords
    };

   // this.allDiscipline = await this._header.disciplineList();
    let data = await this._header.allSuggestedCourses();             //suggest courses to aspirant based on cuurentQualifications//

    if (data.length > 0) {
      this.suggested_courses = data;
      this.discipline_list = data;
      this.config.totalItems = data.length;

    } else {
      this.suggested_courses = [];
      this.config.totalItems = 0;
    }
    this.isLoading = false;
  }

  pageChanged(event) {
    window.scroll(0, 0);
    this.config.currentPage = event;
  }

  coursesOfDiscipline(selected_discipline: string) {
    if (selected_discipline != '') {
      let courseInfo = JSON.parse(sessionStorage.getItem('course_suggestions'));
      console.log('courseInfo ', courseInfo);
      let courseResult = courseInfo.filter(o => o.discipline === selected_discipline);
      this.suggested_courses = courseResult;
      this.config.totalItems = courseResult.length;
    } else {
      this.ngOnInit();
    }
    this.isLoading = false;
  }

}
