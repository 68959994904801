<app-header *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/home' && router.url != '/quiz'"></app-header>


<router-outlet *ngIf="router.url == '/login' || router.url == '/signup' || router.url == '/home';else dashboardSection"></router-outlet>

<ng-template #dashboardSection>
<div class="container" *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/home'">
    <div class="row mt-4">
        <!-- <app-sidebar class="col-lg-9" ></app-sidebar> -->
        <div class="col-lg-12">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>
</ng-template>

<app-footer *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/home'"></app-footer>