import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { HeaderComponent } from '../header/header.component';
import { NgForm } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, first, } from 'rxjs/operators';
import { Educational } from '../models/educational.model';
import { Skills } from '../models/skills.model';
import { Interests } from '../models/interests.model'
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { environment } from '../../environments/environment';

declare var $: any;
declare var Razorpay: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  userInfo;
  eduDetails: object = {};
  isLoading: boolean = false;
  msg: boolean = false;
  skills;
  interest;
  detailsid: string;
  qualifications;
  aspirantAllQualification = [];
  suggested_courses = [];
  suggested_exams = [];
  selectedExam;
  uniqueExtrnalId: string;
  courseConfig: any;
  examConfig: any;
  totalCourseRecords: number = 0;
  totalExamRecords: number = 0;
  filtered_suggested_exams = [];
  selectedIndex;
  wait: boolean = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searchInterest = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.interest
        : this.interest.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  searchSkills = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.skills
        : this.skills.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }


  constructor(private location: LocationStrategy, private _profileService: ManageProfileService, private _header: HeaderComponent, private afAuth: AngularFireAuth, private firestore: AngularFirestore) {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
    }
    $('.modal-backdrop').remove();
    $('.modal-open').css('overflow', 'visible');

    this.courseConfig = {
      id: 'pagination2',
      itemsPerPage: 3,
      currentPage: 1,
      totalItems: 0
    };

    this.examConfig = {
      id: 'pagination1',
      itemsPerPage: 4,
      currentPage: 1,
      totalItems: 0
    };
  }



  async ngOnInit() {

    this.uniqueExtrnalId = 'education_' + uuid();
    $(document).ready(() => {

      var current_fs, next_fs, previous_fs;
      var left, opacity, scale;
      var animating;

      $(".next").click(function () {

        if (animating) return false;
        animating = true;
        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            scale = 1 - (1 - now) * 0.2;
            left = now * 50 + "%";
            opacity = 1 - now;
            current_fs.css({
              'transform': 'scale(' + scale + ')',
              'position': 'absolute'
            });
            next_fs.css({ 'left': left, 'opacity': opacity });
          },
          duration: 800,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          easing: 'easeInOutBack'
        });
      });

      $(".previous").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        previous_fs.show();
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            scale = 0.8 + (1 - now) * 0.2;
            left = (1 - now) * 50 + "%";
            opacity = 1 - now;
            current_fs.css({ 'left': left });
            previous_fs.css({ 'transform': 'scale(' + scale + ')', 'opacity': opacity });
          },
          duration: 800,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          //this comes from the custom easing plugin
          easing: 'easeInOutBack'
        });

      });
    })

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getEducationStatus();

    this._profileService.refreshCourseNeeded$.subscribe(() => {
      console.log('apiRUN');
      this.getEducationStatus();
    })

  }

  coursePageChanged(event) {
    this.courseConfig.currentPage = event;
  }


  examPageChanged(event) {
    console.log(event);
    this.examConfig.currentPage = event;
  }


  async totalQualification() {
    this.qualifications = await this._profileService.getQualificationList('educational_qualification');
  }


  async getEducationStatus() {

    this.isLoading = true;
    this.wait = true;

    await this.afAuth.onAuthStateChanged(async (user) => {

      if (user != null) {

        let result = await this._header.getDetails();
        if (result != undefined && result['aspirant_education'] != undefined && result['aspirant_education']['Education'] != undefined) {

          this.detailsid = result.id;
          let coursedata = await this._header.allSuggestedCourses();  //suggest courses to aspirant based on cuurentHighestQualifications//
          if (coursedata.length > 0) {
            this.suggested_courses = coursedata;
            this.courseConfig.totalItems = coursedata.length;
          } else {
            this.suggested_courses = [];
            this.courseConfig.totalItems = 0;
          }

          let examdata = await this._header.allSuggestedExams();  //suggest exams to aspirant based on cuurentHighestQualifications//

          if (examdata.length > 0) {
            this.suggested_exams = examdata;
            this.selectedExamFilter(examdata[0].job_sector, 0);
          } else {
            this.suggested_exams = [];
          }
          this.wait = false;
          this.isLoading = false;

        } else {

          console.log('StartSHOW');
          $("#IntroModal").modal('show');
          this.totalQualification();
          this.getAllSkills();
          this.getAllInterest();
          this.detailsid = (result == undefined) ? '' : result.id;
          this.isLoading = false;
          this.wait = false;
        }
      } else {
        this._header.signOut();
      }
    })
  }


  async selectedExamFilter(selected_exam: string, index) {

    this.examConfig.currentPage = 1;
    this.selectedIndex = index;
    this.selectedExam = selected_exam;
    let examInfo = JSON.parse(sessionStorage.getItem('exam_suggestions'));
    let examResult = examInfo.filter(o => o.job_sector === selected_exam);
    this.filtered_suggested_exams = examResult;
    this.examConfig.totalItems = examResult.length;
  }



  async addDetails(form: NgForm) {

    this.isLoading = true;

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let enteredQualification = form.value['highest_qualification'];
    let skills = form.value['skills'];
    let interest = form.value['interested_carrer'];

    let userQualification: Educational = {
      Education: [{
        highest_qualification: enteredQualification,
        course: '',
        university: '',
        course_type: '',
        year_of_passing: '',
        medium_of_study: '',
        percentage: '',
        external_education_id: this.uniqueExtrnalId,
        subjects: [
          {
            name: '',
            marks: '',
            external_id: this.uniqueExtrnalId,
            education_subject_id: uuid()
          }
        ],
      }],
      HighestQualification: enteredQualification,
      AllEducation: [enteredQualification],
    }

    if (enteredQualification != '') {

      let geteducationalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (geteducationalDetails != null) {

        geteducationalDetails['aspirant_personal'] = geteducationalDetails.aspirant_personal ? geteducationalDetails.aspirant_personal : {};
        geteducationalDetails['aspirant_education'] = userQualification;
        geteducationalDetails['aspirant_skills'] = geteducationalDetails.aspirant_skills ? geteducationalDetails.aspirant_skills : {};
        geteducationalDetails['aspirant_fitness'] = geteducationalDetails.aspirant_fitness ? geteducationalDetails.aspirant_fitness : {};
        geteducationalDetails['aspirant_interests'] = geteducationalDetails.aspirant_interests ? geteducationalDetails.aspirant_interests : {};

      } else {

        geteducationalDetails = {};
        geteducationalDetails['aspirant_personal'] = {};
        geteducationalDetails['aspirant_education'] = userQualification;
        geteducationalDetails['aspirant_skills'] = {};
        geteducationalDetails['aspirant_fitness'] = {};
        geteducationalDetails['aspirant_interests'] = {};
      }


      geteducationalDetails['created_by'] = this.userInfo['uid'];
      geteducationalDetails['modified_by'] = this.userInfo['uid'];
      geteducationalDetails['created_date_time'] = new Date();
      geteducationalDetails['modified_date_time'] = new Date();
      geteducationalDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      if (this.detailsid != '') {
        console.log('update education')
        let educationResult = await this._profileService.updateEducationalDetails('aspirants', this.detailsid, geteducationalDetails);

      } else {
        console.log('insert education')
        let educationResult = await this._profileService.addEducationalDetails('aspirants', geteducationalDetails);
      }
      let aspirantEducationData = await this._header.getDetails();

      if (aspirantEducationData != undefined) {
        if (skills != '' || interest != '') {
          this.addSkills(skills, interest, aspirantEducationData)
        }
      }
      this.getEducationStatus();
      $("#IntroModal").modal('hide');
      this.isLoading = false;
    } else {
      if (skills != '' || interest != '') {
        this.addSkills(skills, interest, {})
      }

      $("#IntroModal").modal('hide');
      this.isLoading = false;
    }
  }

  async addSkills(skillDetails, interestDetails, updatedData) {

    if (skillDetails != '') {
      let userSkills: Skills = {
        Skills: [{
          name: skillDetails,
          experience: '',
          external_id: 'aspirant_skills_' + uuid()
        }],
      }

      if (Object.keys(updatedData).length != 0) {

        console.log('yaha aa gaya');
        updatedData['aspirant_skills'] = userSkills;
        updatedData['created_by'] = this.userInfo['uid'];
        updatedData['modified_by'] = this.userInfo['uid'];
        updatedData['operation'] = 'created';
        updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

        this.detailsid = updatedData.id;

      } else {

        console.log('sab kahali hai');
        updatedData['aspirant_personal'] = {};
        updatedData['aspirant_education'] = {};
        updatedData['aspirant_skills'] = userSkills;
        updatedData['aspirant_fitness'] = {};
        updatedData['aspirant_interests'] = {};
      }

      this.isLoading = true;

      if (this.detailsid != '') {
        console.log('update hua skills');
        let skillResult = await this._profileService.updateSkillsDetails('aspirants', this.detailsid, updatedData);
      } else {
        console.log('insert hua skills');
        let skillResult = await this._profileService.addSkillsDetails('aspirants', updatedData);
      }

      let aspirantSkillsData = await this._header.getDetails();

      if (aspirantSkillsData != undefined) {
        if (interestDetails != '') {
          this.addInterest(interestDetails, aspirantSkillsData);
        }
      }
      this.msg = false;
      this.isLoading = false;
      $("#IntroModal").modal('hide');
    } else {
      if (interestDetails != '') {
        this.addInterest(interestDetails, updatedData);
      }
    }
  }

  async addInterest(interestDetails, updatedData) {

    if (interestDetails != '') {

      let userInterest: Interests = {
        interested_carrer: interestDetails,
        job_type: '',
        department: '',
        career_type: '',
        desired_location: '',
        external_id: 'aspirant_interest_' + uuid()
      }

      if (Object.keys(updatedData).length != 0) {

        console.log('yaha aa gaya');
        updatedData['aspirant_interests'] = userInterest;
        updatedData['created_by'] = this.userInfo['uid'];
        updatedData['modified_by'] = this.userInfo['uid'];
        updatedData['operation'] = 'created';
        updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;
        this.detailsid = updatedData.id;

      } else {

        console.log('sab kahali hai inreste me');
        updatedData['aspirant_personal'] = {};
        updatedData['aspirant_education'] = {};
        updatedData['aspirant_skills'] = {};
        updatedData['aspirant_fitness'] = {};
        updatedData['aspirant_interests'] = userInterest;
      }


      updatedData['created_by'] = this.userInfo['uid'];
      updatedData['modified_by'] = this.userInfo['uid'];
      updatedData['operation'] = 'created';
      updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      if (this.detailsid != '') {
        console.log('update hua interest');
        let interesrResult = await this._profileService.updateInterestDetails('aspirants', this.detailsid, updatedData);
      } else {
        console.log('insert hua interest');
        let interesrResult = await this._profileService.addInterestDetails('aspirants', updatedData);
      }

      this._header.getDetails();
      $("#IntroModal").modal('hide');
      this.isLoading = false;
    }
  }


  getAllSkills() {

    this.isLoading = true;
    this.afAuth.currentUser.then(async user => {
      if (user != null) {
        let result = await this._profileService.getSkillList('skills');
        if (result.length > 0) {
          this.skills = result[0]['name']
          this.isLoading = false;
        }
      }
    });
  }

  getAllInterest() {

    this.isLoading = true;
    this.afAuth.currentUser.then(async user => {
      if (user != null) {
        let result = await this._profileService.getInterestList('interests');
        if (result.length > 0) {
          this.interest = result[0]['name'];
        }
        this.isLoading = false;
      }
    });
  }

  async Checkout() {

    var name = this.userInfo['displayName'];
    var email = this.userInfo['email'];

    let details = {
      "name": name,
      "email": email
    };
    console.log('email ', details);
    let orderResponse = await this.CreateOrder();
    if (orderResponse.Status == 'Success') {
      var orderid = orderResponse.Data;
      console.log('orderid ', orderid);
    }
    this.isLoading = false;
    var ref = this;
    //let orderid = 'order_G7KdUh4XeGtuby';

    //payment paramter to pass in razor pay object 
    const options = {
      key: environment.razorPayApiKeyId,
      //amount: "300", // 300 paise  //dont provide in case of orderid
      order_id: orderid,
      name: "Open Naukari",
      description: "Payment For Open Naukari",
      handler: function (response: object) {
        console.log('resp  ', response);
        ref.VerifyPayment(response);
      },
      prefill: details,
      theme: {
        "color": "#0478a7"
      }
    };
    console.log('options ', options);

    //Open Gateway
    const rzp = await new Razorpay(options);
    rzp.open();
  }


  async CreateOrder() {
    this.isLoading = true;
    let order_request = {
      "amount": environment.orderAmount,
      "currency": "INR",
    }
    console.log('order_request ', order_request);
    try {
      let order_id = await this._profileService.createOrder(order_request);
      console.log('orderid ', order_id);
      return order_id;
    } catch (errorResponse) {
      this.isLoading = false;
      return errorResponse.error['Error'];
    }
  }

  async VerifyPayment(checkout_response: object) {
    try {

      checkout_response['amount'] = environment.orderAmount;

      console.log('verification started ', checkout_response);
      this.isLoading = true;
      let paymentResponse = await this._profileService.verifyPayment(checkout_response);

      if (paymentResponse['Status'] == 'Success') {

        if (paymentResponse['Data'].hasOwnProperty('id') && paymentResponse['Data']['status'] == 'captured') {

          //save details in db
          let parseAmount = Math.round(paymentResponse['Data']['amount']) / 100;
          let detailsToSavedInDB = {
            payment_id: paymentResponse['Data']['id'],
            order_id: paymentResponse['Data']['order_id'],
            captured: paymentResponse['Data']['captured'],
            payment_date: paymentResponse['Data']['created_at'],
            amount: parseAmount.toFixed(2),
            status: paymentResponse['Data']['status'],
            currency: paymentResponse['Data']['currency'],
            userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref
          }

          console.log('detailsToSavedInDB ', detailsToSavedInDB);
          let paymentDBResult = await this._profileService.addPaymentDetails('payments', detailsToSavedInDB);
          console.log('paymentDBResult ', paymentDBResult);
          this.isLoading = false;
        }
      }

    } catch (errorResponse) {
      console.log('eer ', errorResponse);
      this.isLoading = false;
    }
  }
}
