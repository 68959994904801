import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUnique'
})
export class UniquePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let resultArray = [];
    let uniqueArray = value.filter(function (el) {

      if(el.job_sector != undefined){
        let sector = el.job_sector;
        let sector_exist = resultArray.indexOf(sector);

      if (sector_exist == -1) {
        resultArray.push(sector);
      }
      
      }else{
        let discipline = el.discipline;
        let discipline_exist = resultArray.indexOf(discipline);

        if (discipline_exist == -1) {
          resultArray.push(discipline);
        }
      }
    });

    return resultArray;

  }
}
