import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '../header/header.component'
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  discipline_list: any = [];
  isLoading: boolean = false;
  disable_next: boolean;
  firstInResponse: any;
  lastInResponse: any;
  tableData: any[];


  constructor(private afAuth: AngularFireAuth, private _header: HeaderComponent, public afs: AngularFirestore) { }

  async ngOnInit() {
    this.isLoading = true;
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this._header.getUserStatus(userInfo.uid);
    this.allDiscipline();
  }


  async allDiscipline() {

    try {

      this.isLoading = true;
      this.discipline_list = [];
      this.afs.collection('discipline', ref => ref.where('isDeleted', '==', false)
        .limit(6)
        .orderBy('created_at', 'desc')
      ).snapshotChanges()
        .subscribe(response => {
          if (!response.length) {
            this.disable_next = true;
            this.isLoading = false;
            return false;
          }
          this.firstInResponse = response[0].payload.doc;
          this.lastInResponse = response[response.length - 1].payload.doc;

          if (response.length < 6) {
            this.disable_next = true;
            this.firstInResponse = response[0].payload.doc;
            this.lastInResponse = response[response.length - 1].payload.doc;

            this.tableData = [];
            for (let item of response) {
              let result = item.payload.doc.data();
              result['id'] = item.payload.doc.id;
              this.discipline_list.push(result);
            }

            this.isLoading = false;
            return;
          }

          this.tableData = [];
          for (let item of response) {
            let result = item.payload.doc.data();
            result['id'] = item.payload.doc.id;
            this.discipline_list.push(result);
          }

          this.disable_next = false;
          this.isLoading = false;
        })

    } catch (errorResponse) {
      this.ngOnInit();
    }
  }

  async loadMore() {

    try {

      this.isLoading = true;
      this.afs.collection('discipline', ref => ref.where('isDeleted', '==', false)
        .orderBy('created_at', 'desc')
        .limit(6)
        .startAfter(this.lastInResponse)
      ).get()
        .subscribe(response => {
          if (!response.docs.length) {
            this.disable_next = true;
            this.isLoading = false;
            return;
          }
          this.firstInResponse = response.docs[0];
          this.lastInResponse = response.docs[response.docs.length - 1];

          if (response.docs.length < 6) {
            this.disable_next = true;
            this.firstInResponse = response.docs[0];

            this.lastInResponse = response.docs[response.docs.length - 1];
            this.tableData = [];

            for (let item of response.docs) {
              let result = item.data();
              result['id'] = item.id;
              this.discipline_list.push(result);
            }
            this.isLoading = false;
            return;
          }

          this.tableData = [];
          for (let item of response.docs) {
            let result = item.data();
            result['id'] = item.id;
            this.discipline_list.push(result);
          }

          this.disable_next = false;
          this.isLoading = false;
        })
    } catch (error) {
      this.isLoading = false;
    }
  }

}
