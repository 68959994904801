import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import { ManageProfileService } from '../manage-profile.service';
import { HeaderComponent } from '../../header/header.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { SharedComponent } from '../../shared/shared.component';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  userInfo;
  aspirant_language: FormGroup;
  inputLanguage: object = {};
  isLoading: boolean = false;
  details;
  noSkills: boolean = false;
  detailsid;
  show: boolean = false;
  selectedIndex = 0;
  myClass = 'hide-class';
  uniqueLanguageId: string;
  cancelButton: boolean = false;
  numerOfExperience = [];
  language: string = '';
  proficiency: string = '';
  ability: string = '';
  required = true;
  external_id = '';
  abilityList = ['Read', 'Write', 'Speak'];
  selectedSkill = [];

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private fb: FormBuilder, private afAuth: AngularFireAuth, private firestore: AngularFirestore, private _common: SharedComponent) { }


  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  searchLanguage = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this._common.languagesList()
        : this._common.languagesList().filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }



  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
      this.uniqueLanguageId = 'aspirant_language_' + uuid();
    } else {
      this.router.navigate(['home']);
    }
  }


  // onChange(value: string, isChecked: boolean, form) {
  //   const abilityFormArray = form.controls.ability;
  //   if (isChecked) {
  //     abilityFormArray.push(new FormControl(value));
  //   } else {
  //     let index = abilityFormArray.controls.findIndex(x => x.value == value)
  //     abilityFormArray.removeAt(index);
  //   }
  // }

  onCheck(value, isChecked) {

    if (isChecked) {
      this.selectedSkill.push(value);
    } else {
      let index = this.selectedSkill.findIndex(x => x == value)
      this.selectedSkill.splice(index, 1);
    }

    if (this.selectedSkill.length > 0) {
      this.required = false;
    } else {
      this.required = true;
    }
  }


  addLanguage() {
    this.show = true;
    this.selectedIndex = this.selectedIndex + 1;
    this.myClass = 'hide-class';
    this.language = '';
    this.proficiency = '';
    this.ability = '';
  }


  async addDetails(form) {
    if (form.valid) {

      this.isLoading = true;
      let finalInput;

      form.value['ability'] = this.selectedSkill;

      if (this.details != undefined && this.details.Language != undefined) {
        let data = this.details.Language[this.selectedIndex];

        if (data != undefined && Object.keys(data).length > 0) {
          form.value['external_id'] = this.details['Language'][this.selectedIndex]['external_id'] != '' ? this.details['Language'][this.selectedIndex]['external_id'] : 'aspirant_language_' + uuid();

          this.details['Language'][this.selectedIndex] = form.value;
          finalInput = this.details;
        } else {
          if (this.details != undefined && this.details['Language'].length > 0 && this.details != null) {

            form.value['external_id'] = 'aspirant_language_' + uuid();

            this.details['Language'][this.details['Language'].length] = form.value;
            finalInput = this.details;
          } else {
            form.value['external_id'] = 'aspirant_language_' + uuid();
            let inputData = form.value;
            finalInput = {
              'Language': [inputData]
            }
          }
        }
      } else {
        form.value['external_id'] = 'aspirant_language_' + uuid();
        let inputData = form.value;
        finalInput = {
          'Language': [inputData]
        }
      }

      let getLanguageDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getLanguageDetails != null) {

        getLanguageDetails['aspirant_personal'] = getLanguageDetails.aspirant_personal ? getLanguageDetails.aspirant_personal : {};
        getLanguageDetails['aspirant_education'] = getLanguageDetails.aspirant_education ? getLanguageDetails.aspirant_education : {};
        getLanguageDetails['aspirant_skills'] = getLanguageDetails.aspirant_skills ? getLanguageDetails.aspirant_skills : {};;
        getLanguageDetails['aspirant_fitness'] = getLanguageDetails.aspirant_fitness ? getLanguageDetails.aspirant_fitness : {};
        getLanguageDetails['aspirant_interests'] = getLanguageDetails.aspirant_interests ? getLanguageDetails.aspirant_interests : {};
        getLanguageDetails['aspirant_social_profile'] = getLanguageDetails.aspirant_social_profile ? getLanguageDetails.aspirant_social_profile : {};
        getLanguageDetails['aspirant_certifications'] = getLanguageDetails.aspirant_certifications ? getLanguageDetails.aspirant_certifications : {};
        getLanguageDetails['aspirant_language'] = finalInput;

      } else {

        getLanguageDetails = {};
        getLanguageDetails['aspirant_personal'] = {};
        getLanguageDetails['aspirant_education'] = {};
        getLanguageDetails['aspirant_skills'] = {};
        getLanguageDetails['aspirant_fitness'] = {};
        getLanguageDetails['aspirant_interests'] = {};
        getLanguageDetails['aspirant_social_profile'] = {};
        getLanguageDetails['aspirant_certifications'] = {};
        getLanguageDetails['aspirant_language'] = finalInput
      }


      getLanguageDetails['created_by'] = this.userInfo['uid'];
      getLanguageDetails['modified_by'] = this.userInfo['uid'];
      getLanguageDetails['created_date_time'] = new Date();
      getLanguageDetails['modified_date_time'] = new Date();
      getLanguageDetails['operation'] = 'created';
      getLanguageDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      let result = await this._profileService.addLanguageDetails('aspirants', getLanguageDetails);
      let data = await this._header.getDetails();
      if (data != undefined) {
        this.selectedSkill = [];
        this.show = false;
        this.required = true;
        this.getDetails();
      }
      this.isLoading = false;
    }
  }



  getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        //let result = await this._profileService.getSkillsDetails('aspirant_skills', user.uid);
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_language != undefined && Object.keys(result.aspirant_language).length != 0) {
          this.details = result.aspirant_language;
          this.detailsid = result.id;
          this.noSkills = false;
          this.selectedIndex = this.details['Language'].length - 1;
          this.show = false;
          this.cancelButton = true;
          this.isLoading = false;
        } else {
          this.detailsid = (result == null) ? '' : result.id;
          this.noSkills = true;
          this.show = true;
          this.selectedIndex = 0
          this.cancelButton = false;
          this.myClass = 'hide-class';
        }
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });
  }



  async updateDetails(form) {

    if (form.valid) {

      this.isLoading = true;

      let finalInput;

      form.value['ability'] = this.selectedSkill;

      if (this.details != undefined && this.details.Language != undefined) {
        let data = this.details.Language[this.selectedIndex];

        if (data != undefined && Object.keys(data).length > 0) {

          form.value['external_id'] = this.details['Language'][this.selectedIndex]['external_id'] != '' ? this.details['Language'][this.selectedIndex]['external_id'] : 'aspirant_language_' + uuid();

          this.details['Language'][this.selectedIndex] = form.value;
          finalInput = this.details;
        } else {

          if (this.details != undefined && this.details['Language'].length > 0 && this.details != null) {


            form.value['external_id'] = 'aspirant_language_' + uuid();

            this.details['Language'][this.details['Language'].length] = form.value;
            finalInput = this.details;
          } else {

            form.value['external_id'] = 'aspirant_language_' + uuid();
            let inputData = form.value;
            finalInput = {
              'Language': [inputData]
            }
          }
        }
      } else {
        form.value['external_id'] = 'aspirant_language_' + uuid();
        let inputData = form.value;
        finalInput = {
          'Language': [inputData]
        }
      }

      let getLanguageDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getLanguageDetails['aspirant_personal'] = getLanguageDetails.aspirant_personal ? getLanguageDetails.aspirant_personal : {};
      getLanguageDetails['aspirant_education'] = getLanguageDetails.aspirant_education ? getLanguageDetails.aspirant_education : {};
      getLanguageDetails['aspirant_skills'] = getLanguageDetails.aspirant_skills ? getLanguageDetails.aspirant_skills : {};
      getLanguageDetails['aspirant_fitness'] = getLanguageDetails.aspirant_fitness ? getLanguageDetails.aspirant_fitness : {};
      getLanguageDetails['aspirant_interests'] = getLanguageDetails.aspirant_interests ? getLanguageDetails.aspirant_interests : {};
      getLanguageDetails['aspirant_social_profile'] = getLanguageDetails.aspirant_social_profile ? getLanguageDetails.aspirant_social_profile : {};
      getLanguageDetails['aspirant_certifications'] = getLanguageDetails.aspirant_certifications ? getLanguageDetails.aspirant_certifications : {};
      getLanguageDetails['aspirant_language'] = finalInput;


      getLanguageDetails['created_by'] = this.userInfo['uid'];
      getLanguageDetails['modified_by'] = this.userInfo['uid'];
      getLanguageDetails['created_date_time'] = new Date();
      getLanguageDetails['modified_date_time'] = new Date();
      getLanguageDetails['operation'] = 'created';
      getLanguageDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.updateLanguageDetails('aspirants', this.detailsid, getLanguageDetails);

      delete getLanguageDetails['userId'];
      delete getLanguageDetails['created_by'];
      delete getLanguageDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getLanguageDetails));
      this.selectedSkill = [];
      this.show = false;
      this.required = true;
      this.ngOnInit();
      this.isLoading = false;
    }
  }


  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  cancel() {
    this.getDetails();
    this.myClass = 'hide-class';
    this.show = false;
    this.cancelButton = true;
  }

  showForm(index) {
    this.selectedIndex = index;
    let data = this.details.Language[index];
    this.language = data.language;
    this.proficiency = data.proficiency;
    this.ability = data.ability;
    this.selectedSkill = data.ability;
    this.required = false;
    this.show = true;
  }

  checked(item) {
    if (this.ability.indexOf(item) != -1) {
      return true;
    }
  }

  async removeForm(index) {
    if (confirm('Are you sure want to delete this?')) {
      this.selectedIndex = index;
      if (index == 0 && this.details.Language.length == 1) {
        this.details = {};
      } else {
        this.details.Language.splice(index, 1)
      }


      let getLanguageDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getLanguageDetails['aspirant_personal'] = getLanguageDetails.aspirant_personal ? getLanguageDetails.aspirant_personal : {};
      getLanguageDetails['aspirant_education'] = getLanguageDetails.aspirant_education ? getLanguageDetails.aspirant_education : {};
      getLanguageDetails['aspirant_skills'] = getLanguageDetails.aspirant_skills ? getLanguageDetails.aspirant_skills : {};
      getLanguageDetails['aspirant_fitness'] = getLanguageDetails.aspirant_fitness ? getLanguageDetails.aspirant_fitness : {};
      getLanguageDetails['aspirant_interests'] = getLanguageDetails.aspirant_interests ? getLanguageDetails.aspirant_interests : {};
      getLanguageDetails['aspirant_social_profile'] = getLanguageDetails.aspirant_social_profile ? getLanguageDetails.aspirant_social_profile : {};
      getLanguageDetails['aspirant_certifications'] = getLanguageDetails.aspirant_certifications ? getLanguageDetails.aspirant_certifications : {};
      getLanguageDetails['aspirant_language'] = this.details;


      getLanguageDetails['created_by'] = this.userInfo['uid'];
      getLanguageDetails['modified_by'] = this.userInfo['uid'];
      getLanguageDetails['created_date_time'] = new Date();
      getLanguageDetails['modified_date_time'] = new Date();
      getLanguageDetails['operation'] = 'created';
      getLanguageDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.updateLanguageDetails('aspirants', this.detailsid, getLanguageDetails);

      delete getLanguageDetails['userId'];
      delete getLanguageDetails['created_by'];
      delete getLanguageDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getLanguageDetails));
      if (index == 0 && Object.keys(this.details).length == 0) {
        this.addLanguage();
        this.selectedIndex = 0;
        this.show = true;
      } else {
        this.show = false;
        this.ngOnInit();
      }
    }
  }

  resetForm() {
    this.getDetails();
    this.show = false;
  }
}