    <!-- Breadcrumb -->
    <div class="page-heading row">
    <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
    <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item active" aria-current="page">Careers</li>
    </ol>
    </nav>
    <h2>Careers </h2>
    </div>
    </div>
      
      
       <!-- Careers container -->
        <div class="row block-holder">
        <div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/defence.png"  >
        <figcaption>
        <h2>Defence</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
        
        
        <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>

		<div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/healthcare.png"  >
        <figcaption>
        <h2>Healthcare</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
         <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
        <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>        
        
        <div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/management.png"  >
        <figcaption>
        <h2>Management</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
         <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
      <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>        
        
         <div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/banking.png"  >
        <figcaption>
        <h2>Banking</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
         <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
       <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>    
        
      
        <div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/railway.png"  >
        <figcaption>
        <h2>Railway</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
         <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
       <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>    
        
        
        <div class="col-md-6 col-lg-4">
        <div class="grid data-list">
        <figure class="effect-white">
        <img   src="assets/img/careers/education.png"  >
        <figcaption>
        <h2>Education</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
         <span class="btn btn-primary-outline mt-5">MORE</span>
        </p>
        <a href="#" routerLink="/career">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>                
        </div>   
        
        
        
        </div>