<!-- Top menu -->
<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container">
    <!-- Brand -->
    <a class="navbar-brand"><img src="assets/img/front-end/logo.png" /></a>
    <!-- Toggler -->
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Collapse -->
    <div class="navbar-collapse collapse" id="navbarCollapse" style="">
      <!-- Toggler -->
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fe fe-x"></i>
      </button>

      <!-- Navigation -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"><a class="nav-link ">How We Work</a></li>
        <li class="nav-item"><a class="nav-link ">About Us</a></li>
        <li class="nav-item"><a class="nav-link ">Membership</a></li>
        <li class="nav-item"><a class="nav-link ">Contact</a></li>
      </ul>

      <!-- Button -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"> <a class="navbar-btn btn btn-sm btn-primary lift " href="javascript:void(0)"> Take free
            Assessment</a></li>
        <li class="nav-item"><a href="#" class="nav-link" data-toggle="modal" data-target="#signup">Sign Up</a></li>
        <li class="nav-item"><a href="#" class="nav-link" data-toggle="modal" data-target="#myModal">Log In</a></li>
      </ul>
    </div>

  </div>
</nav>

<div class="modal fade " id="myModal" tabindex="-1" aria-labelledby="loginsignupTitle" style=" padding-right: 0;"
  aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content bg-secondary ">

      <div class="modal-body p-0">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <div class="row form-sm-container pull-left" id="login">
          <div class="pull-right col-sm-7 memberForm pt30 bg-secondary ">
            <h3 class="text-center">Login</h3>
            <!-- <p class="  text-center mb-0 pt-2">
              <a href="javascript:void(0)" class="pb-2 d-inline-block" (click)="FacebookAuth()"><img
                  src="assets/img/img-fb.png"></a>
              <a href="javascript:void(0)" class="pb-2" id="my-signin2" (click)="GoogleAuth()"> <img
                  src="assets/img/img-google.png"></a>
            </p> -->
            <p class="text-center">use your email account.</p>

            <form>

              <div class="form-group">
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Email address" #userEmailId required>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
                  #userPassword required>
              </div>

              <a class="btn btn-primary" (click)="signIn(userEmailId.value,userPassword.value)">Login</a>

            </form>
            <div class="loading" *ngIf="isLoading == true">Loading</div>
          </div>

          <div class="pull-left col-sm-5  wel-section pt30 bg-primary">
            <div class=" mt-5 pt-3 mb-5 pb-3">
              <h3> Hello, Friends!</h3>
              <p> Enter your personal details
                and start your career journey with us</p>
              <a class="btn btn-third-outline show-signup" data-toggle="modal" data-target="#signup"
                (click)="hideLogin()">Signup</a>
            </div>
          </div>

        </div>


        <!-- <div class="form-sm-container row pull-left " id="signups" style="display:none;">
          <div class="float-right col-sm-7 memberForm pt30 bg-secondary ">
            <h3 class="text-center">Sign Up</h3>
            <p class="  text-center mb-0 pt-2"><a href="#" class="pb-2 d-inline-block"><img
                  src="assets/img/img-fb.png"></a> <a href="#" class="pb-3 d-inline-block "><img
                  src="assets/img/img-tw.png"></a></p>
            <p class="text-center">or use your email for registration:</p>

            <form>
              <div class="form-group">
                <input type="text" class="form-control" id="fname" placeholder="Name" #name required>
              </div> -->

        <!-- <div class="form-group">
            <input type="text" class="form-control" id="lname" placeholder="last name" #lastName required>
          </div> -->

        <!-- <div class="form-group">
                <input type="email" class="form-control" id="exampleInputEmail1" #userEmail aria-describedby="emailHelp"
                  placeholder="Email address" required>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" id="exampleInputPassword1" #userPwd placeholder="Password"
                  required>
              </div>

              <button type="submit" class="btn btn-primary" data-dismiss="modal">Sign
                Upww</button>
            </form>

          </div>


          <div class="float-left col-sm-5  wel-section pt30 bg-primary">
            <div class=" mt-5 pt-3 mb-5 pb-3">
              <h3> Welcome Back</h3>
              <p> To keep connected with us please
                login with your personal info.</p>
              <a class="btn btn-third-outline show-login">Login</a>
            </div>
          </div>

        </div> -->
      </div>

    </div>
  </div>
</div>


<!-- signup form -->
<div class="modal fade" id="signup" tabindex="-1" aria-labelledby="loginsignupTitle" style=" padding-right: 0;"
  aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content bg-secondary ">

      <div class="modal-body p-0">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <div class="row form-sm-container pull-left" id="login">
          <div class="pull-right col-sm-7 memberForm pt30 bg-secondary ">
            <h3 class="text-center">Sign Up</h3>
            <!-- <p class="  text-center mb-0 pt-2">
              <a href="javascript:void(0)" class="pb-2 d-inline-block" (click)="FacebookAuth()"><img
                  src="assets/img/img-fb.png"></a>
              <a href="javascript:void(0)" class="pb-2" id="my-signin2" (click)="GoogleAuth()"> <img
                  src="assets/img/img-google.png"></a>
            </p> -->
            <p class="text-center">or use your email for registration:</p>


            <div class="form-group">
              <input type="text" class="form-control" id="fname" placeholder="Name" #name required>
            </div>


            <div class="form-group">
              <input type="email" class="form-control" id="exampleInputEmail1" #userEmail placeholder="Email address"
                required>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" id="exampleInputPassword1" #userPwd placeholder="Password"
                required>
            </div>

            <button type="submit" (click)="signUp(userEmail.value, userPwd.value,name.value)"
              class="btn btn-primary">Sign
              Up</button>
            <div class="loading" *ngIf="isLoading == true">Loading</div>

          </div>


          <div class="float-left col-sm-5  wel-section pt30 bg-primary">
            <div class=" mt-5 pt-3 mb-5 pb-3">
              <h3> Welcome Back</h3>
              <p> To keep connected with us please
                login with your personal info.</p>
              <a class="btn btn-third-outline show-login" data-toggle="modal" data-target="#myModal"
                (click)="hideSignup()">Login</a>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>


<!-- Top menu end-->

<!-- Welcome header-->
<section class="pt-5 pb-5 bg-header">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-2 mb-3">
        <!-- Image -->
        <img src="assets/img/front-end/img-consultation.png"
          class="animate__animated animate__fadeInUp img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100">

      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-up">

        <!-- Heading -->
        <h1 class="  text-md-left  text-uppercase primary-text-color">
          Know Your Perfect Career
        </h1>
        <!-- Text -->
        <p class="display-5 text-center text-md-left text-muted mb-6 mt-3 pr-5  text-monospace">
          Your brighter career is waiting for you and so is the job that you would love.Claim your perfect career with
          the help of Open Naukri’s career counselling.
        </p>

        <!-- Buttons -->
        <div class=" text-md-left">
          <a href="javascript:void(0)" class="btn btn-secondary shadow lift mr-1">
            Get Started
          </a>
        </div>

        <!-- Forms -->
        <form class="mt-5">
          <p class="small">Share your number with us, and we will get in touch</p>
          <div class="row">
            <div class="col-md-5 mb-2 "><input type="text" placeholder="Mobile Number" class="form-control" /></div>
            <div class="col-md-4"><button class="btn btn-primary-outline m-0">Get A Call Back</button></div>
          </div>
        </form>

      </div>
    </div> <!-- / .row -->
  </div> <!-- / .container -->
</section>
<!-- Welcome header end-->

<!-- Feature Services -->
<section class="pt-5 pb-5   animate__animated animate__fadeInUp  animate__delay-1s">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12   text-center mt-5 mb-5 ">
        <h2 class="font-weight-bold primary-text-color">Why Are We Your Best Choice?</h2>
        <p class=" mb-6 mt-3">We have a unique approach for career guidance that utilizes best of technologies and scientific methods. We have an effective process to answer all your career confusions and help you find your best-fit career.</p>
      </div>

      <div class="col-12 col-md-4  ">

        <!-- Icon -->
        <div class="icon text-primary mb-5">
          <img src="assets/img/front-end/icon-conselling.png" class="img-fluid">
        </div>
        <!-- Heading -->
        <h3>
          Career Counselling by Experts
        </h3>

        <!-- Text -->
        <p class="text-muted mb-6 mb-md-0">
          Our expert counsellors are from various fields of different industries; they have worked with good and bad of the sectors and know what it takes to be successful. From education to industry our counsellors have helped students and working professionals leaving no section unturned.
        </p>

      </div>
      <div class="col-12 col-md-4 aos-init aos-animate">

        <!-- Icon -->
        <div class="icon text-primary mb-5">
          <img src="assets/img/front-end/icon-future-scope.png" class="img-fluid">
        </div>

        <!-- Heading -->
        <h3>
          Know Yourself – Assessment
        </h3>

        <!-- Text -->
        <p class="text-muted mb-6 mb-md-0">
          Knowing yourself and your interest and skills is important. Understanding the critical need of it we have designed a career assessment where students get to know themselves better. Assessments are a perfect way to know yourself and play a great role in making a complete personalized plan for you.
        </p>

      </div>
      <div class="col-12 col-md-4 aos-init aos-animate">

        <!-- Icon -->
        <div class="icon text-primary mb-5">
          <img src="assets/img/front-end/icon-support.png" class="img-fluid">
        </div>

        <!-- Heading -->
        <h3>
          Development Programs
        </h3>

        <!-- Text -->
        <p class="text-muted mb-0">
          Once you know your what is your best-fit career and what is stopping you to achieve it, you need to develop those skills that take you close to it. We offer overall development programs developed by experts that help you build overall personality and stand out in the crowd.
        </p>

      </div>

      <div class="col-12 col-md-12 text-center pt-5 pb-5">
        <button class="btn btn-primary-outline m-0">Know More</button>
      </div>


    </div> <!-- / .row -->
  </div> <!-- / .container -->
</section>
<!-- Feature Services end-->


<!-- How we work-->
<section class="pt-4 pt-md-11">
  <div class="container">

    <!-- Step 1 -->
    <div class="row align-items-center">

      <div class="col-12 col-md-7 col-lg-6 order-md-1 ">
        <p class="display-6">How We Work</p>
        <p class="text-muted xsmall pt-3 mb-0">Step - 01</p>
        <!-- Heading -->
        <h2 class="text-md-left  primary-text-color pt-3 pb-3">
          Advanced Career Assessment
        </h2>
        <!-- Text -->
        <p class="  text-muted mb-6  pr-5  ">
          Leveraging the benefits of Artificial intelligence and Machine Learning a career assessment platform is built with the help of best psychometricians. Get to know the unexplored about yourself and recommendations based on your personality and skills.<br />
          <br />
          Career assessment makes you self-aware and based on that you get recommendations on opportunities you must not miss. A detailed career assessment report is available for you to check.
        </p>
      </div>

      <div class="col-12 col-md-5 col-lg-6 order-md-2">
        <!-- Image -->
        <img src="assets/img/front-end/step-1.png" class="img-fluid mw-md-150 mw-lg-130 mb-6  mt-5  
              animate__animated animate__fadeInRight" alt="...">

      </div>


    </div>
    <!-- step1 close -->
  </div> <!-- / .container -->

  <div class="bg-light-grey">
    <div class="container">
      <!-- step2 start -->
      <div class="row align-items-center ">

        <div class="col-12 col-md-7 col-lg-6  order-md-2 ">
          <p class="text-muted xsmall pt-3 mb-0">Step - 02</p>
          <!-- Heading -->
          <h2 class="  text-md-left  primary-text-color pt-3 pb-3">
            Expert Review - Analysis and Insights
          </h2>
          <!-- Text -->
          <p class="  text-muted mb-6 mb-lg-8 pr-5">
            Based on the career assessment report and other tests that you appear for a detailed review is performed by counsellors. Based on your personality, daily habits, schedule, interests, hobbies etc. a detailed analysis and insights your best career, courses and jobs, skill development programs, exams and tests are suggested to you. A detailed expert review is shared in the form of report with you sharing the complete information that you need to make right choices and brighter career.<br />
            <br />

          </p>
        </div>

        <div class="col-12 col-md-5 col-lg-6 order-md-1">
          <!-- Image -->
          <img src="assets/img/front-end/step-2.png" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 
            animate__animated animate__fadeInLeft" alt="..." data-aos="fade-up" data-aos-delay="100">

        </div>
      </div>
      <!-- step2 end -->
    </div> <!-- / .container -->
  </div> <!-- / .bg-color -->
  <div class="container">
    <!-- Step 3 -->
    <div class="row align-items-center">

      <div class="col-12 col-md-7 col-lg-6 order-md-1 ">
        <p class="text-muted xsmall pt-3 mb-0">Step - 03</p>
        <!-- Heading -->
        <h2 class="  text-md-left  primary-text-color pt-3 pb-3">
          Personalized Career Guidance and Best Resources
        </h2>
        <!-- Text -->
        <p class=" text-muted mb-6 mb-lg-8 pr-5 ">
          We offer on-to-one career counselling to you help you find your best career, stream, jobs and planning career moves as well as overall development. Our career mentors have helped many over the years to find their best career and answers to all their confusions, don’t be left behind.<br />
          <br />
          We offer on-to-one career counselling to you help you find your best career, stream, jobs and planning career moves as well as overall development. Our career mentors have helped many over the years to find their best career and answers to all their confusions, don’t be left behind.
        </p>
      </div>

      <div class="col-12 col-md-5 col-lg-6 order-md-2">
        <!-- Image -->
        <img src="assets/img/front-end/step-3.png" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 
            animate__animated animate__fadeInRight " alt="...">

      </div>

    </div>
    <!-- step3 close -->
    <div class="col-12 col-md-12 text-center pt-5 pb-5">
      <button class="btn btn-primary-outline m-0">Take Assessment</button>
    </div>
    <!-- / .row -->
  </div> <!-- / .container -->
</section>
<!-- How we work end-->



<!-- Student Testimonials-->
<section class="pt-4 pt-md-11 bg-blue">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 mt-5 mb-5">
        <!-- Heading -->
        <h2 class="text-md-center  text-color-white font-weight-bold">
          They Made The Right Choice 
        </h2>
        <!-- Text -->
        <p class="text-center   mb-6 mt-3 text-color-white">We have helped many to find their best career and showed them the path to a brighter future, hear from them.</p>
      </div>

      <div class="col-12 mb-5">



        <!-- Testimonials -->
        <ul id="rcbrand3" class=" animate__animated animate__fadeInRight ">
          <li>
            <div class="testimonial rounded p-5">
              <p class="message mt-4">I never thought I could score the highest being an average student till 9th. Thanks to the career counselling, I had a great plan to prepare, tests and quiz, I scored the best.</p>
              <div class="media pt-5">
                <img class="mr-3" src="assets/img/front-end/thumb-user-1.png">
                <div class="media-body">
                  <h5 class="mt-4 mb-2 font-weight-bold">Neha Tiwari</h5>
                  Student of Class 10th
                </div>
              </div>
            </div>
          </li>


          <li>
            <div class="testimonial rounded p-5">
              <p class="message mt-4">The career assessment introduced me to the real me. Career counselling was real need to find my made-for-me career, thanks Opennaukri, I never would have selected fashion designing otherwise.</p>
              <div class="media pt-5">
                <img class="mr-3" src="assets/img/front-end/thumb-user-2.png">
                <div class="media-body">
                  <h5 class="mt-4 mb-2 font-weight-bold">Ajay Shukla</h5>
                  Fashion Designing student
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="testimonial rounded p-5">
              <p class="message mt-4">I made the right choice by selecting Commerce, my counsellor did a great job in showing me the future I can have in this field and all the careers in it, as well as guided on exams too. Thank you!</p>
              <div class="media pt-5">
                <img class="mr-3" src="assets/img/front-end/thumb-user-3.png">
                <div class="media-body">
                  <h5 class="mt-4 mb-2 font-weight-bold">Aditi Rao</h5>
                  Student of Class 12th
                </div>
              </div>
            </div>
          </li>

        </ul>

      </div>
    </div>

  </div>
</section>



<!-- Location -->
<section class="pt-4 pt-md-11">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-12   mt-5 mb-0" data-aos="fade-up">
        <h2 class="font-weight-bold pb-2">We are loved by students across the Country</h2>
        <p>Our counsellors and career guidance sessions are loved by students across the country and no matter where you are from, we are just a call away.</p>
      </div>


      <div class="col-12 col-md-7 col-lg-6 order-md-1 " data-aos="fade-up">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5">
            <h3 class="display-4 font-weight-bold primary-text-color"><span class="counter-count">3500K</span></h3>
            <p>Student Helped</p>
          </div>
          <div class="col-md-6 mb-5">
            <h3 class="display-4 font-weight-bold primary-text-color"><span class="counter-count">100</span>K</h3>
            <p>Psychometric Done</p>
          </div>
          <div class="col-md-6 mb-5">
            <h3 class="display-4 font-weight-bold primary-text-color"><span class="counter-count">1500</span>K</h3>
            <p>Career Counsellor</p>
          </div>
          <div class="col-md-6 mb-5">
            <h3 class="display-4 font-weight-bold primary-text-color"><span class="counter-count">500</span></h3>
            <p>Location Service</p>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-6 order-md-2">
        <!-- Image -->
        <img src="assets/img/front-end/support-map.png"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate" alt="..." data-aos="fade-up"
          data-aos-delay="100">
      </div>

    </div>
  </div>
</section>


<!-- Expert View -->
<section class="pt-4 pt-md-11 bg-orange">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-12 aos-init aos-animate text-center mt-5  pt-5   white-text" data-aos="fade-up">
        <h2 class="font-weight-bold pb-2 white-text pr-5 pl-5 mb-5  w-75 ml-auto mr-auto">Take the First Step Right in Discovering Your Best Career </h2>
        <p>Know yourself to find your best fit career, get the expert help to get all your questions answered and a personalized plan for your overall development.</p>
      </div>


      <div class="col-12 col-md-12 text-center pt-5 pb-5 mb-4">
        <button class="btn btn-third-outline m-0">Connect Now</button>
      </div>

    </div>
  </div>
</section>


<!-- Blog -->
<section class="pt-4 pt-md-11 animate__animated animate__fadeInUp">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-12 aos-init aos-animate text-center mt-3 mb-3" data-aos="fade-up">
        <h2 class="font-weight-bold pb-2 primary-text-color">From Open Naukri Blog</h2>
        <p>Expert-written articles, current affairs, career guides, how to tips and more to help you prepare for future competition and find the right career</p>
      </div>
    </div>

    <div class="row  mt-5 mb-5">
      <div class="col-12 col-md-4 aos-init aos-animate   " data-aos="fade-up">
        <div class="card mb-3">
          <img class="card-img-top" src="assets/img/front-end/blog-pic-1.png" alt="Card image cap">
          <div class="card-body">
            <span class="card-label">Career</span>
            <h5 class="card-title font-weight-bold">How Kid's Physical Fitness Help Promote School Success</h5>
            <p class="card-text"><small class="text-muted">May 19, 2020</small></p>
            <p class="card-text">Most schools have mechanisms and personnel in
              place to improve the physical fitness and metabolism
              in growing children. Nutritious meals are provided
              during the day for optimum...</p>

          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 aos-init aos-animate   " data-aos="fade-up">
        <div class="card mb-3">
          <img class="card-img-top" src="assets/img/front-end/blog-pic-2.png" alt="Card image cap">
          <div class="card-body">
            <span class="card-label">Education</span>
            <h5 class="card-title font-weight-bold">Reprogram Your Mind in These 11 Ways</h5>
            <p class="card-text"><small class="text-muted">June 20, 2020</small></p>
            <p class="card-text">Most schools have mechanisms and personnel in
              place to improve the physical fitness and metabolism
              in growing children. Nutritious meals are provided
              during the day for optimum...</p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-4 aos-init aos-animate   " data-aos="fade-up">
        <div class="card mb-3">
          <img class="card-img-top" src="assets/img/front-end/blog-pic-3.png" alt="Card image cap">
          <div class="card-body">
            <span class="card-label">English</span>
            <h5 class="card-title font-weight-bold">7 Things You Need To Do During The Coronavirus Outbreak</h5>
            <p class="card-text"><small class="text-muted">May 19, 2020</small></p>
            <p class="card-text">Most schools have mechanisms and personnel in
              place to improve the physical fitness and metabolism
              in growing children. Nutritious meals are provided
              during the day for optimum...</p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-12 text-center pt-5 pb-5">
        <button class="btn btn-primary-outline m-0">More</button>
      </div>

    </div>
  </div>
</section>


<!-- Connect -->
<section class="pt-4 pb-3 pt-md-11 support bg-support">
  <div class="container">
    <div class="row  mt-5 mb-5">
      <div class="col-12 col-md-6 aos-init aos-animate   " data-aos="fade-up">
        <h2 class="white-text font-weight-bold pb-2">Ask Your Career Related Queries</h2>
        <p class="white-text">Got career-related questions? Ask them to our career experts.</p>
      </div>

      <div class="col-12 col-md-6 mt-4 aos-init aos-animate   " data-aos="fade-up">
        <div class="row ">
          <div class="col-md-6"><span><a href="javascript:void(0)"><i class="fa fa-envelope mr-3"
                  aria-hidden="true"></i>
                  info@opennaukri.com</a></span></div>
          <!-- <div class="col-md-6"> <span><i class="fa fa-phone mr-3" aria-hidden="true"></i> 123-456-789</span></div> -->
        </div>
      </div>

    </div>
  </div>
</section>


<!-- Footer -->
<footer class="pt-4 pt-md-11">
  <div class="container">
    <div class="row  mt-5 mb-5">
      <div class=" col-md-12 col-lg-5 aos-init aos-animate   " data-aos="fade-up">
        <div class="row">
          <div class="col-md-4">
            <div class="media">
              <img class="mr-3 img-fluid" src="assets/img/front-end/logo.png">

            </div>
          </div>
          <div class="col-md-8">
            <div class="media">
              <div class="media-body">
                <p>Opennaukri is the best place to learn and seek assistance for
                  shaping your career. No matter in which stream you study,
                  what exam your are preparing for, here you get the most
                  relevant and supreme information. We work to help you
                  achieve your career goals and academic landmarks.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-7 col-md-12 aos-init aos-animate   " data-aos="fade-up">
        <div class="row">
          <div class="col-md-3">
            <ul class="list-unstyle">
              <li><a href="javascript:void(0)">Home</a></li>
              <li><a href="javascript:void(0)">About Us</a></li>
              <li><a href="javascript:void(0)">Membership</a></li>
              <li><a href="javascript:void(0)">Blog</a></li>
              <li><a href="javascript:void(0)">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul class="list-unstyle">
              <li><a href="javascript:void(0)">Personality Development</a></li>
              <li><a href="javascript:void(0)">Skill Improvement</a></li>
              <li><a href="javascript:void(0)">English speaking </a></li>
              <li><a href="javascript:void(0)">Government Job</a></li>
              <li><a href="javascript:void(0)"></a></li>
            </ul>
          </div>
          <div class="col-md-5">
            <form>
              <div class="row">
                <div class="col-md-12 mb-2 "><input type="text" placeholder="Enter your email address"
                    class="form-control" /></div>
                <div class="col-md-12"><button class="btn btn-secondary m-0">Subscribe</button></div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</footer>