import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageProfileService } from '../manage-profile/manage-profile.service';

@Component({
  selector: 'app-result-details',
  templateUrl: './result-details.component.html',
  styleUrls: ['./result-details.component.css']
})
export class ResultDetailsComponent implements OnInit {

  quiz_id: string;
  isLoading: boolean = true;
  quiz_details={};
  correctAnswer: number;
  wrongAnswer: number;
  attempted: number;
  resultInPercent: number;
  notAttempted: number;
  totalQuizQuestions: any;
  userDetails: any;

  constructor(private route: ActivatedRoute, private _profileService: ManageProfileService) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.quiz_id = this.route.snapshot.paramMap.get('id');
    this.getQuizDetails(this.quiz_id);
  }

  async getQuizDetails(quizid) {
    try {
      this.isLoading = true;
      let result = await this._profileService.getQuizById('quiz_result', quizid);
      if (result.length > 0) {
        this.quiz_details = result[0];
        this.calculateResult(result[0]['details']);

      }
    } catch (errorResponse) {
      this.ngOnInit();
      console.log('error :', errorResponse);
    }
  }

  async calculateResult(result) {

    if (result != null) {
      let finalResult = result.filter(function (el) { return el.result === true })
      let totalAttemptQuestions = result.filter(function (el) { return el.Attempt == true })
      this.totalQuizQuestions = result.length;

      this.correctAnswer = Object.keys(finalResult).length;
      this.attempted = Object.keys(totalAttemptQuestions).length;
      this.wrongAnswer = this.attempted - this.correctAnswer;
      this.resultInPercent = this.correctAnswer * 100 / this.totalQuizQuestions;
      this.notAttempted = this.totalQuizQuestions - this.attempted;
      this.isLoading = false;
    }
  }
}
