<!--Login Form-->
<div class="container ">

  <div class="form-sm-container row ">

    <div class="float-right col-sm-7 memberForm pt30 bg-secondary ">
      <h2 class="text-center">Sign Up</h2>
      <p class="  text-center"><a href="#" class="pt-3 d-inline-block"><img src="assets/img/img-fb.png"></a> <a href="#"
          class="pt-3 d-inline-block"><img src="assets/img/img-tw.png"></a></p>
      <p class="text-center">or use your email for registration:</p>

      <form>
        <div class="form-group">
          <input type="text" class="form-control" id="fname" placeholder="Name" #name required>
        </div>

        <!-- <div class="form-group">
          <input type="text" class="form-control" id="lname" placeholder="last name" #lastName required>
        </div> -->

        <div class="form-group">
          <input type="email" class="form-control" id="exampleInputEmail1" #userEmail aria-describedby="emailHelp"
            placeholder="Email address" required>
        </div>
        <div class="form-group">
          <input type="password" class="form-control" id="exampleInputPassword1" #userPwd placeholder="Password"
            required>
        </div>

        <button type="submit" (click)="signUp(userEmail.value, userPwd.value,name.value)" class="btn btn-primary">Sign
          Up</button>
      </form>

    </div>


    <div class="float-left col-sm-5  wel-section pt30 bg-primary">
      <div class=" mt-5 pt-3 mb-5 pb-3">
        <h3> Welcome Back</h3>
        <p> To keep connected with us please
          login with your personal info.</p>
        <a class="btn btn-third-outline" routerLink="/login">Login</a>
      </div>
    </div>

  </div>

</div>

<div class="loading" *ngIf="isLoading == true">Loading</div>