import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from "../models/user.models";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  isLoading: boolean = false;
  successMsg: string = 'Thank You for Registering!.We have sent a confirmation email.Please click on link to verify!'
  constructor(public router: Router, private toastr: ToastrService, public authService: AuthService, public afAuth: AngularFireAuth, public afs: AngularFirestore) { }

  ngOnInit(): void {
  }

  // Sign up with email/password
  signUp(email: string, password: string, name: string) {

    this.isLoading = true;
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.sendVerificationMail();
        result.user.updateProfile({
          displayName: name
        }).then(function () {
        }, function (error) {
          this.isLoading = false;
          this.toastr.error(error)
        });
        this.toastr.success(this.successMsg)
      }).catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.message)
      })
  }


  // Send email verfificaiton when new user sign up
  async sendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  // setUserData(user:string, name:string) {

  //   let displayName;
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   if (user.displayName == null) {
  //     displayName = name;
  //   } else {
  //     displayName = user.displayName
  //   }
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   }
  //   return userRef.set(userData, {
  //     merge: true
  //   })
  // }
}
