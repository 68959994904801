<!-- Breadcrumb -->
<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item "><a routerLink="/careers">Careers</a></li>
        <li class="breadcrumb-item "><a routerLink="/career">Defence</a></li>
        <li class="breadcrumb-item active" aria-current="page">Army</li>
      </ol>
    </nav>
    <h2>Army </h2>
  </div>
</div>



<!-- container -->
<div class="row">
  <div class="col-md-10">
    <div class="data-list ">

      <!-- Summary Start -->
      <div id="summary" class="p-5 mb-0 crs-summary">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <h1>Army</h1>
            <p class="pt-3 lead">NDA is one of the most sought after defence entrance exams in the country.
              NDA recruitment is conducted for admission to Army, Navy and Air Force wings of
              NDA and Indian Naval Academy Course (INAC). </p>
          </div>
          <div class="col-md-12 col-lg-6 text-center"> <img src="assets/img/careers/img-army.png" class="img-fluid " />
          </div>
          <div class="col-md-12 col-lg-12">
            <p class="pt-3 lead"> NDA is conducted twice a year.
              The exam is conducted two stages: written exam and SSB Interview. Around 4 lakh
              aspirants sit for NDA every year, out of which approximately 6000 are called for
              SSB Interview. </p>
          </div>
        </div>
      </div>
      <!-- Summary End -->


      <!-- opportunities -->
      <div id="opportunities" class="p-5 bg-light-grey  mb-2">
        <div class="row">
          <div class="col-md-12 col-lg-6 text-center badge-font-medium"><img src="assets/img/careers/img-role.png" />
          </div>
          <div class="col-md-12 col-lg-6">
            <h3>Career Opportunities</h3>
            <p> Many career options in army</p>
            <span class="badge badge-primary p-3 rounded mr-2 mb-2 badge-font-medium">Combat Unit</span> <span
              class="badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium">Air Defence</span> <span
              class="badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium">Engineering and IT Unit</span>
            <span class="badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium">Army Service Corps</span>
            <span class="badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium">Education</span>
          </div>
        </div>
      </div>
      <!-- opportunities End -->




      <!-- pursue -->
      <div id="pursue" class="p-5   mb-2">
        <h3> Pursue Career in Indian Army</h3>
        <p>Check exam names suitable according yout eligibility</p>

        <div class="border rounded">
          <table class="table table-striped mb-0 table-responsive">
            <thead>
              <tr>
                <th scope="col" width="12%" class="bg-orange border-bottom-orange white-text font-weight-bold"></th>
                <th scope="col">10+2</th>
                <th scope="col">Graduation</th>
                <th scope="col" width="20%">Post Graduaction</th>
                <th scope="col" width="20%">Exam</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="bg-orange border-bottom-orange white-text font-weight-bold">Phase-1</th>
                <td>Clear Class XIIth from a well
                  recognized board</td>
                <td>-</td>
                <td>-</td>
                <td>Pass the National Defense
                  Academy (NDA) in any stream</td>
              </tr>
              <tr>
                <th scope="row" class="bg-orange border-bottom-orange white-text font-weight-bold">Phase-2</th>
                <td>Clear Class XII in any Field</td>
                <td>Graduate in any Discipline
                  (Open to pre-final year
                  students) if they have the
                  required Certificate</td>
                <td>-</td>
                <td>Pass the CDS Exam</td>
              </tr>

              <tr>
                <th scope="row" class="bg-orange border-bottom-orange white-text font-weight-bold">Phase-3</th>
                <td>Clear Class XII in any fields
                  and be part of NCC Society
                  in school fr NCC entry</td>
                <td>Graduate in any Discipline
                  (Open to pre-final year
                  students) also be a part
                  of your NCC society and
                  hold C certificate</td>
                <td>-</td>
                <td>Pass the CDS Exam</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <!-- pursue End -->


      <!-- Highlights -->
      <div id="highlights" class=" p-5 bg-light-grey ">
        <h3>Major highlights </h3>
        <div class="row mt-5">
          <div class="col-md-12 col-lg-6">
            <ul class="list-unstyled">
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> One can appear for CDS while in final year of raducation as well.
                  Most of these exams are conducted twice a year.</p>
              </li>
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> For NDA age range is 16.5 years to 29 years.</p>
              </li>
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> NDA is open only to male unmarried candidates.</p>
              </li>
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> Age range for entrances after graducation is 20-26 years.</p>
              </li>
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> Minimum 60% aggregate in graducation is needed.</p>
              </li>
              <li class="row">
                <p class="col-1"><i class="fa fa-check-square-o fa-primary-large" aria-hidden="true"></i></p>
                <p class="col-11"> For TES, minimum aggregate of 70% in PCM is required.</p>
              </li>
            </ul>
          </div>
          <div class="col-md-12 col-lg-6 text-center"><img src="assets/img/careers/img-highlights.png" /></div>
        </div>

      </div>
      <!-- highlights End -->


      <!-- Exam  start-->
      <div id="vacancies" class="p-5">
        <h3> Enterance Exam</h3>

        <div class="mt-4">
          <small class="text-uppercase mb-0 font-weight-bold text-muted">Undergraduate </small>
          <ul class="list-unstyled list-mob  mb-0  mt-2 data-list">
            <li class="media row  p-3 border rounded">
              <div class="col-md-4">
                <h4 class="font-weight-bold">NDA <small class="text-muted">(Only for men)</small></h4>
              </div>
              <div class="col-md-4 ">
                <p class="font-weight-bold pb-0 mb-0">June - July <small class="text-muted">Sep (Exam)</small></p>
                <p class="font-weight-bold pb-0 mb-0">June - July <small class="text-muted">Sep (Exam)</small></p>
              </div>
              <div class="col-md-4">Written test by UPSC SSB for Personality &amp; Intelligence test</div>
            </li>
          </ul>
          <nav aria-label="Page navigation example" class="mt-4">
            <ul class="pagination justify-content-end mb-0">
              <li class="page-item disabled page-prev">
                <a class="page-link" href="#" tabindex="-1">Previous</a>
              </li>
              <li class="page-item page-next">
                <a class="page-link" href="#">Next</a>
              </li>
            </ul>
          </nav>

        </div>

        <div class="mt-4">
          <small class="text-uppercase mb-0 font-weight-bold text-muted">Post Graduate </small>
          <ul class="list-unstyled list-mob  mb-0  mt-2 data-list">
            <li class="media row  p-3  border rounded">
              <div class="col-md-4">
                <h4 class="font-weight-bold">CDSE <small class="text-muted">(Only for graduate)</small></h4>
              </div>
              <div class="col-md-4 ">
                <p class="font-weight-bold mb-0 pb-0">Twice a year CDS</p> <small class="text-muted">Form available in
                  October and exam in Feburary</small>
                <small class="text-muted">Form available in June and exam in September</small>
              </div>
              <div class="col-md-4"><span class="display-inline mr-5"><strong>Paper 1 -</strong> English</span> <span
                  class="display-inline mr-5"><strong>Paper 2 -</strong> GK(SST, Science)</span>
                <span class="display-inline mr-5"><strong>Paper 4 -</strong> Math</span></div>
            </li>
          </ul>
          <nav aria-label="Page navigation example" class="mt-4">
            <ul class="pagination justify-content-end mb-0">
              <li class="page-item disabled page-prev">
                <a class="page-link" href="#" tabindex="-1">Previous</a>
              </li>
              <li class="page-item page-next">
                <a class="page-link" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>

      </div>

      <!-- exam end -->





      <!-- Vacancies -->
      <div id="vacancies" class="p-5 bg-light-grey">
        <p class="font-weight-bold">256 Vacancies</p>

        <div class="border rounded">
          <table class="table table-striped mb-0 table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Eligibility</th>
                <th scope="col">Last Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
                <td>10th Pass</td>
                <td>02/10/2020</td>
                <td><a class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">Link</a></td>
              </tr>
              <tr>
              <tr>
                <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
                <td>10th Pass</td>
                <td>02/10/2020</td>
                <td><a class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">Link</a></td>
              </tr>
              <tr>
              <tr>
                <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
                <td>10th Pass</td>
                <td>02/10/2020</td>
                <td><a class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">Link</a></td>
              </tr>
              <tr>
              <tr>
                <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
                <td>10th Pass</td>
                <td>02/10/2020</td>
                <td><a class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">Link</a></td>
              </tr>
              <tr>

            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example" class="mt-4">
          <ul class="pagination justify-content-end mb-0">
            <li class="page-item disabled page-prev">
              <a class="page-link" href="#" tabindex="-1">Previous</a>
            </li>
            <li class="page-item page-next">
              <a class="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav>
      </div>

      <!-- vacancies End -->




      <!-- faq -->
      <div id="faq" class="  p-5 ">
        <h3>Faq </h3>
        <p>Check question and answer</p>
        <div class="row mt-2">
          <div class="col-md-12">
            <div id="accordion">
              <div class="card mb-3">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link row w-100 p-0 text-left" data-toggle="collapse"
                      data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span class="col-10 pl-0">Q: What is the minimum educational qualification required to apply for
                        the exam?</span> <span class="col-2 pull-right text-right"><i
                          class="fa fa-plus-circle fa-primary-large" aria-hidden="true"></i></span>
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                      3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                      laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                      occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.</p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link row w-100 p-0 text-left collapsed" data-toggle="collapse"
                      data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="col-10 pl-0">Q: What is the minimum educational qualification required to apply for
                        the exam?</span> <span class="col-2 pull-right text-right"><i
                          class="fa fa-plus-circle fa-primary-large" aria-hidden="true"></i></span>
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                      3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                      laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                      occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.</p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link row w-100 p-0 text-left collapsed" data-toggle="collapse"
                      data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span class="col-10 pl-0">Q: What is the minimum educational qualification required to apply for
                        the exam?</span> <span class="col-2 pull-right text-right"><i
                          class="fa fa-plus-circle fa-primary-large" aria-hidden="true"></i></span>
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                      3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                      laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                      occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!-- faq End -->



    </div>
  </div>


  <!-- Right nav -->
  <div class="col-md-2 ">
    <!-- aside container -->
    <div class=" aside">
      <nav class="nav mb-5">
        <a class="nav-link w-100 active" href="#summary">Summary</a>
        <a class="nav-link w-100" href="#opportunities">Career Opportunities</a>
        <a class="nav-link w-100" href="#pursue"> Pursue Career in Indian Army</a>
        <a class="nav-link w-100" href="#highlights">Major Highlights</a>
        <a class="nav-link w-100" href="#exam">List of all the Entrance Exam</a>
        <a class="nav-link w-100" href="#vacancies">Vacancies</a>
        <a class="nav-link w-100" href="#faq">FAQ's</a>
      </nav>


      <div id="aside-category">
        <p class="text-muted pl-3 mb-0 small-text font-weight-bold">OTHER CATEGORIES</p>
        <nav class="nav mb-5">
          <a class="nav-link w-100" href=" " routerLink="/career">Education</a>
          <a class="nav-link w-100" href=" " routerLink="/career"> Management</a>
          <a class="nav-link w-100" href=" " routerLink="/career"> Defence</a>
          <a class="nav-link w-100" href=" " routerLink="/career">Healthcare </a>
        </nav>
      </div>

    </div>
    <!-- aside container end-->


  </div>
  <!-- Right nav end -->


</div>
<!-- container End -->



<div class="row">

  <div class="col-md-10">
    <h4>Other Options</h4>

    <div class="row">
      <div class="col-md-6">
        <div id="carouselOptions" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-6 ">
                  <div class="grid data-list">
                    <figure class="effect-white small-device">
                      <img src="assets/img/careers/career/navy.png">
                      <figcaption>
                        <h2>Navy</h2>
                        <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                        <a href="#" routerLink="/careerdetails">&nbsp;</a>
                      </figcaption>
                    </figure>
                  </div>
                </div>

                <div class="col-md-6 ">
                  <div class="grid data-list">
                    <figure class="effect-white small-device">
                      <img src="assets/img/careers/career/airforce.png">
                      <figcaption>
                        <h2>Airforce</h2>
                        <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                        <a href="#" routerLink="/careerdetails">&nbsp;</a>
                      </figcaption>
                    </figure>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="carousel-action">
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>


        </div>
      </div>



      <div class="col-md-6">
        <div class="data-list row">
          <div class="col-md-12">
            <p class="font-weight-bold text-center pt-4 mb-0 ">Your Profile Match</p>
          </div>
          <div class="col-md-121">
            <div class="p-3">
              <img src="assets/img/ratio.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>






  <!-- Enquiry & Support -->
  <div class="col-md-10">
    <div class="data-list">
      <div class="row d-flex ">
        <div class="col-12 col-md-6 ">
          <div class="p-5">
            <h4>Enquiry</h4>
            <div class="row">
              <div class="col-md-12">
                <label>Title</label>
                <div class="form-group"><input type="text" required="" class="form-control "></div>
              </div>
              <div class="col-md-12">
                <label>Message</label>
                <div class="form-group"><textarea required="" class="form-control " cols="5" rows="5"></textarea></div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Submit Message</button>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-6 bg-light-blue d-flex align-items-center justify-content-center">
          <div class="  p-3 text-center  ">
            <img src="assets/img/icon-support.png">
            <h4>Support</h4>
            <p class="pt-1">We provide information about colleges, <br />
              career guidance and many more...</p>
            <p><a class="btn-primary-outline btn ">UPGRADE PLAN</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Enquiry & Support end-->


</div>