import { Component, OnInit } from '@angular/core';
import { ManageProfileService } from '../manage-profile.service';
import { NgForm, FormControl } from '@angular/forms';
import { HeaderComponent } from '../../header/header.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Personal } from '../../models/personal.model';
import { Address } from '../../models/personal.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from "@angular/router";
import { SharedComponent } from '../../shared/shared.component';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})

export class PersonalDetailsComponent implements OnInit {

  userInfo;
  userDetails;
  inputData: object = {};
  address;
  details: object = { first_name: "", email: "", category: "", marital_status: "" };
  isLoading: boolean = true;
  date;
  serializedDate;
  show: boolean = true;
  state_list: any;
  external_id = '';

  constructor(private _profileService: ManageProfileService, private _header: HeaderComponent, public router: Router, private afAuth: AngularFireAuth, private firestore: AngularFirestore, private _common: SharedComponent) {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (this.userInfo != null) {
      this._header.getUserStatus(this.userInfo.uid);
      this.details['first_name'] = this.userDetails['first_name'];
      this.details['last_name'] = this.userDetails['last_name'];
      this.details['email'] = this.userInfo['email'];
      this.state_list = this._common.stateList();
    } else {
      this.router.navigate(['home']);
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.date = new FormControl(new Date());
    this.serializedDate = new FormControl((new Date()).toISOString());
    this.getDetails();
  }

  async showForm() {
    this.show = true;
  }

  async addDetails(form: NgForm) {
    this.isLoading = true;
    if (form.valid) {
      let dbDate = new Date(form.value['date_of_birth']);
      let dobDate = new Date(
        Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())
      ).toISOString();

      let inputData: Personal = {
        first_name: form.value['first_name'],
        last_name: form.value['last_name'],
        email: form.value['email'],
        date_of_birth: dobDate,
        gender: form.value['gender'],
        category: form.value['category'],
        marital_status: form.value['marital_status'],
        external_id: 'aspirant_personal_' + uuid()
      }

      let aspirantAge = this._common.getAge(dbDate);

      let inputAddress: Address = {
        address: (form.value['address'] == undefined) ? form.value['address'] : '',
        city: (form.value['city'] == undefined) ? form.value['city'] : '',
        state: (form.value['state'] == undefined) ? form.value['state'] : '',
        country: (form.value['country'] == undefined) ? form.value['country'] : '',
        zip_code: (form.value['zip_code'] == undefined) ? form.value['zip_code'] : ''
      }

      inputData['age'] = aspirantAge;
      inputData['current_address'] = inputAddress;

      let getStorageDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getStorageDetails != null) {

        getStorageDetails['aspirant_personal'] = inputData;
        getStorageDetails['aspirant_education'] = getStorageDetails.aspirant_education ? getStorageDetails.aspirant_education : {};
        getStorageDetails['aspirant_skills'] = getStorageDetails.aspirant_skills ? getStorageDetails.aspirant_skills : {};
        getStorageDetails['aspirant_fitness'] = getStorageDetails.aspirant_fitness ? getStorageDetails.aspirant_fitness : {};
        getStorageDetails['aspirant_interests'] = getStorageDetails.aspirant_interests ? getStorageDetails.aspirant_interests : {};
        getStorageDetails['aspirant_social_profile'] = getStorageDetails.aspirant_social_profile ? getStorageDetails.aspirant_social_profile : {};
        getStorageDetails['aspirant_certifications'] = getStorageDetails.aspirant_certifications ? getStorageDetails.aspirant_certifications : {};
        getStorageDetails['aspirant_language'] = getStorageDetails.aspirant_language ? getStorageDetails.aspirant_language : {};

      } else {

        getStorageDetails = {};
        getStorageDetails['aspirant_personal'] = inputData;
        getStorageDetails['aspirant_education'] = {};
        getStorageDetails['aspirant_skills'] = {};
        getStorageDetails['aspirant_fitness'] = {};
        getStorageDetails['aspirant_interests'] = {};
        getStorageDetails['aspirant_social_profile'] = {};
        getStorageDetails['aspirant_certifications'] = {};
        getStorageDetails['aspirant_language'] = {};

      }

      getStorageDetails['created_by'] = this.userInfo['uid'];
      getStorageDetails['modified_by'] = this.userInfo['uid'];
      getStorageDetails['created_date_time'] = new Date();
      getStorageDetails['modified_date_time'] = new Date();
      getStorageDetails['operation'] = 'created';
      getStorageDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.addPersonalDetails('aspirants', getStorageDetails);

      let data = await this._header.getDetails();
      if (data != undefined) {
        this.getDetails();
      }
      // let data = await this._header.getDetails();
      // // if (data != undefined) {
      // //   this.getDetails();
      // //   this._header.suggestedCoursesOnFieldsUpdation();
      // //   this._header.suggestedExamsOnFieldsUpdation();
      // // }
      this.show = true;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }


  async getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result['aspirant_personal'] != undefined && Object.keys(result['aspirant_personal']).length != 0) {
          this.details = result['aspirant_personal'];
          this.address = (result['aspirant_personal']['current_address'] == undefined) ? { country: 'India', address: '', city: '', state: '', zip_code: '' } : result['aspirant_personal']['current_address'];
          this.address['country'] = 'India';
          this.details['id'] = result.id;
          this.external_id = this.details['external_id'];
          this.show = true;
        } else {
          this.details['id'] = (result == null) ? '' : result.id;
          this.address = { country: 'India', address: '', city: '', state: '', zip_code: '' };
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }


  async updateDetails(form: NgForm) {

    this.isLoading = true;

    if (form.valid) {
      let formControls = form.controls;
      let detailsId = this.details['id'];
      let dbDate = new Date(form.value['date_of_birth']);
      let dobDate = new Date(
        Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())
      ).toISOString();


      let inputData: Personal = {
        first_name: form.value['first_name'],
        last_name: form.value['last_name'],
        email: form.value['email'],
        date_of_birth: dobDate,
        gender: form.value['gender'],
        category: form.value['category'],
        marital_status: form.value['marital_status'],
        external_id: this.external_id != '' ? this.external_id : 'aspirant_personal_' + uuid()
      }

      this.userDetails['first_name'] = form.value['first_name'];
      this.userDetails['last_name'] = form.value['last_name'];


      let aspirantAge = this._common.getAge(dbDate);
      inputData['age'] = aspirantAge;

      let inputAddress: Address = {
        address: (form.value['address'] != undefined) ? form.value['address'] : '',
        city: (form.value['city'] != undefined) ? form.value['city'] : '',
        state: (form.value['state'] != undefined) ? form.value['state'] : '',
        country: (form.value['country'] != undefined) ? form.value['country'] : '',
        zip_code: (form.value['zip_code'] != undefined) ? form.value['zip_code'] : ''
      }

      inputData['current_address'] = inputAddress;

      let getStorageDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (getStorageDetails != null) {

        getStorageDetails['aspirant_personal'] = inputData;
        getStorageDetails['aspirant_education'] = getStorageDetails.aspirant_education ? getStorageDetails.aspirant_education : {};
        getStorageDetails['aspirant_skills'] = getStorageDetails.aspirant_skills ? getStorageDetails.aspirant_skills : {};
        getStorageDetails['aspirant_fitness'] = getStorageDetails.aspirant_fitness ? getStorageDetails.aspirant_fitness : {};
        getStorageDetails['aspirant_interests'] = getStorageDetails.aspirant_interests ? getStorageDetails.aspirant_interests : {};
        getStorageDetails['aspirant_social_profile'] = getStorageDetails.aspirant_social_profile ? getStorageDetails.aspirant_social_profile : {};
        getStorageDetails['aspirant_certifications'] = getStorageDetails.aspirant_certifications ? getStorageDetails.aspirant_certifications : {};
        getStorageDetails['aspirant_language'] = getStorageDetails.aspirant_language ? getStorageDetails.aspirant_language : {};


        getStorageDetails['created_by'] = this.userInfo['uid'];
        getStorageDetails['modified_by'] = this.userInfo['uid'];
        getStorageDetails['created_date_time'] = new Date();
        getStorageDetails['modified_date_time'] = new Date();
        getStorageDetails['operation'] = 'created';
        getStorageDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

        let result = await this._profileService.updatePersonalDetails('aspirants', detailsId, getStorageDetails);

        delete getStorageDetails['userId'];
        delete getStorageDetails['created_by'];
        delete getStorageDetails['modified_by'];

        sessionStorage.setItem('aspirantInfo', JSON.stringify(getStorageDetails));
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));

        // if (formControls.date_of_birth.dirty) {
        //   this._header.suggestedCoursesOnFieldsUpdation();
        // }

        // if (formControls.marital_status.dirty) {
        //   this._header.suggestedExamsOnFieldsUpdation();
        // }

        this.show = true;
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }


  async getAge(born) {
    let today = new Date();
    let birthDate = new Date(born);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  resetForm(form: NgForm) {
    form.reset();
  }

  cancel() {
    this.show = false;
  }

}
