<!-- Success / Failed message start here-->

<app-payment #payment> </app-payment>

<!-- Success / Failed message end here-->

<div class="page-heading row">
    <div class="heading col-lg-8 col-md-6 col-sm-12">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerlink="/dashboard" ng-reflect-router-link="/dashboard">Dashboard /
                        {{(quiz_details['name'] != undefined)?quiz_details['name']:quiz_details['quiz_name'] }}</a>
                </li>
            </ol>
        </nav>
        <h2>{{(quiz_details['name'] != undefined)?quiz_details['name']:quiz_details['quiz_name'] }}</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-8">

        <p class="readmessage">Read the following passage carefully and answer the following questions given
            below it. Certain words are given in bold to help you locate them while answering
            some of the questions.
        </p>
        <ol *ngIf="quiz_details['settings'] != undefined && quiz_details['settings']['quiz_stop'] == true">
            <li>
                <b>Use of keyboard is prohibited!</b>
            </li>
            <li>
                <b>Please refrain yourself from using keyboard buttons like Escape & F11 buttons!</b>
            </li>
            <li>
                <b>Anytype of unusual activity will lead to end the quiz!</b>
            </li>
        </ol>


        <div class="cs-following_passage">
            <p class="cs-texthead"> Test Name </p>
            <p> {{ (quiz_details['name'] != undefined)?quiz_details['name']:quiz_details['quiz_name'] }}</p>
            <p class="cs-texthead"> Description </p>
            <p [innerHtml]="quiz_details['settings'] != undefined?quiz_details['settings']['description']:''">
            </p>

            <div class="select-language">
                <p class="cs-texthead">Select Language</p>
                <select data-width="fit">
                    <option>English</option>
                    <!-- <option>Hindi</option> -->
                </select>
            </div>
        </div>


    </div>
    <div class="col-md-4">
        <div class="cs-rightsection">
            <div class="cs-imgtop">
                <img src="{{quiz_details['banner_image']}}"
                    *ngIf="(quiz_details['banner_image'] != undefined);else imageJobSection" class="img-fluid"
                    width="245" onerror="this.src='assets/img/Layer-18.png'" />

                <ng-template #imageJobSection><img src="assets/img/Layer-18.png" alt=""></ng-template>

            </div>
            <div class="innerinfo">
                <h4>{{quiz_details['name']}}</h4>
                <div class="cs-abilityinfo d-block d-md-flex heading mb-1">
                    <div class="col-md-6 p-0">Category</div>
                    <div class="col-md-6 p-0">Duration</div>

                </div>

                <div class="cs-abilityinfo d-block d-md-flex  mb-1">
                    <div class="col-md-6 p-0 ">
                        {{quiz_details['category_name'] != undefined?quiz_details['category_name'].join(', '):'N/A'}}
                    </div>
                    <div class="col-md-6 p-0">
                        {{quiz_details['settings'] != undefined ? quiz_details['settings']['duration']['minutes'] : 0}}
                        min </div>
                </div>

                <div class="cs-abilityinfo d-block d-md-flex heading mb-1">
                    <div class="col-md-6 p-0">Eligibility</div>
                    <div class="col-md-6 p-0">Age</div>
                </div>

                <div class="cs-abilityinfo d-block d-md-flex mb-1">
                    <!-- <sup>th</sup> -->
                    <div class="col-md-6 p-0"
                        *ngIf="quiz_details['settings'] != undefined && quiz_details['settings']['eligibility'] == 'All';else others">
                        For All</div>
                    <ng-template #others>
                        <div class="col-md-6 p-0">
                            Only {{ quiz_details['settings']!=undefined?quiz_details['settings']['eligibility']:'' }}
                            Passed
                        </div>
                    </ng-template>
                    <div class="col-md-6 p-0">Any</div>
                </div>

                <div class="cs-attmpbtn mb-1">
                    <a href="#" target="_blank" routerLink="/quiz/{{quiz_id}}" data-toggle="modal"
                        data-target="#myModal" (click)="startQuiz()"
                        *ngIf="showResult == false && showContinue == false && buy == false">Attempt Quizz</a>

                    <a href="#" target="_blank" routerLink="/quiz/{{quiz_id}}" data-toggle="modal"
                        data-target="#myModal" (click)="startQuiz()" *ngIf="showContinue == true">Continue Quizz</a>

                    <a href="#" routerLink="/result/{{quiz_id}}" *ngIf="showResult == true">Check Result</a>

                </div>
                <div class="row mt-4">
                    <div class="col text-center ">
                        <a class="btn-primary px-4 py-2 d-block w-100" href="javascript:void(0)"
                            *ngIf="(quiz_details['settings'] != undefined && quiz_details['settings']['access_type']) == 'Paid' && buy == true"
                            routerLink="/order-review/{{quiz_id}}"><strong class="h5"><i class="fas fa-rupee-sign"></i>
                                {{amount}} </strong> - BUY QUIZ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true" *ngIf="show_quiz == true">
    <div class="modal-dialog modal-full">
        <div class="modal-content">
            <div class="modal-body">
                <app-quiz></app-quiz>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->