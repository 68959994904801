<div class="page-heading row">
    <div class="heading col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
            </ol>
        </nav>
        <h2>Manage Profile</h2>
    </div>
</div>

<div class="row">
    <!-- Aside container -->

    <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

    <!-- Aside container end-->

    <!-- Profile container -->
    <div class="col-md-9 col-lg-9">


        <div class="filter-tab ">
            <ul class="list-unstyled list-inline">
                <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
                <li class="list-inline-item "><a routerLink="/manage-profile/skills">Skill</a></li>
                <li class="list-inline-item active"><a routerLink="/manage-profile/interest">Interest</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
            </ul>
        </div>



        <div class="data-list p-3 mb-3 ">


            <!-- <div class="row mb-3" *ngIf="show==false">
            <div class="col-md-12">
                 <div class="pull-right"><a class="btn btn-primary-outline m-0 " href="javascript:void(0)">+ Add
                        Interest</a>
                </div> 
                <p class="form-msg">Add your interest</p>
            </div>
        </div> -->

            <div class="card bg-form mb-3" *ngIf="show==false">
                <div class="cardHeader ">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="pull-right"><a href="javascript:void(0)"
                                    class="btn btn-secondary-outline btn-small mr-2" (click)="showForm()">Edit</a>
                                <!-- <a
                                href="javascript:void(0)" class="btn btn-secondary-outline btn-small ">Delete</a> -->
                            </div>
                            <div class="profile-details">
                                <h2> {{ details['interested_carrer'] }} </h2>
                                <h3>{{ details['department'] }} <span class="badge">Job Type
                                        <strong>{{ details['job_type'] ? details['job_type'] : 'N/A' }}</strong></span>
                                </h3>
                                <ul>
                                    <li *ngIf="details['desired_location']"><span class="badge-orange mr-2">{{ details['desired_location'] }}</span>
                                        <!-- <span
                                        class="badge-orange mr-2">Mumbai</span> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <form #interest="ngForm" *ngIf="show==true"
                (ngSubmit)="details['id'] ? updateDetails(interest) : !details['id'] ? addDetails(interest):addDetails(interest)"
                ngNativeValidate>
                <div class="row">
                    <div class="col-md-6"><label>Interest Career<span class="required">*</span></label>
                        <div class="form-group">
                            <!-- <select class="form-control" name="interested_carrer"
                            [(ngModel)]="details['interested_carrer']">
                                <option value="engineering">Engineering</option>
                                <option value="medical">Medical</option>
                                <option value="travelling">Travelling</option>
                            </select> -->

                            <input type="text" class="form-control" name="interested_carrer" placeholder="careers"
                                (keypress)="textOnly($event)" [(ngModel)]="details['interested_carrer']"
                                [ngbTypeahead]="searchInterest" #instance="ngbTypeahead" required />
                        </div>
                    </div>

                    <div class="col-md-6"><label>Job Type<span class="required">*</span></label>
                        <div class="form-group"> <select class="form-control" name="job_type"
                                [(ngModel)]="details['job_type']" required>
                                <option value="">Please select</option>
                                <option value="government">Government</option>
                                <option value="private">Private</option>
                            </select></div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6"><label>Functional Area/Department<span class="required">*</span></label>
                        <div class="form-group"> 
                            <input type="text" class="form-control" name="department" placeholder="department"
                                (keypress)="textOnly($event)" [(ngModel)]="details['department']"
                                [ngbTypeahead]="searchDepartment"   #instanceDepartment="ngbTypeahead" required />
                        </div>
                    </div>

                    <div class="col-md-6"><label>Career Type<span class="required">*</span></label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <div class="custom-control custom-radio mb-3">
                                    <input type="radio" class="custom-control-input" id="customControlValidation4"
                                        name="career_type" [(ngModel)]="details['career_type']" value="permanent"
                                        required="">
                                    <label class="custom-control-label" for="customControlValidation4">Permanent</label>
                                </div>

                            </div>
                            <div class="form-check form-check-inline">
                                <div class="custom-control custom-radio mb-3">
                                    <input type="radio" class="custom-control-input" name="career_type"
                                        [(ngModel)]="details['career_type']" value="contractual"
                                        id="customControlValidation5" required="">
                                    <label class="custom-control-label"
                                        for="customControlValidation5">Contractual</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6"><label>Desired Location<span class="required">*</span></label>
                        <div class="form-group"> 
                            <!-- <select class="form-control" name="desired_location"
                                [(ngModel)]="details['desired_location']" required>
                                <option value="">Please select</option>
                                <option value="delhi">Delhi</option>
                                <option value="pune">Pune</option>
                                <option value="mumbai">Mumbai</option>
                                <option value="dehradun">Dehradun</option>
                                <option value="bheemtal">Bheemtal</option>
                            </select> -->
                            <input type="text" class="form-control" name="desired_location" placeholder="location"
                            (keypress)="textOnly($event)" [(ngModel)]="details['desired_location']"
                            [ngbTypeahead]="searchLocation"  #instanceLocation="ngbTypeahead" required />
                        </div>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-secondary-outline" *ngIf="cancelButton == true" (click)="cancel()">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>


<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>