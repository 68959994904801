<!-- Breadcrumb -->
<div class="page-heading row">
   <div class="heading col-sm-12 ">
      <nav aria-label="breadcrumb">
         <ol class="breadcrumb">
            <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Disciplines</li>
         </ol>
      </nav>
      <h2>Courses </h2>
   </div>
</div>





<!-- courses container -->
<div class="row block-holder">

   <div class="col-md-6 col-lg-4" *ngFor="let list of discipline_list">
      <div class="grid data-list">
         <figure class="effect-sadie">
            <img src="{{list?.discipline_details?.img_url}}" class="img-fluid mb-3" style="height:332px"
               *ngIf="list?.discipline_details?.img_url != null && list?.discipline_details?.img_url != 'null';else imageDisciplineSection"
               onerror="this.src='assets/img/courses/management.png'">
            <ng-template #imageDisciplineSection><img class="img-fluid mb-3" src="assets/img/courses/management.png">
            </ng-template>
            <figcaption>
               <h2>{{list?.display_name}}</h2>
               <p [innerHTML]="list?.discipline_details?.summary | split:'.' "></p>
               <a href="#" routerLink="/discipline/{{list?.name}}">MORE</a>
            </figcaption>
         </figure>
      </div>

   </div>
</div>

<div class="text-center py-3">
   <button type="button" class="btn btn-primary-outline btn-sm" (click)="loadMore()" *ngIf="disable_next == false">Load
      More</button>
</div>
<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>