import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router } from "@angular/router";
import { AuthService } from '../auth.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { User } from "../models/user.models";
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  isLoading: boolean = false;
  userData;
  userDetails = {};
  successMsg: string = 'Thank You for Registering!.We have sent a confirmation email.Please click on link to verify!';
  showLogin: boolean = false;
  showSignup: boolean = false;
  constructor(public router: Router, private toastr: ToastrService, public authService: AuthService, public afAuth: AngularFireAuth, public afs: AngularFirestore, private location: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
  }
  ngOnInit(): void {
    $(document).ready(() => {
      $('#myModal').modal('show');
    })
  }


  // Sign in with Google
  GoogleAuth() {
    this.isLoading = true;
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        this.userData = result.user;
        let existUser = await this.userExists(result.user.uid);
        if (!existUser) {
          this.setUserData(this.userData);
        }
        localStorage.setItem('userInfo', JSON.stringify(this.userData));
        this.router.navigate(['dashboard']);
        this.isLoading = false;
      }).catch((error) => {
        this.toastr.error(error)
        this.isLoading = false;
      })
  }

  // Sign in with Facebook
  FacebookAuth() {
    this.isLoading = true;
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }


  async userExists(userId: string) {
    return await this.afs.doc(`users/${userId}`).valueChanges().pipe(first()).toPromise();
  }



  // Sign in with email/password
  signIn(email, password) {
    this.isLoading = true;
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        this.userData = result.user;

        let existUser = await this.userExists(result.user.uid);
        this.userDetails['first_name'] = existUser['first_name'];
        this.userDetails['last_name'] = existUser['last_name'];
        this.userDetails['role'] = existUser['role'];

        if (existUser['status'] == 'Active') {
          if (existUser['role'] == 'Aspirant' || existUser['role'] == 'Employee') {

            localStorage.setItem('userInfo', JSON.stringify(this.userData));
            localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
            this.router.navigate(['dashboard']);
            this.isLoading = false;
          } else {
            this.toastr.error('Unauthorized access!.User with role aspirants & employees are allowed to login here!')
            this.isLoading = false;
          }
        } else {
          this.toastr.error('User is not in active state.Please contact your administrator!')
          this.isLoading = false;
        }
      }).catch((error) => {
        this.toastr.error(error)
        this.isLoading = false;
      })
  }


  // Sign up with email/password
  signUp(email: string, password: string, name: string) {
    this.isLoading = true;
    var ref = this;
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.sendVerificationMail(name);
        result.user.updateProfile({
          displayName: name
        }).then(function () {
          ref.setUserData(result.user);
        }, function (error) {
          this.isLoading = false;
          this.toastr.error(error)
        });
        this.toastr.success(this.successMsg)
        $("#signup").modal('hide');
        $("#myModal").modal('show');
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.message)
        $("#signup").modal('hide');
      })
  }


  //Send email verfificaiton when new user sign up
  async sendVerificationMail(name: string) {
    let actionCodeSettings = {
      url: environment.emailUrl,
      handleCodeInApp: false
    }
    return (await this.afAuth.currentUser).sendEmailVerification(actionCodeSettings);
  }

  setUserData(user, name = null) {

    let displayName: string;
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    if (user.displayName == null) {
      displayName = name;
    } else {
      displayName = user.displayName
    }

    const userData: User = {
      userId: user.uid,
      first_name: displayName,
      last_name: '',
      email: user.email,
      displayName: displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      role: 'Aspirant',
      status: 'Active',
      invite: false,
      created_at: new Date(),
      updated_at: new Date()
    }

    return userRef.set(userData, {
      merge: true
    })
  }

  hideSignup() {
    $("#signup").modal('hide');
  }

  hideLogin() {
    $("#myModal").modal('hide');
  }
}
