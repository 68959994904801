<div class="mob-profile"><a href="javascript:void(0);" class="slider-arrow show btn btn-secondary">Profile
        &raquo;</a></div>
<div class="left-nav  p-3 data-list block-height-max">
    <div class="profile-link">
        <div class="media d-block text-center">
            <div class="avatar-upload">
                <div class="avatar-preview">


                    <img *ngIf="userInfo?.photoURL;else defaultImg" src="{{userInfo?.photoURL}}" alt="...">
                    <ng-template #defaultImg><img src="assets/img/profile-thumb.png" alt="..."></ng-template>

                </div>


                <div class="avatar-edit">
                    <label for="imageUpload"></label>
                    <input type="file" (change)="fileChangeEvent($event)" />
                </div>
            </div>

            <div class="media-body">
                <h6 class="mt-2 mb-0 font-weight-bold">{{userDetails?.first_name }} {{ userDetails?.last_name }}</h6>
                <p class="small-text" *ngIf="eduDetails['course']">{{eduDetails['course']}} from
                    {{eduDetails['university']}}</p>
            </div>
        </div>
    </div>

    <div class="other-link mt-4">
        <ul class="nav  row">
            <li class="nav-item  mb-4 col-lg-12 col-md-6">
                <div class="row">

                    <div class="col-7">
                        <p class="small-text">Education</p>
                    </div>
                    <div class="col-5">
                        <div class="progress-num">{{educationPercent}}%</div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="{{educationStyle}}" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="col-12">
                        <p class="font-weight-bold pb-2">{{userQualification}}</p>
                    </div>
                </div>

            </li>

            <li class="nav-item    mb-4 col-lg-12 col-md-6">
                <div class="row">
                    <div class="col-7">
                        <p class="small-text">Skills</p>
                    </div>
                    <div class="col-5">
                        <div class="progress-num">{{skillsPercent}}%</div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="{{skillsStyle}}" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>


                    <div class="col-12">
                        <p class="font-weight-bold pb-2">{{skillsName}}</p>
                    </div>
                </div>
            </li>


            <li class="nav-item    mb-4 col-lg-12 col-md-6">
                <div class="row">
                    <div class="col-7 pr-0">
                        <p class="small-text">Interested Career</p>
                    </div>
                    <div class="col-5">
                        <div class="progress-num">{{interestPercent}}%</div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="{{interestStyle}}" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="col-12">
                        <p class="font-weight-bold pb-2">{{intrestDetails['interested_carrer']}}</p>
                    </div>
                </div>
            </li>


            <li class="nav-item   col-lg-12 col-md-6 mb-4 pb-4">
                <div class="row">
                    <div class="col-7">
                        <p class="small-text">Fitness</p>
                    </div>
                    <div class="col-5">
                        <div class="progress-num">{{fitnessPercent}}%</div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="{{fitnessStyle}}" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="font-weight-bold pb-2">{{fitnesDetails['height']}} (height in cm)</p>
                    </div>
                </div>
            </li>

            <li class="nav-item   col-lg-12 text-right mt-5 mb-4" *ngIf="updateProfileButton==true">
                <a class="btn btn-primary-outline w-100  m-0" id="updateProfile"
                    routerLink="/manage-profile/personal">{{'update_label' | translate }}</a>
            </li>

        </ul>
    </div>

</div>
<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>

<!-- Modal -->

<div class="modal fade" id="ImageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-heading">
                    <h2>{{'welcome_label' | translate}} <span
                            class="orange-text">{{'title1_label' | translate }}</span><span
                            class="blue-text">{{'title2_label' | translate}}</span></h2>
                    <p> {{'details_label' | translate  }}</p>
                    <h3 class="mt-4 mb-4">Crop Image</h3>
                </div>
                <div class="loading" *ngIf="isLoadingImage == true">Loading&#8230;</div>
                <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4/3" [resizeToWidth]="128" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
            </div>
            <button class="submit btn btn-primary" (click)="cropIt()">Done</button>

        </div>
    </div>
</div>