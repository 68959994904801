import { Component, OnInit } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HeaderComponent } from '../header/header.component';


@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent implements OnInit {

  isLoading: boolean = false;
  course_details_result: any = [];
  course_id: string;
  suggested_courses: any = [];
  discipline_list: any = [];

  constructor(private _profileService: ManageProfileService, private afAuth: AngularFireAuth, private _router: Router, private _route: ActivatedRoute, private _header: HeaderComponent) {
    this.isLoading = true;
    this._route.paramMap.subscribe(url => {
      this.course_id = url['params']['id'];
      this.courseDetails(this.course_id);
    })
  }

  async ngOnInit() {

    let href = this._router.url.split("#");

    if (href[1] == 'entry') {
      window.scroll(0, 700);
    } else if (href[1] == 'highlights') {
      window.scroll(0, 1100);
    } else if (href[1] == 'career') {
      window.scroll(0, 1600);
    } else if (href[1] == 'summary') {
      window.scroll(0, 100);
    }

    let data = await this._header.allSuggestedCourses();             //suggest courses to aspirant based on cuurentQualifications//

    if (data != undefined && data.length > 0) {
      this.suggested_courses = data;
    } else {
      this.suggested_courses = [];
    }
    this.discipline_list = await this._header.disciplineList();
  }



  courseDetails(id: string) {
    this.afAuth.onAuthStateChanged(async user => {
      if (user != null) {
        let result = await this._profileService.getCourseDetails('courses', id);
        if (result.length > 0) {
          this.course_details_result = result[0];
        }
      }
      this.isLoading = false;
    });
  }

}
