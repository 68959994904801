import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageProfileService } from '../manage-profile.service';
import { NgForm, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../../header/header.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Interests } from '../../models/interests.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { SharedComponent } from '../../shared/shared.component';


@Component({
  selector: 'app-interest',
  templateUrl: './interest.component.html',
  styleUrls: ['./interest.component.css']
})


export class InterestComponent implements OnInit {

  userInfo;
  inputData: object = {};
  details: object = { job_type: '', interested_carrer: '', department: '', desired_location: '' };
  isLoading: boolean = true;
  interest;
  show: boolean = false;
  cancelButton: boolean = false;
  external_id = '';

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('instanceDepartment', { static: true }) instanceDepartment: NgbTypeahead;
  @ViewChild('instanceLocation', { static: true }) instanceLocation: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searchInterest = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.interest
        : this.interest.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  searchDepartment = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instanceDepartment.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this._common.departmentList()
        : this._common.departmentList().filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  searchLocation = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instanceLocation.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this._common.locationList()
        :this._common.locationList().filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }



  constructor(private _profileService: ManageProfileService, private toastr: ToastrService, public router: Router, private _header: HeaderComponent, private afAuth: AngularFireAuth, private firestore: AngularFirestore,private _common: SharedComponent) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
      this.getAllInterest();
    } else {
      this.router.navigate(['home']);
    }
  }

  async addDetails(form: NgForm) {

    this.isLoading = true;
    this.show = true;

    if (form.valid) {

      let inputData: Interests = {
        job_type: form.value['job_type'],
        interested_carrer: form.value['interested_carrer'],
        department: form.value['department'],
        desired_location: form.value['desired_location'],
        career_type: form.value['career_type'],
        external_id: 'aspirant_interest_' + uuid()
      }


      let getInterestsDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getInterestsDetails != null) {

        getInterestsDetails['aspirant_personal'] = getInterestsDetails.aspirant_personal ? getInterestsDetails.aspirant_personal : {};
        getInterestsDetails['aspirant_education'] = getInterestsDetails.aspirant_education ? getInterestsDetails.aspirant_education : {};
        getInterestsDetails['aspirant_skills'] = getInterestsDetails.aspirant_skills ? getInterestsDetails.aspirant_skills : {};
        getInterestsDetails['aspirant_fitness'] = getInterestsDetails.aspirant_fitness ? getInterestsDetails.aspirant_fitness : {};
        getInterestsDetails['aspirant_interests'] = inputData;
        getInterestsDetails['aspirant_social_profile'] = getInterestsDetails.aspirant_social_profile ? getInterestsDetails.aspirant_social_profile : {};
        getInterestsDetails['aspirant_certifications'] = getInterestsDetails.aspirant_certifications ? getInterestsDetails.aspirant_certifications : {};
        getInterestsDetails['aspirant_language'] = getInterestsDetails.aspirant_language ? getInterestsDetails.aspirant_language : {};

      } else {

        getInterestsDetails = {};
        getInterestsDetails['aspirant_personal'] = {};
        getInterestsDetails['aspirant_education'] = {};
        getInterestsDetails['aspirant_skills'] = {};
        getInterestsDetails['aspirant_fitness'] = {};
        getInterestsDetails['aspirant_interests'] = inputData;
        getInterestsDetails['aspirant_social_profile'] = {};
        getInterestsDetails['aspirant_certifications'] = {};
        getInterestsDetails['aspirant_language'] = {};

      }


      getInterestsDetails['created_by'] = this.userInfo['uid'];
      getInterestsDetails['modified_by'] = this.userInfo['uid'];
      getInterestsDetails['created_date_time'] = new Date();
      getInterestsDetails['modified_date_time'] = new Date();
      getInterestsDetails['operation'] = 'created';
      getInterestsDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      let result = await this._profileService.addInterestDetails('aspirants', getInterestsDetails);
      let data = await this._header.getDetails();
      if (data != undefined) {
        this.getDetails();
      }
      this.show = false;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }


  async getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_interests != undefined && Object.keys(result.aspirant_interests).length != 0) {
          this.details = result.aspirant_interests;
          this.details['id'] = result.id;
          this.external_id = this.details['external_id'];
          this.cancelButton = true;
          this.show = false;
        } else {
          this.details = { job_type: '', interested_carrer: '', department: '', desired_location: '' };
          this.details['id'] = (result == null) ? '' : result.id;
          this.cancelButton = false;
          this.show = true;
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }


  async updateDetails(form: NgForm) {

    this.isLoading = true;

    if (form.valid) {

      let detailsId = this.details['id'];
      let inputData: Interests = {
        job_type: form.value['job_type'],
        interested_carrer: form.value['interested_carrer'],
        department: form.value['department'],
        desired_location: form.value['desired_location'],
        career_type: form.value['career_type'],
        external_id: this.external_id != '' ? this.external_id : 'aspirant_interest_' + uuid()
      }

      let getInterstsDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getInterstsDetails['aspirant_personal'] = getInterstsDetails.aspirant_personal ? getInterstsDetails.aspirant_personal : {};
      getInterstsDetails['aspirant_education'] = getInterstsDetails.aspirant_education ? getInterstsDetails.aspirant_education : {};
      getInterstsDetails['aspirant_skills'] = getInterstsDetails.aspirant_skills ? getInterstsDetails.aspirant_skills : {};
      getInterstsDetails['aspirant_fitness'] = getInterstsDetails.aspirant_fitness ? getInterstsDetails.aspirant_fitness : {};
      getInterstsDetails['aspirant_interests'] = inputData;
      getInterstsDetails['aspirant_social_profile'] = getInterstsDetails.aspirant_social_profile ? getInterstsDetails.aspirant_social_profile : {};
      getInterstsDetails['aspirant_certifications'] = getInterstsDetails.aspirant_certifications ? getInterstsDetails.aspirant_certifications : {};
      getInterstsDetails['aspirant_language'] = getInterstsDetails.aspirant_language ? getInterstsDetails.aspirant_language : {};
      


      getInterstsDetails['created_by'] = this.userInfo['uid'];
      getInterstsDetails['modified_by'] = this.userInfo['uid'];
      getInterstsDetails['created_date_time'] = new Date();
      getInterstsDetails['modified_date_time'] = new Date();
      getInterstsDetails['operation'] = 'created';
      getInterstsDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      // delete getInterstsDetails['id'];


      let result = await this._profileService.updateInterestDetails('aspirants', detailsId, getInterstsDetails);

      delete getInterstsDetails['userId'];
      delete getInterstsDetails['created_by'];
      delete getInterstsDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getInterstsDetails));

      this.show = false;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }


  async getAllInterest() {
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = await this._profileService.getInterestList('interests');
        //let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result.length > 0) {
          this.interest = result[0]['name'];
          this.isLoading = false;
        } else {
          this.interest = [];
        }
      } else {
        this._header.signOut();
      }
    });
  }

  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }


  showForm() {
    this.cancelButton = true;
    this.show = true;
  }


  resetForm(form: NgForm) {
    form.reset();
  }

  cancel() {
    this.getDetails();
    this.show = false;
  }
}
