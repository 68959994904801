<!-- Breadcrumb -->
<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item "><a routerLink="/courses">Discipline</a></li>
        <li class="breadcrumb-item "><a routerLink="/discipline/{{course_details_result?.course_details?.discipline}}">{{course_details_result?.course_details?.discipline}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{course_details_result?.display_name}}</li>
      </ol>
    </nav>
    <h2>{{course_details_result?.display_name}} </h2>
  </div>
</div>





<!-- container -->
<div class="row">
  <div class="col-md-10">
    <div class="data-list ">

      <!-- Summary Start -->
      <div id="summary" class="p-5 mb-0 crs-summary">
        <div class="row">
          <div class="col-12 order-item">
            <div class="pull-right pl-3 order-md-2 order-size">
              <img *ngIf="(course_details_result?.course_information?.course_summary?.img_url != 'null' && course_details_result?.course_information?.course_summary?.img_url != null);else imageSection"
                src="{{course_details_result?.course_information?.course_summary?.img_url}}" onerror="this.src='assets/img/courses/finance/img-finance.png'" class="img-fluid "
                width="318" />
              <ng-template #imageSection><img class="img-fluid mb-3" src="assets/img/courses/finance/img-finance.png">
              </ng-template>
            </div>
            <div class="order-md-1 order-size">
              <h1>{{course_details_result?.course_information?.course_summary?.title}}</h1>
              <span class="badge badge-pill  badge-outline p-2 mr-2 mb-2"
                *ngFor="let tags of course_details_result?.course_information?.course_summary?.tags">{{tags}}</span>
              <p class="pt-3 lead" [innerHTML]="course_details_result?.course_information?.course_summary?.summary">
              </p>
            </div>
          </div>

        </div>
      </div>
      <!-- Summary End -->


      <!-- Entry -->
      <div id="entry" class="p-5 mt-0 mb-2 bg-light-grey">
        <div class="row">
          <div class="col-12 order-item">
            <div class="pull-right  order-md-2 order-size text-center">
              <img *ngIf="(course_details_result?.course_information?.entry_requirement?.img_url != 'null' && course_details_result?.course_information?.entry_requirement?.img_url != null);else imageEntrySection"
                src="{{course_details_result?.course_information?.entry_requirement?.img_url}}" onerror="this.src='assets/img/courses/finance/img-req.png'" width="272"
                class="img-fluid ml-4 mt-4" />
              <ng-template #imageEntrySection><img class="img-fluid mb-3" src="assets/img/courses/finance/img-req.png">
              </ng-template>
            </div>

            <div class="order-md-1 order-size">
              <h3>Entry Requirement</h3>
              <p> {{course_details_result?.course_information?.entry_requirement?.title}}</p>
              <p class="lead font-weight-bold"
                [innerHTML]="course_details_result?.course_information?.entry_requirement?.summary"></p>


              <div class="bg-light-orange p-3 w-50 rounded mb-3 imp-points "
                *ngIf="course_details_result?.course_information?.entry_requirement?.options.length > 0">
                <dl class="row mb-0"
                  *ngFor="let option of course_details_result?.course_information?.entry_requirement?.options">
                  <dt class="col-sm-5">{{option.label}} </dt>
                  <dd class="col-sm-7">{{option.value}}</dd>
                </dl>
              </div>
            </div>

          </div>


        </div>
      </div>
      <!-- Entry End -->


      <!-- Highlights -->
      <div id="highlights" class="p-5 mt-0 mb-2">
        <div class="row ">
          <div class="col-12">
            <h3>Major highlights of the course</h3>
          </div>
        </div>
        <div class="row list-highlight m-0">
          <dl class="col-md-12 col-lg-6 row m-0 p-0"
            *ngFor="let key_highlights of course_details_result?.course_information?.highlights">

            <dt class="col-sm-6">{{key_highlights.level}} </dt>
            <dd class="col-sm-6">{{key_highlights.value}}</dd>

          </dl>

        </div>
      </div>
      <!-- Highlights End -->




      <!-- Apply -->
      <div id="apply" class="p-5   mb-2">
        <div class="row">
          <div class="col-12 order-item">
            <div class="pull-right pl-3 order-md-2 order-size">
              <img *ngIf="(course_details_result?.course_information?.how_to_apply?.img_url != 'null' && course_details_result?.course_information?.how_to_apply?.img_url != null);else imageApplySection"
                src="{{course_details_result?.course_information?.how_to_apply?.img_url}}"  onerror="this.src='assets/img/courses/finance/img-apply.png'" class="img-fluid"
                width="366" />
              <ng-template #imageApplySection><img class="img-fluid mb-3"
                  src="assets/img/courses/finance/img-apply.png"></ng-template>
            </div>
            <div class="order-md-1 order-size">
              <h3>How to apply</h3>

              <p class="lead" [innerHTML]="course_details_result?.course_information?.how_to_apply?.summary"></p>
              <div class="bg-light-orange p-3 w-50 rounded"
                *ngIf="course_details_result?.course_information?.how_to_apply?.apply.length > 0">
                <dl class="row mb-0"
                  *ngFor="let how_apply of course_details_result?.course_information?.how_to_apply?.apply">
                  <dt class="col-sm-6">{{how_apply.label}} </dt>
                  <dd class="col-sm-6">{{how_apply.value}}</dd>

                </dl>
              </div>

            </div>
          </div>




        </div>
      </div>
      <!-- Apply End -->


      <!-- Top Test -->
      <div id="test" class="p-5 mt-0 mb-2 bg-light-blue">
        <div class="row">
          <div class="col-md-12 col-lg-6 text-center ">
            <img width="299"
              *ngIf="(course_details_result?.course_information?.top_entrance_exam?.img_url != 'null' && course_details_result?.course_information?.top_entrance_exam?.img_url != null);else imageExamSection"
              src="{{course_details_result?.course_information?.top_entrance_exam?.img_url}}" onerror="this.src='assets/img/courses/finance/img-exam.png'"  class="img-fluid" />

            <ng-template #imageExamSection><img class="img-fluid mb-3" src="assets/img/courses/finance/img-exam.png">
            </ng-template>

          </div>
          <div class="col-md-12 col-lg-6  pt-3">
            <h3>Top Entrance Exams</h3>
            <p [innerHTML]="course_details_result?.course_information?.top_entrance_exam?.summary"></p>
            <div class="carousel row d-flex flex-nowrap scroll" data-flickity='{ "groupCells": true }'>
              <div class="col-md-4 carousel-cell"
                *ngFor="let top_exam of course_details_result?.course_information?.top_entrance_exam?.exam;let j=index">
                <span
                  [ngClass]="j === 0 ? 'circle circle-blue d-flex align-items-center justify-content-center': 'circle circle-blue-outline d-flex align-items-center justify-content-center'">{{top_exam}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Top Test End -->



      <!-- Career -->
      <div id="career" class="p-5   mb-2">

        <div class="row">
          <div class="col-12">
            <h3>Career Options</h3>
            <p [innerHTML]="course_details_result?.course_information?.career_option?.summary"></p>
          </div>
          <div class="col-12 order-item">
            <div class="pull-right pl-3 order-md-2 order-size"><img
                *ngIf="(course_details_result?.course_information?.career_option?.img_url != 'null' && course_details_result?.course_information?.career_option?.img_url != null);else imageCareerSection"
                src="{{course_details_result?.course_information?.career_option?.img_url}}" onerror="this.src='assets/img/courses/finance/job.png'" width="245" />

              <ng-template #imageCareerSection><img class="img-fluid mb-3" src="assets/img/courses/finance/job.png">
              </ng-template>

            </div>
            <div class="order-md-1 order-size pl-0">
              <span
                [ngClass]="k === 0 ? 'badge badge-primary p-3 rounded mr-2 mb-2 badge-font-medium': 'badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium'"
                *ngFor="let career_options of course_details_result?.course_information?.career_option?.job_opportunity;let k=index">{{career_options}}</span>
            </div>
          </div>


        </div>
      </div>
      <!-- Career End -->




      <!-- Exam -->
      <div id="exam" class="p-5  mb2">
        <h3>Top courses</h3>
        <div class="row mt-3">
          <div class="col-md-3">
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
          </div>
          <div class="col-md-3">
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
          </div>
          <div class="col-md-3">
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
          </div>
          <div class="col-md-3">
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
            <a href="#" class="d-block mb-3">Bcom Marketing</a>
          </div>
        </div>
      </div>
      <!-- exam End -->




    </div>
  </div>



  <!-- Right nav -->
  <div class="col-md-2 pl-0">

    <!-- aside container -->
    <div class=" aside">
      <nav class="nav mb-5" id="fixed-nav">
        <a class="nav-link w-100 active" href="coursedetails/{{course_id}}#summary">Course summary</a>
        <a class="nav-link w-100" href="coursedetails/{{course_id}}#entry">Entry requirement</a>
        <a class="nav-link w-100" href="coursedetails/{{course_id}}#highlights"> Major Highlights</a>
        <a class="nav-link w-100" href="coursedetails/{{course_id}}#apply">How to apply</a>
        <a class="nav-link w-100" href="coursedetails/{{course_id}}#career">Careers</a>
      </nav>



      <div id="aside-category">
        <p class="text-muted pl-3 mb-0 small-text font-weight-bold">OTHER CATEGORIES</p>
        <nav class="nav mb-5">
          <a class="nav-link w-100" href=" " *ngFor="let list of discipline_list"
            routerLink="/discipline/{{list.name}}">{{list.display_name}}</a>
          <!-- <a class="nav-link w-100" href=" " routerLink="/courses">Education</a>
          <a class="nav-link w-100" href=" " routerLink="/courses"> Management</a>
          <a class="nav-link w-100" href=" " routerLink="/courses"> Defence</a>
          <a class="nav-link w-100" href=" " routerLink="/courses">Healthcare </a> -->
        </nav>
      </div>


      <div id="aside-category">
        <p class="text-muted pl-3 mb-0 small-text font-weight-bold">Course Suggestions</p>
        <nav class="nav mb-5">
          <a class="nav-link w-100" href="#" routerLink="/coursedetails/{{course.courseid}}"
            *ngFor="let course of suggested_courses">{{course.title}}</a>
        </nav>
      </div>

    </div>
    <!-- aside container end-->

  </div>
  <!-- Right nav end -->

</div>

<!-- container End -->

<div class="row">
  <div class="col-md-10">
    <div class="data-list row profile-match">
      <div class="col-md-4">
        <p class="font-weight-bold text-center pt-5 ">Your Profile Match</p>
      </div>
      <div class="col-md-8">
        <div class="p-3">
          <img src="assets/img/ratio.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-10 col-lg-10">
    <div class="data-list std-enroll">
      <div class="row">
        <div class="col-md-6">
          <div class="p-3 text-center pt-5">
            <h2 class="  orange-text mb-0 font-weight-bold">26+</h2>
            <p class="font-weight-bold mb-0">Students enrolled
              in course</p>
          </div>
        </div>
        <div class="col-md-6">
          <div id="carouselStudentFeedback" class="carousel slide" data-ride="carousel">

            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="mb-0 pt-2"> I got a job after complete this course thank to
                  open-naukri to provide right guidance about
                  right college</p>
              </div>
              <div class="carousel-item">
                <p class="mb-0 pt-2"> I got a job after complete this course thank to
                  open-naukri to provide right guidance about
                  right college</p>
              </div>
              <div class="carousel-item">
                <p class="mb-0 pt-2"> I got a job after complete this course thank to
                  open-naukri to provide right guidance about
                  right college
                </p>
              </div>
            </div>

            <ol class="carousel-indicators">
              <li data-target="#carouselStudentFeedback" data-slide-to="0" class="active"> <img
                  src="assets/img/thumb1.png" /></li>
              <li data-target="#carouselStudentFeedback" data-slide-to="1"><img src="assets/img/thumb2.png" /></li>
              <li data-target="#carouselStudentFeedback" data-slide-to="2"><img src="assets/img/thumb3.png" /></li>
            </ol>

          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Enquiry & Support -->
  <div class="col-md-10">
    <div class="data-list">
      <div class="row d-flex ">
        <div class="col-12 col-md-6 ">
          <div class="p-5">
            <h4>Enquiry</h4>
            <div class="row">
              <div class="col-md-12">
                <label>Title</label>
                <div class="form-group"><input type="text" required="" class="form-control "></div>
              </div>
              <div class="col-md-12">
                <label>Message</label>
                <div class="form-group"><textarea required="" class="form-control " cols="5" rows="5"></textarea></div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Submit Message</button>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-6 bg-light-blue d-flex align-items-center justify-content-center">
          <div class="  p-3 text-center  ">
            <img src="assets/img/icon-support.png">
            <h4>Support</h4>
            <p class="pt-1">We provide information about colleges, <br />
              career guidance and many more...</p>
            <p><a class="btn-primary-outline btn ">UPGRADE PLAN</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Enquiry & Support end-->


</div>

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>