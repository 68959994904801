<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
      </ol>
    </nav>
    <h2>Manage Profile</h2>
  </div>
</div>

<div class="row">
  <!-- Aside container -->

  <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>


  <!-- Aside container end-->

  <!-- Profile container -->
  <div class="col-md-9 col-lg-9">
    <div class="filter-tab ">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item active"><a routerLink="/manage-profile/personal">Personal Details</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
        <li class="list-inline-item "><a routerLink="/manage-profile/skills">Skill</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
      </ul>
    </div>


    <div class="data-list p-3 mb-3 " *ngIf="show==false">
      <div class="card bg-form mb-3">
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right"><a href="javascript:void(0)" (click)="showForm()"
                class="btn btn-secondary-outline btn-small">Edit</a></div>
            <div class="profile-details">
              <h3> {{ details['gender'] ? details['gender'] : details['first_name'] }} <span class="badge"
                  *ngIf="details['category']">Category
                  <strong>{{ details['category'] ? details['category'] : 'N/A'  }}</strong></span> </h3>
              <ul>
                <li><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ details['email'] ? details['email'] : 'NA' }}
                </li>
                <li><i class="fa fa-map-marker" aria-hidden="true"></i> {{ address?.city ? address['city'] : 'NA' }}
                </li>
                <li><i class="fa fa-calendar" aria-hidden="true"></i>
                  {{ details['date_of_birth'] ? (details['date_of_birth'] | date: 'dd-MM-yyyy' ) : 'NA' }}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>

    <form #personal="ngForm"
      (ngSubmit)="details['id'] ? updateDetails(personal) : !details['id'] ? addDetails(personal):addDetails(personal)"
      ngNativeValidate>
      <div class="data-list p-3 mb-3 " *ngIf="show==true">
        <h5 class="font-weight-bold">Personal</h5>
        <div class="row">
          <div class="col-md-6"><label>First Name <span class="required">*</span></label>
            <div class="form-group"><input type="text" class="form-control" placeholder="first name" name="first_name"
                [(ngModel)]="details['first_name']" required></div>
          </div>

          <div class="col-md-6"><label>Last Name<span class="required">*</span></label>
            <div class="form-group"><input type="text" class="form-control" name="last_name"
                [(ngModel)]="details['last_name']" placeholder="last name" required="required"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"><label>Email Address <span class="required">*</span></label>
            <div class="form-group"><input type="email" class="form-control" name="email" [(ngModel)]="details['email']"
                placeholder="test@example.com" readonly required></div>
          </div>
          <!-- <div *ngIf="email.touched && email?.invalid" class="alert alert-danger">

            <div *ngIf="email.errors?.required">Email is required.</div>

            <div *ngIf="email.errors?.email">Please, enter valid email address.</div>

          </div> -->


          <!-- <div class="col-md-6"><label>Current Place <span class="required">*</span></label>
            <div class="form-group">
              <input type="text" class="form-control" name="city" [(ngModel)]="details['city']" placeholder="City"
                required>
            </div>
          </div> -->

          <div class="col-md-6"><label>Date of Birth <span class="required">*</span></label>
            <div class="form-group">
              <mat-form-field>
                <input matInput [matDatepicker]="picker3" name="date_of_birth" [(ngModel)]="details['date_of_birth']"
                  required>
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>


        <div class="row">

          <div class="col-md-6">
            <label>Gender <span class="required">*</span></label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="customControlValidation2" value="male"
                    name="gender" [(ngModel)]="details['gender']" required>
                  <label class="custom-control-label" for="customControlValidation2">Male</label>
                </div>
              </div>
              <div class="form-check form-check-inline">
                <div class="custom-control custom-radio mb-3">
                  <input type="radio" class="custom-control-input" id="customControlValidation3" name="gender"
                    value="female" [(ngModel)]="details['gender']" required>
                  <label class="custom-control-label" for="customControlValidation3">Female</label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <label>Category</label>
            <div class="form-group">
              <select class="form-control" name="category" [(ngModel)]="details['category']" required>
                <option [value]="''">Select Category</option>
                <option value="general">General</option>
                <option value="obc">OBC</option>
                <option value="sc/st">SC/ST</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>Marital Status<span class="required">*</span></label>
            <div class="form-group">
              <select class="form-control" name="marital_status" [(ngModel)]="details['marital_status']" required>
                <option [value]="''">Select Status</option>
                <option value="Marrried">Married</option>
                <option value="Unmarried">Unmarried</option>
                <option value="Divorcee">Divorcee</option>
                <option value="Widows">Widows</option>
              </select>
            </div>
          </div>
        </div>


        <h5 class="font-weight-bold mt-3">Current Address</h5>

        <div class="row">
          <div class="col-md-6">
            <label>Address</label>
            <div class="form-group">
              <input type="text" class="form-control" name="address" [ngModel]="address?.address"
                placeholder="Address">
            </div>
          </div>

          <div class="col-md-6">
            <label>City</label>
            <div class="form-group">
              <input type="text" class="form-control" name="city" [ngModel]="address?.city" placeholder="City">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>State</label>
            <div class="form-group">
              <!-- <input type="text" class="form-control" name="state" [ngModel]="address?.state" placeholder="State"> -->
               <select class="form-control" name="state" [ngModel]="address?.state">
                <option [value]="''">Select State</option>
                <option *ngFor="let list of state_list">{{list?.name}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <label>Country</label>
            <div class="form-group">
              <input type="text" class="form-control" name="country" [ngModel]="address?.country" placeholder="Country">
              <!-- <select class="form-control" name="country" [(ngModel)]="address['country']">
                <option [value]="''">Select Country</option>
                <option value="India">India</option>
                <option value="Nepal">Nepal</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Sri lanka">Sri Lanka</option>
              </select> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>Zip</label>
            <div class="form-group">
              <input type="text" class="form-control" name="zip_code" [ngModel]="address?.zip_code" maxlength="6"
                placeholder="ZIP/Postal code">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <button class="btn btn-secondary-outline" (click)="cancel()" type="submit">Cancel</button>
            <button class="btn btn-primary" type="submit">Submit</button>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>