<!-- Navbar -->
<nav class="   navbar-expand-lg navbar-light header" id="brand">
  <div class="container ">
    <div class="row   align-items-center py-4 py-md-2">
      <div class="col-2 d-lg-none"> <button class="navbar-toggler   float-left px-0" type="button"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button></div>
      <div class="col px-0 px-md-4 text-center text-lg-left"> <a class="navbar-brand mr-0" routerLink="/dashboard"><img
            src="assets/img/logo.png" class="img-fluid"></a></div>
      <div class="col-md-3 col">
        <div class="  pull-right btn-group">
          <a class="nav-link waves-effect waves-light  dropdown-toggle dropdown-profile rounded" href="#" role="button"
            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img *ngIf="userInfo?.photoURL;else defaultImg" src="{{image}}" style="width:22px;height:22px;  "
              class="mr-1 rounded-circle" alt="...">
            <ng-template #defaultImg><img src="/assets/img/profile.png" class="mr-2" alt="..."></ng-template>
            {{userDetails?.first_name }} {{ userDetails?.last_name }}
          </a>

          <div class="dropdown-menu   py-0 dropdown-menu-end shadow-sm" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" routerLink="manage-profile/personal">Manage Profile</a>
            <a class="dropdown-item" routerLink="my-learning" *ngIf="userDetails?.role == 'Aspirant'">My Learning</a>
            <a class="dropdown-item" routerLink="orders" *ngIf="userDetails?.role == 'Aspirant'">My Orders</a>
            <a class="dropdown-item" (click)="signOut()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light-blue toggle-menu">
    <div class="container ">
      <div class="row  ">
        <div class="col-lg-12 ">

          <!-- Collapse -->
          <!-- Links -->
          <div class="collapse navbar-collapse py-3 py-lg-0" id="navbarSupportedContent">

            <!-- right -->
            <ul class="navbar-nav   w-100 navScroll  ">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light d-flex align-items-center" routerLink="dashboard"><svg
                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-speedometer2 mr-2" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                    <path fill-rule="evenodd"
                      d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
                  </svg> {{'menu_dashboard_label' | translate }}</a>
              </li>
              <li class="nav-item" routerLinkActive="active" *ngIf="userDetails?.role == 'Aspirant'">
                <a class="nav-link waves-effect waves-light d-flex align-items-center" routerLink="my-learning"><svg
                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-book mr-2" viewBox="0 0 16 16">
                    <path
                      d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                  </svg> {{'menu_my_learning' | translate }}</a>
              </li>
              <li class="nav-item" *ngIf="userDetails?.role == 'Aspirant'">
                <a class="nav-link waves-effect waves-light d-flex align-items-center" href="javascript:void(0)"
                  routerLink="courses">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-building mr-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                    <path
                      d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                  </svg>
                  Courses</a>
              </li>

              <li class="nav-item" *ngIf="userDetails?.role == 'Aspirant'">
                <a class="nav-link waves-effect waves-light d-flex align-items-center" href="javascript:void(0)"  routerLink="careers">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-newspaper mr-2" viewBox="0 0 16 16">
                    <path
                      d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                    <path
                      d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z" />
                  </svg>
                  {{'menu_careers_label' | translate }}</a>
              </li>

              <!--<li class="nav-item">
                <a class="nav-link waves-effect waves-light d-flex align-items-center" href="javascript:void(0)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-question-diamond mr-2" viewBox="0 0 16 16">
                    <path
                      d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                    <path
                      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                  </svg>
                  {{'menu_assessment_label' | translate }}</a>
              </li> -->



               <li class="nav-item d-flex align-items-center flex-fill" *ngIf="userDetails?.role == 'Aspirant'">
                <div class="input-group  ">
                  <span class="input-group-text bg-white" style="border:0; border-radius:.25rem 0 0 .25rem;"><svg
                      xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg></span>
                  <input type="text" class="form-control glb-search pl-0" style="border:0;" placeholder="SEARCH">
                </div>
              </li> 
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</nav>