import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ManageProfileService } from '../manage-profile.service';
import { HeaderComponent } from '../../header/header.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import * as _ from 'lodash';

declare var $: any;


@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.css']
})
export class CertificationsComponent implements OnInit {

  isLoading: boolean = false;
  allYears = [];
  userInfo;
  aspirant_certifications: FormGroup;
  inputSkills: object = {};
  details;
  noSkills: boolean = false;
  detailsid;
  skills;
  show: boolean = false;
  selectedIndex;
  myClass = 'hide-class';
  uniqueCertification: string;
  cancelButton: boolean = false;
  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private fb: FormBuilder, private afAuth: AngularFireAuth, private firestore: AngularFirestore) { }


  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
      this.uniqueCertification = 'aspirant_certification_' + uuid();
      this.aspirant_certifications = new FormGroup({
        Certificates: new FormArray([
          this.initCertificates(),
        ]),
      });
    } else {
      this.router.navigate(['home']);
    }

    let curYear = new Date().getFullYear() + 9;

    for (let i = 1990; i <= curYear; i++) {
      this.allYears.push(i);
    }

  }


  initCertificates() {
    return new FormGroup({
      certification_name: new FormControl(''),
      certified_by: new FormControl(''),
      year_of_completion: new FormControl(''),
      external_id: new FormControl(this.uniqueCertification),
    });
  }

  addCertificates() {
    const control = <FormArray>this.aspirant_certifications.get('Certificates');
    control.push(this.initCertificates());
    let index = control.length - 1;
    this.show = true;
    this.selectedIndex = index;
    this.myClass = 'hide-class';
  }

  getCertificates(form) {
    return form.controls.Certificates.controls;
  }

  removeSkills(i) {
    const control = <FormArray>this.aspirant_certifications.get('Certificates');
    control.removeAt(i);
  }

  async addDetails(form) {

    if (form.valid) {

      this.isLoading = true;
      let inputData = form.value;
      let getCertificateDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getCertificateDetails != null) {

        getCertificateDetails['aspirant_personal'] = getCertificateDetails.aspirant_personal ? getCertificateDetails.aspirant_personal : {};
        getCertificateDetails['aspirant_education'] = getCertificateDetails.aspirant_education ? getCertificateDetails.aspirant_education : {};
        getCertificateDetails['aspirant_skills'] = getCertificateDetails.aspirant_skills ? getCertificateDetails.aspirant_skills : {};
        getCertificateDetails['aspirant_fitness'] = getCertificateDetails.aspirant_fitness ? getCertificateDetails.aspirant_fitness : {};
        getCertificateDetails['aspirant_interests'] = getCertificateDetails.aspirant_interests ? getCertificateDetails.aspirant_interests : {};
        getCertificateDetails['aspirant_social_profile'] = getCertificateDetails.aspirant_social_profile ? getCertificateDetails.aspirant_social_profile : {};
        getCertificateDetails['aspirant_language'] = getCertificateDetails.aspirant_language ? getCertificateDetails.aspirant_language : {};
        getCertificateDetails['aspirant_certifications'] = inputData;


      } else {

        getCertificateDetails = {};
        getCertificateDetails['aspirant_personal'] = {};
        getCertificateDetails['aspirant_education'] = {};
        getCertificateDetails['aspirant_skills'] = {};
        getCertificateDetails['aspirant_fitness'] = {};
        getCertificateDetails['aspirant_interests'] = {};
        getCertificateDetails['aspirant_social_profile'] = {};
        getCertificateDetails['aspirant_language'] = {};
        getCertificateDetails['aspirant_certifications'] = inputData;
      }


      getCertificateDetails['created_by'] = this.userInfo['uid'];
      getCertificateDetails['modified_by'] = this.userInfo['uid'];
      getCertificateDetails['created_date_time'] = new Date();
      getCertificateDetails['modified_date_time'] = new Date();
      getCertificateDetails['operation'] = 'created';
      getCertificateDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      let result = await this._profileService.addCertificationDetails('aspirants', getCertificateDetails);
      let data = await this._header.getDetails();
      if (data != undefined) {
        this.getDetails();
      }
      this.isLoading = false;
    }
  }



  getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        //let result = await this._profileService.getSkillsDetails('aspirant_skills', user.uid);
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_certifications != undefined && Object.keys(result.aspirant_certifications).length != 0) {
          this.details = _.orderBy(result.aspirant_certifications.Certificates, [(profile) => profile.year_of_completion], ["desc"]);
          this.detailsid = result.id;
          this.noSkills = false;
          this.aspirant_certifications.setControl('Certificates', this.setExistingCertificates(this.details))
          this.show = false;
          this.cancelButton = true;
          this.isLoading = false;
        } else {
          this.detailsid = (result == null) ? '' : result.id;
          this.noSkills = true;
          this.show = true;
          this.selectedIndex = 0
          this.cancelButton = false;
          this.myClass = 'hide-class';
        }
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });

  }


  setExistingCertificates(certificateSet) {
    let formarray = new FormArray([]);
    certificateSet.forEach(s => {
      formarray.push(this.fb.group({
        certification_name: s.certification_name,
        certified_by: s.certified_by,
        year_of_completion: s.year_of_completion,
        external_id: s.external_id,
      }))
    })
    return formarray;
  }



  async updateDetails(form) {

    if (form.valid) {

      this.isLoading = true;
      let inputData = form.value;
      let getCertificateDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getCertificateDetails['aspirant_personal'] = getCertificateDetails.aspirant_personal ? getCertificateDetails.aspirant_personal : {};
      getCertificateDetails['aspirant_education'] = getCertificateDetails.aspirant_education ? getCertificateDetails.aspirant_education : {};
      getCertificateDetails['aspirant_skills'] = getCertificateDetails.aspirant_skills ? getCertificateDetails.aspirant_skills : {};
      getCertificateDetails['aspirant_fitness'] = getCertificateDetails.aspirant_fitness ? getCertificateDetails.aspirant_fitness : {};
      getCertificateDetails['aspirant_interests'] = getCertificateDetails.aspirant_interests ? getCertificateDetails.aspirant_interests : {};
      getCertificateDetails['aspirant_social_profile'] = getCertificateDetails.aspirant_social_profile ? getCertificateDetails.aspirant_social_profile : {};
      getCertificateDetails['aspirant_language'] = getCertificateDetails.aspirant_language ? getCertificateDetails.aspirant_language : {};
      getCertificateDetails['aspirant_certifications'] = inputData;

      getCertificateDetails['created_by'] = this.userInfo['uid'];
      getCertificateDetails['modified_by'] = this.userInfo['uid'];
      getCertificateDetails['created_date_time'] = new Date();
      getCertificateDetails['modified_date_time'] = new Date();
      getCertificateDetails['operation'] = 'created';
      getCertificateDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.updateCertificationDetails('aspirants', this.detailsid, getCertificateDetails);

      delete getCertificateDetails['userId'];
      delete getCertificateDetails['created_by'];
      delete getCertificateDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getCertificateDetails));

      this.ngOnInit();
      this.isLoading = false;
    }
  }



  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  cancel() {
    this.getDetails();
    this.myClass = 'hide-class';
    this.show = false;
    this.cancelButton = true;
  }

  showForm(index) {
    this.selectedIndex = index;
    this.show = true;
  }

  resetForm() {
    this.getDetails();
    this.show = false;
  }
}
