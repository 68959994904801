<!-- Breadcrumb -->
<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item"><a routerLink="/courses">Discipline</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{discipline_details_result?.display_name}}</li>
      </ol>
    </nav>
    <h2>{{discipline_details_result?.display_name}} </h2>
  </div>
</div>



<!-- container -->
<div class="row ">


  <div class="col-md-10 ">
    <div class="data-list ">

      <!-- Summary Start -->
      <div id="summary" class="p-5 ">
        <div class="row">
          <div class="col order-item">
            <div class="pull-right pl-3 order-md-2 order-size text-center">
              <img src="{{discipline_details_result?.discipline_details.img_url}}"
                *ngIf="(discipline_details_result?.discipline_details.img_url != 'null' && discipline_details_result?.discipline_details.img_url != null);else imagesummarySection"
                onerror="this.src='assets/img/courses/finance.png'" class="img-fluid " width="318" />
              <ng-template #imagesummarySection><img class="img-fluid mb-3" src="assets/img/courses/finance.png">
              </ng-template>
            </div>
            <div class="order-md-1 order-size">
              <h1>{{discipline_details_result?.discipline_details?.title}}</h1>
              <span class="badge badge-pill  badge-outline p-2 mr-2 mb-2"
                *ngFor="let tags of discipline_details_result?.discipline_details?.tags">{{tags}}</span>
              <p class=" lead" [innerHTML]="discipline_details_result?.discipline_details.summary"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary End -->


      <!-- Growth -->
      <div id="growth" class="growth p-5 mb-2">
        <div class="row">
          <div class="col-12">
            <h3>Growth Prospect</h3>
            <p>{{discipline_details_result?.growth_prospects.title}}</p>
          </div>
        </div>
        <div class="row  pb-5">
          <div class="col-md-2 pt-5"><img src="assets/img/courses/finance/img-men.png"
              class="img-fluid mt-5 img-char" /></div>
          <div class="col-md-10 ">
            <div class="row scroll d-flex  flex-nowrap pt-5 stairs-holder">
              <div
                [ngClass]="i === 0 ? ' text-right  pr-0 align-items-end': 'text-right  pr-0 pl-0 d-flex align-items-start'"
                class=" d-flex  text-right pr-0"
                *ngFor="let growth_level of discipline_details_result?.growth_prospects.prospects_level;let i=index;">

                <div [ngClass]="growthClass(i)">
                  <p class="growth-position ellipsis">{{growth_level.value}}</p>
                  <h5 class="font-weight-bold ellipsis">{{growth_level.level}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Growth End -->

      <!-- Salary -->
      <div id="salary">
        <div class="p-3 text-center">
          <h3>Salary Range</h3>
          <p> {{discipline_details_result?.salary_range.title}}</p>
        </div>
        <div class="salary-growth   ">
          <div class="row m-0">
            <table class="table table-striped mb-0 table-responsive w-auto mx-auto d-inline-block">
              <tr>
                <td class=" text-center pb-0 mb-0 border-0"
                  *ngFor="let salary_level of discipline_details_result?.salary_range.salary_level;let j = index">
                  <div class="bar-info">
                    <p> {{salary_level.text}}<br />
                      <span class="font-weight-bold">{{salary_level.value}}</span></p>
                    <div class="bar">
                      <div [ngClass]="classObject(j)" role="progressbar" aria-valuenow="" aria-valuemin="0"
                        aria-valuemax="100" [ngStyle]="styleObject(j)">&nbsp;
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- Salary End -->

      <!-- Job -->
      <div id="job" class="p-5 mt-5 mb-5">

        <div class="row">
          <div class="col-12 ">
            <h3>{{discipline_details_result?.display_name}} Jobs</h3>
            <p> {{discipline_details_result?.jobs.title}}</p>
          </div>
          <div class="col order-item">
            <div class="order-md-2 pull-right pl-3 order-size text-center">
              <img src="{{discipline_details_result?.jobs?.img_url}}"
                *ngIf="(discipline_details_result?.jobs?.img_url != 'null' && discipline_details_result?.jobs?.img_url != null);else imageJobSection"
                class="img-fluid" width="245" onerror="this.src='assets/img/courses/finance/job.png'" />

              <ng-template #imageJobSection><img class="img-fluid mb-3" src="assets/img/courses/finance/job.png">
              </ng-template>
            </div>
            <div class="order-md-1 order-size"> <span
                *ngFor="let jobs of discipline_details_result?.jobs.job_name;let k = index"
                [ngClass]="k === 0 ? 'badge badge-primary p-3 rounded mr-2 mb-2 badge-font-medium': 'badge badge-primary-outline p-3 rounded mr-2 mb-2 badge-font-medium'">{{jobs}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Job End -->


      <!-- Market -->
      <div id="market" class="market-bg p-5 ">
        <h3>Key Projections</h3>
        <p>{{discipline_details_result?.market_opportunity.title}}</p>
        <div class="row mt-5">
          <div class="col-md-12 col-lg-6">

            <div class="row">
              <div class="col-12 mb-4"
                *ngFor="let market of discipline_details_result?.market_opportunity.opportunity_service">
                <h6 class="font-weight-bold">{{market.value}}</h6>
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <div class="progress" style="height: 20px;">
                      <div class="bar-lightblue" role="progressbar" style="width: 55%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <p class="font-weight-bold">{{market.level}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 text-center"><img src="assets/img/courses/finance/market.png" class="img-fluid"
              width="330" /></div>
        </div>

      </div>
      <!-- market End -->

      <!-- Courses -->
      <div id="courses" class="p-5">
        <p class="font-weight-bold">{{discipline_courses.length}} Courses</p>

        <div class="border rounded">
          <table class="table table-striped mb-0 table-responsive">
            <thead>
              <tr>
                <th scope="col">Course Name</th>
                <th scope="col">Duration</th>
                <th scope="col">Fees (Approx)</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let courses of discipline_courses  | paginate: config">
                <th scope="row">{{courses?.display_name}}</th>
                <td *ngIf="courses?.course_details?.duration >= 12;else monthlyOthers">{{courses?.course_details?.duration/12}} years</td>
                <ng-template #monthlyOthers>
                  <td>
                    {{courses?.course_details?.duration}} months
                  </td>
                </ng-template>
                <td><i class="fa fa-inr" *ngIf="courses?.course_details?.fees"
                    aria-hidden="true"></i>{{courses?.course_details?.fees?(courses?.course_details?.fees | number :'1.2-2'):'N/A'}}
                </td>
                <td><a class="btn btn-primary btn-sm m-0" routerLink="/coursedetails/{{courses.id}}">View</a></td>
              </tr>

              <tr *ngIf="discipline_courses.length == 0">
                <td colspan="4">
                  No courses found!
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example" class="mt-4">
          <ul class="pagination justify-content-end mb-0">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
          </ul>
        </nav>
      </div>

      <!-- Course End -->

    </div>
  </div>

  <!-- Right nav -->
  <div class="col-md-2 pl-0 ">
    <!-- aside container -->
    <div class=" aside">
      <nav class="nav mb-5" id="fixed-nav">
        <a class="nav-link active w-100" href="/discipline/{{discipline_name}}#summary">Summary</a>
        <a class="nav-link w-100" href="/discipline/{{discipline_name}}#growth">Growth prospect </a>
        <a class="nav-link" href="/discipline/{{discipline_name}}#salary"> Salary range &amp; income </a>
        <a class="nav-link w-100" href="/discipline/{{discipline_name}}#job">{{discipline_details_result?.display_name}}
          jobs </a>
        <!-- <a class="nav-link w-100" href="javascriot:void(0)"> Personality trends </a> -->
        <a class="nav-link w-100" href="/discipline/{{discipline_name}}#market">Market Opportunities </a>
        <a class="nav-link w-100" href="/discipline/{{discipline_name}}#courses"> Courses</a>
      </nav>


      <div id="aside-category">
        <p class="text-muted pl-3 mb-0 small-text font-weight-bold">OTHER CATEGORIES</p>
        <nav class="nav mb-5">
          <span *ngFor="let list of discipline_list" style="margin-left: 5px;margin-right: 6px;">

            <a class="nav-link w-100" href="" routerLink="/discipline/{{list.name}}"
              *ngIf="list.display_name != discipline_details_result?.display_name"> {{list.display_name}} </a>

          </span>
        </nav>
      </div>

    </div>
    <!-- aside container end-->

  </div>
  <!-- Right nav end -->

</div>


<div class="row">
  <!-- Enquiry & Support -->
  <div class="col-md-10">
    <div class="data-list">
      <div class="row d-flex ">
        <div class="col-12 col-md-6 ">
          <div class="p-5">
            <h4>Enquiry</h4>
            <div class="row">
              <div class="col-md-12">
                <label>Title</label>
                <div class="form-group"><input type="text" required="" class="form-control "></div>
              </div>
              <div class="col-md-12">
                <label>Message</label>
                <div class="form-group"><textarea required="" class="form-control " cols="5" rows="5"></textarea></div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Submit Message</button>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-6 bg-light-blue d-flex align-items-center justify-content-center">
          <div class="  p-3 text-center  ">
            <img src="assets/img/icon-support.png">
            <h4>Support</h4>
            <p class="pt-1">We provide information about colleges, <br />
              career guidance and many more...</p>
            <p><a class="btn-primary-outline btn ">UPGRADE PLAN</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Enquiry & Support end-->

</div>
<!-- container end-->
<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>