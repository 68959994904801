import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize, tap } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { HeaderComponent } from '../header/header.component';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from '@angular/router';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';


declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent implements OnInit {

  userInfo;
  userDetails;
  isLoading: boolean = false;
  isLoadingImage: boolean = false;
  eduDetails: object = {};
  skillsDetails = [];
  intrestDetails: object = {};
  fitnesDetails: object = {};
  totalCount: number;
  userQualification: string;
  allQualification: [];
  skillsName: string;
  user_name: string;
  updateProfileButton: boolean;

  totalEducationFields = 8;
  totalSkillsFields = 3;
  totalInterestFields = 6;
  totalFitnessFields = 8;

  educationPercent: Number;
  skillsPercent: Number;
  interestPercent: Number;
  fitnessPercent: Number;

  educationStyle: string;
  skillsStyle: string;
  interestStyle: string;
  fitnessStyle: string;

  selectedFile: File = null;
  profilePic: string = '';
  downloadURL: Observable<string>;
  maximumUploadSize: Number = 5000000;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn;


  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  personalDetails: any;
  constructor(private _profileService: ManageProfileService, private toastr: ToastrService, private storage: AngularFireStorage, public afs: AngularFirestore, public afAuth: AngularFireAuth, private cdRef: ChangeDetectorRef, private _header: HeaderComponent, private router: Router) {

    let currentHref = this.router.url.split('/');
    if (currentHref[1] == 'manage-profile') {
      this.updateProfileButton = false;
    } else {
      this.updateProfileButton = true;
    }
  }

  private _refreshNeeded$ = new Subject<void>();

  get refreshEducationNeeded$() {
    return this._refreshNeeded$;
  }


  ngOnInit(): void {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));


    if (this.userInfo != null) {

      this.getEducationStatus();
      this.getSkillsStatus();
      this.getIntersetStatus();
      this.getFitnessStatus();
      this.getPersonalDetails();

      this._profileService.refreshEducationNeeded$.subscribe(() => {
        this.getEducationStatus();
      })

      this._profileService.refreshSkillsNeeded$.subscribe(() => {
        this.getSkillsStatus();
      })

      this._profileService.refreshInterestNeeded$.subscribe(() => {
        this.getIntersetStatus();
      })

      this._profileService.refreshFitnessNeeded$.subscribe(() => {
        this.getFitnessStatus();
      })

      this._profileService.refreshGetNeeded$.subscribe(() => {

        this.getFitnessStatus();
        this.getIntersetStatus();
        this.getSkillsStatus();
        this.getEducationStatus();
        this.getPersonalDetails();

      })

      this._profileService.refreshNameNeeded$.subscribe(() => {
        this.getPersonalDetails();
      })
    }
  }


  async signOut() {
    await this.afAuth.signOut();
    localStorage.removeItem('userInfo');
  }

  async getPersonalDetails() {
    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result['aspirant_personal'] != undefined && Object.keys(result['aspirant_personal']).length != 0) {
          this.personalDetails = result['aspirant_personal'];
          this.userDetails['first_name'] = this.personalDetails['first_name'];
          this.userDetails['last_name'] = this.personalDetails['last_name'];
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }

  async getEducationStatus() {
    this.isLoading = true;
    await this.afAuth.onAuthStateChanged(async (user) => {

      if (user != null) {

        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_education != undefined && result.aspirant_education['Education'] != undefined) {

          this.eduDetails = result.aspirant_education.Education[0];
          this.userQualification = result.aspirant_education.HighestQualification;
          this.allQualification = result.aspirant_education.AllEducation;

          switch (this.userQualification) {

            case '10':
              let total_10;
              if (this.allQualification.length > 0) {
                total_10 = 1;
              } else {
                total_10 = 1;
              }

              let totalEducations_10 = 1;
              let count_10 = total_10 / totalEducations_10;
              this.educationPercent = count_10 * 100;
              this.educationStyle = "width:" + this.educationPercent + "%;";
              break;

            case '12':

              let total_12;
              if (this.allQualification.length >= 2) {
                total_12 = 2;
              } else {
                total_12 = 1;
              }

              let totalEducations_12 = 2;
              let count_12 = total_12 / totalEducations_12;
              this.educationPercent = count_12 * 100;
              this.educationStyle = "width:" + this.educationPercent + "%;";

              break;

            case 'Bachelors':
              let total_grad;
              if (this.allQualification.length <= 2 && this.allQualification.length > 1) {
                total_grad = 2;
              } else if (this.allQualification.length >= 3 && this.allQualification.length > 2) {
                total_grad = 3;
              } else {
                total_grad = 1;
              }

              let totalEducations_grad = 3;
              let count_grad = total_grad / totalEducations_grad;
              this.educationPercent = Math.round(count_grad * 100);
              this.educationStyle = "width:" + this.educationPercent + "%;";
              break;


            case 'Diploma':
              let total_diploma;
              if (this.allQualification.length <= 2 && this.allQualification.length > 1) {
                total_diploma = 2;
              } else if (this.allQualification.length >= 3 && this.allQualification.length > 2) {
                total_diploma = 3;
              } else {
                total_diploma = 1;
              }

              let totalEducations_diploma = 3;
              let count_diploma = total_diploma / totalEducations_diploma;
              this.educationPercent = Math.round(count_diploma * 100);
              this.educationStyle = "width:" + this.educationPercent + "%;";
              break;


            case 'Masters':
              let total_pg;
              if (this.allQualification.length <= 2 && this.allQualification.length > 1) {
                total_pg = 2;
              } else if (this.allQualification.length <= 3 && this.allQualification.length > 2) {
                total_pg = 3;
              } else if (this.allQualification.length >= 4 && this.allQualification.length > 3) {
                total_pg = 4;
              } else {
                total_pg = 1;
              }
              let totalEducations_pg = 4;
              let count_pg = total_pg / totalEducations_pg;
              this.educationPercent = count_pg * 100;
              this.educationStyle = "width:" + this.educationPercent + "%;";
              break;

            case 'Doctorate':
              let total_doct;
              if (this.allQualification.length <= 2 && this.allQualification.length > 1) {
                total_doct = 2;
              } else if (this.allQualification.length <= 3 && this.allQualification.length > 2) {
                total_doct = 3;
              } else if (this.allQualification.length <= 4 && this.allQualification.length > 3) {
                total_doct = 4;
              } else if (this.allQualification.length >= 5 && this.allQualification.length > 4) {
                total_doct = 5;
              } else {
                total_doct = 1;
              }

              let totalEducations_doc = 5;
              let count_doc = total_doct / totalEducations_doc;
              this.educationPercent = count_doc * 100;
              this.educationStyle = "width:" + this.educationPercent + "%;";
              break;

            default:
              this.educationPercent = 0 * 100;
              this.educationStyle = "width:" + this.educationPercent + "%;";
          }
        } else {
          this.eduDetails = {};
          this.educationPercent = 0 * 100;
          this.educationStyle = "width:" + this.educationPercent + "%;";
          this.isLoading = false;
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    })
  }


  async getSkillsStatus() {

    this.isLoading = true;
    await this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {

        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_skills != undefined && Object.keys(result.aspirant_skills).length) {
          let data = result.aspirant_skills;
          this.skillsDetails = data['Skills'];
          this.skillsName = data['Skills'][0]['name'];
          let total = this.skillsDetails.length;
          if (total <= 3) {
            let count = total / this.totalSkillsFields;
            this.skillsPercent = Math.round((count * 100));
            this.skillsStyle = "width:" + this.skillsPercent + "%;";
          } else {
            this.skillsPercent = 100;
            this.skillsStyle = "width:" + this.skillsPercent + "%;";
          }
        } else {
          this.isLoading = false;
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }


  async getIntersetStatus() {

    this.isLoading = true;
    await this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_interests != undefined) {
          this.intrestDetails = result.aspirant_interests;
          let total = Object.keys(this.intrestDetails).length;
          let count = total / this.totalInterestFields;
          this.interestPercent = count * 100;
          this.interestStyle = "width:" + this.interestPercent + "%;";
        } else {
          this.isLoading = false;
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }


  async getFitnessStatus() {

    this.isLoading = true;
    await this.afAuth.onAuthStateChanged(async (user) => {

      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));

        if (result != null && result.aspirant_fitness != undefined) {
          this.fitnesDetails = result.aspirant_fitness;
          let total = Object.keys(this.fitnesDetails).length;
          let count = total / this.totalFitnessFields;
          this.fitnessPercent = count * 100;
          this.fitnessStyle = "width:" + this.fitnessPercent + "%;";
        } else {
          this.isLoading = false;
        }
      } else {
        this._header.signOut();
      }
      this.isLoading = false;
    });
  }

  async totalQualification() {
    let result = await this._profileService.getQualificationList('educational_qualification');
    if (result.length > 0) {
      this.totalCount = result.length;
    }
  }


  onFileSelected(file) {
    this.isLoading = true;
    var n = Date.now();
    // const file = event.target.files[0];
    const filePath = `Aspirants/${n}`;
    const fileRef = this.storage.ref(filePath);
    let user = JSON.parse(localStorage.getItem('userInfo'));

    const task = this.storage.upload(`Aspirants/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.profilePic = url;
              user['photoURL'] = this.profilePic;
              localStorage.setItem('userInfo', JSON.stringify(user));
            }
            //this._refreshNeeded$.next();
            this.setUserData(this.profilePic, user.uid);
            this.ngOnInit();
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log('URL ', url);
        }
      });
  }


  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    console.log('filesize ', file.size);
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      this.isLoadingImage = true;
      if (file.size < this.maximumUploadSize) {
        this.imageChangedEvent = event;
        $("#ImageModal").modal('show');
      } else {
        this.toastr.error('Please select less then 5mb file!');
      }
    } else {
      this.toastr.error('Invalid file format!');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
  }

  imageLoaded() {
    console.log('loaded ', event);
    // show cropper
  }
  cropperReady() {
    this.isLoadingImage = false;
    // cropper ready
  }
  loadImageFailed() {
    this.isLoadingImage = false;
    console.log('failed ', event);
    // show message
  }

  cropIt() {
    this.onFileSelected(this.fileToReturn);
    $("#ImageModal").modal('hide');
  }



  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  setUserData(photoURL, uid) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    const userData = {
      photoURL: photoURL
    }

    this.afAuth.authState.subscribe(user => {
      let updateData = user.updateProfile(userData);
    })

    return userRef.set(userData, {
      merge: true
    })
  }
}
