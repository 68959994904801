import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Fitness } from '../models/fitness.model';
import { Interests } from '../models/interests.model';
import { Personal } from '../models/personal.model';
import { Skills } from '../models/skills.model';
import { Educational } from '../models/educational.model';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class ManageProfileService {

  constructor(public _http: HttpClient, private firestore: AngularFirestore) { }

  //dev 
  // apiURL = environment.serviceUrl;
  // apiKey = environment.serviceKey;
  // paymentKey = environment.paymentApiKey;
  // serviceUrl = environment.paymentApiUrl;
  // cloudUrl = environment.cloudCourseUrl;

  //prod
  serviceUrl = environment.serviceUrl;
  httpOptions: { headers: HttpHeaders; };


  private _refreshEducationNeeded$ = new Subject<void>();
  private _refreshSkillsNeeded$ = new Subject<void>();
  private _refreshInterestNeeded$ = new Subject<void>();
  private _refreshFitnessNeeded$ = new Subject<void>();
  private _refreshGetNeeded$ = new Subject<void>();
  private _refreshCourseNeeded$ = new Subject<void>();
  private _refreshNameNeeded$ = new Subject<void>();



  get refreshEducationNeeded$() {
    return this._refreshEducationNeeded$;
  }

  get refreshSkillsNeeded$() {
    return this._refreshSkillsNeeded$;
  }

  get refreshInterestNeeded$() {
    return this._refreshInterestNeeded$;
  }

  get refreshFitnessNeeded$() {
    return this._refreshFitnessNeeded$;
  }

  get refreshGetNeeded$() {
    return this._refreshGetNeeded$;
  }

  get refreshCourseNeeded$() {
    return this._refreshCourseNeeded$;
  }

  get refreshNameNeeded$() {
    return this._refreshNameNeeded$;
  }

  private _refreshPaymentNeeded$ = new Subject<void>();

  get refreshPaymentNeeded$() {
    return this._refreshPaymentNeeded$;
  }



  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  async getCareerCounsellingPrice(collectionName) {

    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

  }
  // POST
  async addPersonalDetails(collectionName, data: Personal) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshNameNeeded$.next();
    return add;
  }

  // GET
  async getPersonalDetails(collectionName, uid) {

    let getdetails = await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

    this._refreshGetNeeded$.next();
    return getdetails;

  }

  // PUT
  async updatePersonalDetails(collectionName, detailsid, data: Personal) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshNameNeeded$.next();
    return update;

  }

  // POST
  async addEducationalDetails(collectionName: string, data: Educational) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshEducationNeeded$.next();

    return add;
  }


  // GET
  async getEducationDetails(collectionName, uid) {


    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

  }



  // PUT
  async updateEducationalDetails(collectionName, detailsid, data: Educational) {


    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshEducationNeeded$.next();
    return update;

  }



  // POST
  async addInterestDetails(collectionName: string, data: Interests) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshInterestNeeded$.next();

    return add;
  }


  // GET
  async getInterestDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

  }



  // PUT
  async updateInterestDetails(collectionName, detailsid, data: Interests) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshInterestNeeded$.next();

    return update;

  }


  // POST
  async addSkillsDetails(collectionName: string, data: Skills) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshSkillsNeeded$.next();

    return add;
  }


  // GET
  async getSkillsDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  // PUT
  async updateSkillsDetails(collectionName, detailsid, data: Skills) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshSkillsNeeded$.next();

    return update;

  }


  // POST
  async addFitnessDetails(collectionName: string, data: Fitness) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshFitnessNeeded$.next();

    return add;
  }


  // GET
  async getFitnessDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }



  // PUT
  async updateFitnessDetails(collectionName, detailsid, data: Fitness) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshFitnessNeeded$.next();

    return update;
  }



  // POST
  async addSocialProfileDetails(collectionName: string, data: Skills) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshSkillsNeeded$.next();

    return add;
  }


  // GET
  async getSocialProfileDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  // PUT
  async updateSocialProfileDetails(collectionName, detailsid, data: Skills) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshSkillsNeeded$.next();

    return update;

  }


  // POST
  async addCertificationDetails(collectionName: string, data: Skills) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshSkillsNeeded$.next();

    return add;
  }


  // GET
  async getCertificationDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  // PUT
  async updateCertificationDetails(collectionName, detailsid, data: Skills) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshSkillsNeeded$.next();

    return update;

  }


  // POST
  async addLanguageDetails(collectionName: string, data: Skills) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshSkillsNeeded$.next();

    return add;
  }


  // GET
  async getLanguageDetails(collectionName, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  // PUT
  async updateLanguageDetails(collectionName, detailsid, data: Skills) {

    let update = await this.firestore.doc(collectionName + '/' + detailsid).update(data);
    this._refreshSkillsNeeded$.next();

    return update;

  }




  // GET ALL Courses
  async getCoursesList(collectionName) {

    return await this.firestore.collection(collectionName, ref => ref.where('isDeleted', '==', false)).valueChanges().pipe(first()).toPromise();

  }

  // GET ALL Courses
  async getQualificationList(collectionName) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();

  }


  // GET ALL University
  async getUniversityList(collectionName: string) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();

  }

  // GET ALL Subject
  async getSubjectList(collectionName: string) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();
  }


  // GET ALL Interests
  async getInterestList(collectionName: string) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();
  }


  // GET ALL Skills
  async getSkillList(collectionName: string) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();
  }

  // GET ALL Skills
  async getDisabilityList(collectionName: string) {

    return await this.firestore.collection(collectionName).valueChanges().pipe(first()).toPromise();
  }


  async getPaymentStatus(collectionName: string, uid, quizId) {
    if (collectionName == 'payments') {
      return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('status', '==', 'paid')).valueChanges().pipe(first()).toPromise();
    } else {
      return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_id', '==', quizId)).valueChanges().pipe(first()).toPromise();
    }
  }


  // // GET Suggest courses //
  // async getSuggestedCourses(collectionName: string, allQualification) {

  //   console.log(collectionName);
  //   console.log(allQualification);
  //   return await this.firestore.collection(collectionName, ref => ref.where('elegibility_criteria.required_program_level', 'in', allQualification)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  // }

  async getDisciplineDetails(collectionName: string, selectedDiscipline) {

    return await this.firestore.collection(collectionName, ref => ref.where('name', '==', selectedDiscipline)).valueChanges().pipe(first()).toPromise();
  }

  async getDisciplineCourses(collectionName: string, name: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('course_details.discipline', '==', name).where('status', '==', 'Published').where('isDeleted', '==', false)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getCourseDetails(collectionName: string, selectedCourse: string) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', selectedCourse)).valueChanges().pipe(first()).toPromise();
  }

  async getCourseSuggestion(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getExamSuggestion(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getAllDisciplineList(collectionName: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('isDeleted', '==', false)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async addCourseSuggestion(aspirantData: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    let result = await this._http.post(this.serviceUrl + "/createUserCourseSuggestion", aspirantData, httpOptions).toPromise();
    this._refreshCourseNeeded$.next();
    return result;
  }

  async addExamSuggestion(aspirantData: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    let result = await this._http.post(this.serviceUrl + "/createExamSuggestion", aspirantData, httpOptions).toPromise();
    this._refreshCourseNeeded$.next();
    return result;
  }

  async createOrder(paymentData: object) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'x-api-key': environment.paymentApiKey
      })
    }

    let result = await this._http.post(this.serviceUrl + "/NewOrder", paymentData, httpOptions).toPromise();
    return result;
  }

  async verifyPayment(paymentData: object) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'x-api-key': environment.paymentApiKey
      })
    }

    let result = await this._http.post(this.serviceUrl + "/VerifyPayment", paymentData, httpOptions).toPromise();
    return result;
  }

  async getRandomQuizQuestions(filtersData) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    let result = await this._http.post(this.serviceUrl + "/generateRandomQuetion", filtersData, httpOptions).toPromise();
    return result;
  }


  async addPaymentDetails(collectionName: string, data: object) {

    let add = await this.firestore.collection(collectionName).add(data);
    this._refreshPaymentNeeded$.next();
    return add;
  }

  async getUserTopOrders(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).orderBy('created_at', 'desc').limit(5)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getPaymentHistory(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).orderBy('created_at', 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getOrderById(collectionName: string, uid: string, orderId: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('order_id', '==', orderId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getPaymentsById(collectionName: string, uid: string, orderId: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('payment_id', '==', orderId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  async getQuizById(collectionName: string, quizId: string) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', quizId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getQuizFromResult(collectionName: string, quizId: string, uid) {

    return await this.firestore.collection(collectionName, ref => ref.where('quiz_id', '==', quizId).where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  async checkPaidQuizzes(collectionName: string, quizId: string, uid) {
    if (quizId != '') {
      return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_id', '==', quizId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    } else {
      return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    }
  }



  async getQuizByName(collectionName: string, quizName: string, duration) {

    return await this.firestore.collection(collectionName, ref => ref.where('name', '==', quizName).where('settings.duration', '==', duration)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getQuizQuestions(collectionName: string, questionsId) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), "in", questionsId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }



  async addAspirantQuizDetails(collectionName: string, data) {

    return await this.firestore.collection(collectionName).add(data);

  }

  async updateAspirantQuizDetails(collectionName: string, quizid, data) {

    let update = await this.firestore.doc(collectionName + '/' + quizid).update(data);
  }

  async getAllQuizzes(collectionName: string, allQualification) {

    return await this.firestore.collection(collectionName, ref => ref.where('isDeleted', '==', false).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.eligibility', 'in', allQualification).orderBy('created_at', 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getAspirantQuizzes(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).orderBy('quiz_id', 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getAttemptedQuizes(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', false)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getCompletedQuizes(collectionName: string, uid: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', true)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getAssignedQuizes(collectionName1: string, userId: string, collectionName2: string) {

    return await this.firestore.collection(collectionName1 + '/' + userId + '/' + collectionName2, ref => ref.where('user_id', '==', userId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getAllEmployeesQuizzes(collectionName, quizId) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), "in", quizId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  async getAllCategories(collectionName: string) {

    return await this.firestore.collection(collectionName, ref => ref.where('isDeleted', '==', false).where('status', '==', 'Published')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


  async getAll(collectionName: string, category: string, status: string, access_type: string, uid: string, allQualification) {

    if (collectionName == 'quizzes' && status != 'new') {
      if (access_type != '' && category != '') {
        return await this.firestore.collection(collectionName, ref => ref.where('category_name', 'array-contains', category).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.access_type', '==', access_type).where('settings.eligibility', 'in', allQualification).orderBy(firebase.firestore.FieldPath.documentId(), 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      } else if (access_type != '') {
        return await this.firestore.collection(collectionName, ref => ref.where('settings.access_type', '==', access_type).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.eligibility', 'in', allQualification).orderBy(firebase.firestore.FieldPath.documentId(), 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      } else {
        return await this.firestore.collection(collectionName, ref => ref.where('category_name', 'array-contains', category).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.eligibility', 'in', allQualification).orderBy(firebase.firestore.FieldPath.documentId(), 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }
    } else {
      if ((status == 'attempted' || status == 'completed') && access_type != '' && category != '') {
        return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', (status == 'attempted') ? false : true).where('settings.access_type', '==', access_type).where('category_name', 'array-contains-any', [category])).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else if ((status == 'attempted' || status == 'completed') && access_type == '' && category == '') {
        return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', (status == 'completed') ? true : false)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }


      else if ((status == 'attempted' || status == 'completed') && access_type != '') {
        return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', (status == 'attempted') ? false : true).where('settings.access_type', '==', access_type)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else if ((status == 'attempted' || status == 'completed') && category != '') {
        return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', '==', (status == 'attempted') ? false : true).where('category_name', 'array-contains-any', [category])).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else if (status == 'new' && access_type != '' && category != '') {

        return await this.firestore.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('settings.access_type', '==', access_type).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('category_name', 'array-contains', category).where('settings.eligibility', 'in', allQualification)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }
      else if (status == 'new' && access_type == '' && category == '') {
        return await this.firestore.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.eligibility', 'in', allQualification)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else if (status == 'new' && access_type == '') {
        return await this.firestore.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('settings.access_type', '==', access_type).where('settings.eligibility', 'in', allQualification)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else if (status == 'new' && category == '') {
        return await this.firestore.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('status', '==', 'Published').where('settings.allowed_user', '==', 'Aspirant').where('category_name', 'array-contains', category).where('settings.eligibility', 'in', allQualification)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }

      else {
        return await this.firestore.collection(collectionName, ref => ref.where('userId', '==', this.firestore.doc('/users/' + uid).ref).where('quiz_attempt', '==', true).where('quiz_complete', 'in', [true, false])).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
      }
    }
  }


  async sendEmail(data) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    let result = await this._http.post(this.serviceUrl + "/sendEmployeeResultToHR", data, httpOptions).toPromise();
    return result;
  }

  async userStatus(collectionName, userId) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', userId)).valueChanges().pipe(first()).toPromise();

  }

}

