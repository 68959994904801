import { Component, OnInit } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '../header/header.component'


@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  isLoading: boolean = false;
  discipline_details_result;
  discipline_courses: any = [];
  discipline_name: string;
  discipline_list: any = [];
  config: any;
  totalRecords: number;
  totalLevel;
  secondClass;
  totalProspectLevel: any;

  constructor(private _profileService: ManageProfileService, private afAuth: AngularFireAuth, private _router: Router, private _route: ActivatedRoute, private _header: HeaderComponent) {
    this.isLoading = true;
    this._route.paramMap.subscribe(url => {
      this.discipline_name = url['params']['name'];
      this.disciplineDetails(this.discipline_name);
      this.coursesList(this.discipline_name);
    })
  }

  async ngOnInit() {
    this.isLoading = true;
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.totalRecords
    };

    this.discipline_list = await this._header.disciplineList();
  }

  disciplineDetails(name: string) {

    this.afAuth.onAuthStateChanged(async user => {
      if (user != null) {
        let result = await this._profileService.getDisciplineDetails('discipline', name);
        if (result.length > 0) {
          this.discipline_details_result = result[0];
          this.totalLevel = this.discipline_details_result?.growth_prospects.prospects_level.length - 1;
          this.totalProspectLevel = this.discipline_details_result?.growth_prospects.prospects_level.length;
          this.secondClass = 'stairs pr-3 mt-' + this.totalLevel;
        }
      }
    });
  }

  styleObject(index) {
    if (index == 0) {
      return { 'height': '40%' };
    } else if (index == 1) {
      return { 'height': '60%' }

    } else if (index == 2) {
      return { 'height': '75%' }

    } else {
      return { 'height': '90%' }
    }
  }

  classObject(index) {
    if (index == 0) {
      return 'bar-skyblue bar-postion';
    } else if (index == 1 || index == 2) {
      return 'bar-blue bar-postion';
    } else {
      return 'bar-pink bar-postion';
    }
  }

  growthClass(index) {
    if (this.totalProspectLevel.length == 4) {
      return 'stairs pr-3 mt-2';
    } else if (this.totalProspectLevel.length == 3) {
      return 'stairs pr-3 mt-3';
    } else {
      return 'stairs pr-3 mt-' + (5 - index);
    }
  }

  coursesList(name: string) {

    this.afAuth.onAuthStateChanged(async user => {
      if (user != null) {
        let result = await this._profileService.getDisciplineCourses('courses', name);
        if (result.length > 0) {
          this.discipline_courses = result;
          this.totalRecords = result.length;
        } else {
          this.discipline_courses = [];
        }
      }
      this.isLoading = false;
    });
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

}
