<div class="mt-3 mb-2 pl-5 pr-5 pt-4  border rounded" *ngIf="userRole == 'Aspirant';else employeeMsg">
    <h2 class="text-blue-900   font-bold  text-2xl text-center pb-4 ">Quiz Summary</h2>
    <div class="row align-items-center">
       

        <div class="col-md-12">
            <div class="single-chart" *ngIf="resultInPercent >= minimumPercentage">
                <img src="assets/img/icon-smily.png">
                <svg viewBox="0 0 36 36" class="circular-chart green">
                    <path class="circle-bg" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <path class="circle" attr.stroke-dasharray="{{resultInPercent}}, 100" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" />

                    <text x="18" y="23.35" class="percentage smily">{{resultInPercent | number:'1.2-2'}}%</text>
                </svg>
            </div>


            <div class="single-chart" *ngIf="resultInPercent < minimumPercentage">
                <img src="assets/img/icon-sad.png">
                <svg viewBox="0 0 36 36" class="circular-chart red">
                    <path class="circle-bg" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <path class="circle" attr.stroke-dasharray="{{resultInPercent}}, 100" d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831" />

                    <text x="18" y="23.35" class="percentage smily">{{resultInPercent | number:'1.2-2'}}%</text>
                </svg>
            </div>
        </div>

        <div class="col-md-12 text-center text-md-left">

            <div class="row pt-6 mt-3">
                <div class="col-6 mb-6  ">
                    <p class="text-sm text-muted mb-0 ">Attempted</p>
                    <p class="fw-bold">{{attempted}} Questions</p>
                </div>
                <div class="col-6 mb-6  ">
                    <p class="text-sm text-muted mb-0">Not Attempt</p>
                    <p class="fw-bold">{{notAttempted}} Questions</p>
                </div>
                <div class="col-6 mb-6  ">
                    <p class="text-sm text-muted mb-0">Total Questions</p>
                    <p class="fw-bold">{{totalQuizQuestions}}</p>
                </div>

             
                <div class="col-6 mb-6">
                    <p class="text-sm text-muted mb-1">Correct Answers</p>
                    <p class="fw-bold">{{correctAnswer}}</p>
                </div>
                <div class="col-6 mb-6  ">
                    <p class="text-sm text-muted mb-1">Wrong Answers</p>
                    <p class="fw-bold">{{wrongAnswer}}</p>
                </div>
                <div class="col-6 mb-6  ">
                    <p class="text-sm text-muted mb-1">Percantage</p>
                    <p class="fw-bold">{{resultInPercent}}%</p>
                </div>

            </div>
        </div>
        <div class="col-md-12">
            <div class="row pt-6  ">
                <!-- <div class="col text-right"><button class="px-2 my-3 py-2 uppercase btn-primary" type="button">
              Re-attempt
            </button></div> -->
            </div>
        </div>

    </div>

</div>

<ng-template #employeeMsg>
    <div class="mt-3 mb-2 pl-5 pr-5 pt-4  border rounded">
        <div class="row align-items-center">
            <div class="col-md-12" style="text-align:center; color: green;">
                <h2 class="text-blue-900   font-bold  text-2xl text-center pb-4 ">Thank You for Completing the Test!
                </h2>
            </div>
        </div>
    </div>
</ng-template>