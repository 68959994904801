import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import { ManageProfileService } from '../manage-profile.service';
import { HeaderComponent } from '../../header/header.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Educational } from '../../models/educational.model';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { Router } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-educational-details',
  templateUrl: './educational-details.component.html',
  styleUrls: ['./educational-details.component.css']
})

export class EducationalDetailsComponent implements OnInit {

  model: any;
  userInfo;
  education: FormGroup;
  inputEducations: object = {};
  isLoading: boolean = false;
  details;
  myTag;
  courses;
  universities;
  subjects;
  selectedIndex;
  icon = 'fa fa-plus';
  show: boolean = false;
  currentIndex;
  myClass = 'hide-class'
  filteredValues;
  qualifications: any[];
  currentlySelectedQualification;
  invalidMarks: string = '';
  invalidPercentage: string = '';
  currentSubjectIndex: Number;
  filteredQualifications = [];
  nonfilteredQualifications = [];
  uniqueExtrnalId: string;
  fireBaseListener;
  sortedData;
  allYears = [];
  showCourseField: boolean = true;
  submitted = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('element') element: ElementRef;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searchCourse = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.courses
        : this.courses.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  searchUniversity = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.universities
        : this.universities.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }


  searchSubjects = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.subjects
        : this.subjects.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }


  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private fb: FormBuilder, private afAuth: AngularFireAuth, private firestore: AngularFirestore) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo == null) {
      this.router.navigate(['home']);
    }
  }

  ngOnInit(): void {

    this.uniqueExtrnalId = 'education_' + uuid();
    this.education = new FormGroup({
      Education: new FormArray([
        this.initEducation(),
      ]),
      AllEducation: new FormArray([]),
      HighestQualification: new FormControl('')
    });

    let curYear = new Date().getFullYear() + 9;

    for (let i = 1990; i <= curYear; i++) {
      this.allYears.push(i);
    }

    this.getDetails();
    this.getCourses();
    this.getAllSubjects();
    // this.getAllQualification();
    this.getUniversity();
  }


  expandRow(i) {

    const control = this.education.get('Education')['controls'][i].get('highest_qualification').value;
    if (control == '10' || control == '12') {
      this.showCourseField = false;
    } else {
      this.showCourseField = true;
    }

    if (this.myClass == 'hide-class') {
      this.myClass = 'show-class';
      this.selectedIndex = null;
    } else {
      this.myClass = 'hide-class';
    }
    this.currentIndex = i;
    this.show = true;
  }

  cancel() {
    this.getDetails();
  }


  initEducation() {
    return new FormGroup({
      highest_qualification: new FormControl('', Validators.required),
      course: new FormControl(''),
      university: new FormControl(''),
      course_type: new FormControl('full'),
      year_of_passing: new FormControl(''),
      percentage: new FormControl(''),
      medium_of_study: new FormControl(''),
      status: new FormControl('', Validators.required),
      external_education_id: new FormControl(this.uniqueExtrnalId),
      subjects: new FormArray([
        this.initSubjects()
      ])
    });
  }


  initSubjects() {
    return new FormGroup({
      name: new FormControl(''),
      marks: new FormControl(''),
      external_id: new FormControl(this.uniqueExtrnalId),
      education_subject_id: new FormControl(uuid())
    });
  }


  async addEducation() {

    this.filterQualification();

    const control = <FormArray>this.education.get('Education');
    control.push(this.initEducation());
    let i = control.length - 1;

    this.selectedIndex = i;
    this.myClass = 'show-class';
    this.show = true;
    this.currentIndex = i;
  }

  addSubjects(j) {
    const control = <FormArray>this.education.get('Education')['controls'][j].get('subjects');
    control.push(this.initSubjects());
  }

  getEducations(form) {
    return form.controls.Education.controls;
  }

  getSubjects(form) {
    return form.controls.subjects.controls;
  }


  removeSubjects(i, j) {
    const control = <FormArray>this.education.get('Education')['controls'][i].get('subjects');
    control.removeAt(j);
  }

  async removeEducation(index, form) {

    let newItems = {};
    let allEducation = [];
    let education = [];
    let detailsId = this.details['id'];

    if (confirm('Are you sure want to delete this?')) {

      const control = <FormArray>this.education.get('Education');
      control.removeAt(index);

      let inputData = this.getEducations(form);

      if (inputData.length > 0) {
        inputData.forEach(data => {
          allEducation.push(data.value.highest_qualification);
          education.push(data.value)
        });

        newItems['Education'] = education;
        newItems['AllEducation'] = allEducation;
        newItems['HighestQualification'] = inputData[0].value.highest_qualification;
      } else {
        inputData = {}
      }

      let geteducationalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (geteducationalDetails != null) {
        geteducationalDetails['aspirant_personal'] = geteducationalDetails.aspirant_personal ? geteducationalDetails.aspirant_personal : {};
        geteducationalDetails['aspirant_education'] = newItems;
        geteducationalDetails['aspirant_skills'] = geteducationalDetails.aspirant_skills ? geteducationalDetails.aspirant_skills : {};
        geteducationalDetails['aspirant_fitness'] = geteducationalDetails.aspirant_fitness ? geteducationalDetails.aspirant_fitness : {};
        geteducationalDetails['aspirant_interests'] = geteducationalDetails.aspirant_interests ? geteducationalDetails.aspirant_interests : {};
        geteducationalDetails['aspirant_social_profile'] = geteducationalDetails.aspirant_social_profile ? geteducationalDetails.aspirant_social_profile : {};
        geteducationalDetails['aspirant_certifications'] = geteducationalDetails.aspirant_certifications ? geteducationalDetails.aspirant_certifications : {};
        geteducationalDetails['aspirant_language'] = geteducationalDetails.aspirant_language ? geteducationalDetails.aspirant_language : {};
      }

      let result = await this._profileService.updateEducationalDetails('aspirants', detailsId, geteducationalDetails);

      delete geteducationalDetails['userId'];
      delete geteducationalDetails['created_by'];
      delete geteducationalDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(geteducationalDetails));
      //this._header.suggestedCoursesOnFieldsUpdation();  //update course suggestion on fields update//
      //this._header.suggestedExamsOnFieldsUpdation(); //update exams suggestion on fields update//
      this.ngOnInit();
      this.show = false;
    }
  }

  setModel(e, i) {
    this.filteredValues = this.displayFn(e);
  };

  displayFn(name: string) {
    return this.courses.filter(state =>
      state.display_name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  async addDetails(form) {
    this.isLoading = true;
    this.submitted = true;

    if (form.valid) {

      let inputData: Educational = form.value;
      let geteducationalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      inputData.Education.forEach(data => {
        inputData.AllEducation.push(data.highest_qualification);
        inputData.HighestQualification = data.highest_qualification;
      });



      if (geteducationalDetails != null) {

        geteducationalDetails['aspirant_personal'] = geteducationalDetails.aspirant_personal ? geteducationalDetails.aspirant_personal : {};
        geteducationalDetails['aspirant_education'] = inputData;
        geteducationalDetails['aspirant_skills'] = geteducationalDetails.aspirant_skills ? geteducationalDetails.aspirant_skills : {};
        geteducationalDetails['aspirant_fitness'] = geteducationalDetails.aspirant_fitness ? geteducationalDetails.aspirant_fitness : {};
        geteducationalDetails['aspirant_interests'] = geteducationalDetails.aspirant_interests ? geteducationalDetails.aspirant_interests : {};
        geteducationalDetails['aspirant_social_profile'] = geteducationalDetails.aspirant_social_profile ? geteducationalDetails.aspirant_social_profile : {};
        geteducationalDetails['aspirant_certifications'] = geteducationalDetails.aspirant_certifications ? geteducationalDetails.aspirant_certifications : {};
        geteducationalDetails['aspirant_language'] = geteducationalDetails.aspirant_language ? geteducationalDetails.aspirant_language : {};

      } else {

        geteducationalDetails = {};
        geteducationalDetails['aspirant_personal'] = {};
        geteducationalDetails['aspirant_education'] = inputData;
        geteducationalDetails['aspirant_skills'] = {};
        geteducationalDetails['aspirant_fitness'] = {};
        geteducationalDetails['aspirant_interests'] = {};
        geteducationalDetails['aspirant_social_profile'] = {};
        geteducationalDetails['aspirant_certifications'] = {};
        geteducationalDetails['aspirant_language'] = {};


      }


      geteducationalDetails['created_by'] = this.userInfo['uid'];
      geteducationalDetails['modified_by'] = this.userInfo['uid'];
      geteducationalDetails['created_date_time'] = new Date();
      geteducationalDetails['modified_date_time'] = new Date();
      geteducationalDetails['operation'] = 'created';
      geteducationalDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      let result = await this._profileService.addEducationalDetails('aspirants', geteducationalDetails);
      let data = await this._header.getDetails();
      if (data != undefined) {
        this.submitted = false;
        this.getDetails();
      }

      //this._header.suggestedCoursesOnFieldsUpdation();  //save course suggestion in session storage 
      //this._header.suggestedExamsOnFieldsUpdation(); //update exams suggestion on fields update//
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }



  async getDetails() {
    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_education != undefined && Object.keys(result.aspirant_education).length != 0) {
          this.details = result.aspirant_education;
          this.details['id'] = result.id;
          this.education.patchValue({ HighestQualification: this.details.HighestQualification })
          this.sortedData = _.orderBy(this.details.Education, [(datas) => datas.year_of_passing], ["desc"]);
          this.education.setControl('Education', this.setExistingEducation(this.sortedData))
          this.show = false;
          this.myClass = 'hide-class';
        } else {
          this.selectedIndex = 0;
          this.details = [];
          this.details['id'] = (result == null) ? '' : result.id;
          this.show = true;
          this.myClass = 'hide-class';
        }
        this.getAllQualification();
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });
  }



  setExistingEducation(educationSet) {

    let formarray = new FormArray([]);
    let subjectarray = new FormArray([]);

    educationSet.forEach(s => {
      subjectarray = new FormArray([])
      s.subjects.forEach(e => {
        subjectarray.push(this.fb.group({
          name: e.name,
          marks: e.marks,
          external_id: e.external_id,
          education_subject_id: e.education_subject_id
        }))
      });

      formarray.push(this.fb.group({
        highest_qualification: s.highest_qualification,
        course: s.course,
        university: s.university,
        course_type: s.course_type,
        year_of_passing: s.year_of_passing,
        percentage: s.percentage,
        medium_of_study: s.medium_of_study,
        status: s.status,
        external_education_id: s.external_education_id,
        subjects: subjectarray
      }))
    })

    return formarray;
  }



  async updateDetails(form) {

    this.isLoading = true;
    this.submitted = true;

    if (form.valid) {

      let currentHighestQualification;
      let detailsId = this.details['id'];
      let inputData: Educational = form.value;

      inputData.Education.forEach(data => {
        inputData.AllEducation.push(data.highest_qualification);
      });


      let total = inputData.Education.length - 1;
      let lastEnteredQualification = inputData.Education[total].highest_qualification;

      if (inputData.HighestQualification != '') {
        currentHighestQualification = inputData.HighestQualification;
      } else {
        currentHighestQualification = lastEnteredQualification;
      }

      let updatedResult = await this.updateHighestQualification(currentHighestQualification);

      if (updatedResult == true) {
        inputData.HighestQualification = lastEnteredQualification;
      }


      let geteducationalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (geteducationalDetails != null) {

        geteducationalDetails['aspirant_personal'] = geteducationalDetails.aspirant_personal ? geteducationalDetails.aspirant_personal : {};
        geteducationalDetails['aspirant_education'] = inputData;
        geteducationalDetails['aspirant_skills'] = geteducationalDetails.aspirant_skills ? geteducationalDetails.aspirant_skills : {};
        geteducationalDetails['aspirant_fitness'] = geteducationalDetails.aspirant_fitness ? geteducationalDetails.aspirant_fitness : {};
        geteducationalDetails['aspirant_interests'] = geteducationalDetails.aspirant_interests ? geteducationalDetails.aspirant_interests : {};
        geteducationalDetails['aspirant_social_profile'] = geteducationalDetails.aspirant_social_profile ? geteducationalDetails.aspirant_social_profile : {};
        geteducationalDetails['aspirant_certifications'] = geteducationalDetails.aspirant_certifications ? geteducationalDetails.aspirant_certifications : {};
        geteducationalDetails['aspirant_language'] = geteducationalDetails.aspirant_language ? geteducationalDetails.aspirant_language : {};


        geteducationalDetails['created_by'] = this.userInfo['uid'];
        geteducationalDetails['modified_by'] = this.userInfo['uid'];
        geteducationalDetails['created_date_time'] = new Date();
        geteducationalDetails['modified_date_time'] = new Date();
        geteducationalDetails['operation'] = 'created';
        geteducationalDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

        let result = await this._profileService.updateEducationalDetails('aspirants', detailsId, geteducationalDetails);

        delete geteducationalDetails['userId'];
        delete geteducationalDetails['created_by'];
        delete geteducationalDetails['modified_by'];

        sessionStorage.setItem('aspirantInfo', JSON.stringify(geteducationalDetails));
        this.getDetails();
        this.ngOnInit();
        this.submitted = false;
        //this._header.suggestedCoursesOnFieldsUpdation();  //update course suggestion on fields update//
        //this._header.suggestedExamsOnFieldsUpdation(); //update exams suggestion on fields update//
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  async getCourses() {
    let result = await this._profileService.getCoursesList('courses');
    if (result.length > 0) {
      this.courses = result;
    } else {
      this.courses = [];
    }

  }

  async getAllSubjects() {
    let result = await this._profileService.getSubjectList('subjects');
    if (result.length > 0) {
      this.subjects = result[0]['name'];
    } else {
      this.subjects = [];
    }

  }

  async getAllQualification() {
    let listQualification = await this._profileService.getQualificationList('educational_qualification');

    listQualification = _.orderBy(listQualification, ['rank'], ['desc'])

    const filteredQualification = listQualification.filter((item) => item['name'] != 'All');
    this.filteredQualifications = filteredQualification;
    this.nonfilteredQualifications = filteredQualification;
  }


  async getUniversity() {
    let result = await this._profileService.getUniversityList('universities');
    if (result.length > 0) {
      this.universities = result[0]['name'];
    } else {
      this.universities = [];
    }
  }

  filterQualification() {
    //show only not filled up education//
    if (this.details != undefined && this.details['AllEducation'] != undefined && this.details['id'] != '') {
      let allQualification = this.details['AllEducation'];
      this.filteredQualifications = this.filteredQualifications.filter(function (item) {
        return allQualification.indexOf(item['name']) === -1;
      });

    }
  }


  async currentRank(event) {
    if (event.value == '10' || event.value == '12') {
      this.showCourseField = false;
    } else {
      this.showCourseField = true;
    }

    if (event.selectedOptions[0].attributes.myattr.nodeValue != undefined) {
      this.currentlySelectedQualification = event.selectedOptions[0].attributes.myattr.nodeValue;
    }
  }



  async updateHighestQualification(currentHighQualification) {
    let existQualification = this.nonfilteredQualifications.filter(function (el) {
      return el.name == currentHighQualification;
    });

    if (this.currentlySelectedQualification != undefined && existQualification.length > 0) {
      if (existQualification[0].rank < this.currentlySelectedQualification) {
        return false;
      } else {
        return true;
      }
    }
  }

  numberOnly(event, type: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (type == 'percentage') {
      if (charCode > 47 && charCode < 58 || charCode == 46) {
        return true;
      } else {
        return false;
      }
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      } else {
        return true;
      }
    }
  }

  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  validValue(value, type, index) {

    if (type == 'percentage') {
      if (value.indexOf('.') != -1) {
        if (value.substring(value.indexOf('.')).length == 3) {
          this.invalidPercentage = '';
        } else {
          this.invalidPercentage = 'Invalid percentage!';
        }
      } else {
        if (value > 100) {
          this.invalidPercentage = 'Invalid percentage!';
        } else {
          this.invalidPercentage = '';
        }
      }
    }


    if (type == 'marks') {
      if (value > 100) {
        this.invalidMarks = 'Invalid marks!';
        this.currentSubjectIndex = index;
      } else {
        this.invalidMarks = '';
        this.currentSubjectIndex;
      }
    }
  }


  resetForm() {
    const control = <FormArray>this.education.get('Education')['controls'][this.currentIndex];
    control.reset();
  }

  async delay(t) {
    return new Promise(resolve => setTimeout(() => {
      resolve()
    }, t));
  }
}
