<div class="container">
  <div class="row">
    <div class="col-12">
    <div class="bg-blue-dark text-white rounded  ">
      <div class="row align-items-center p-4 ">
        <div class="col">
          <!-- Breadcrumb -->
          <div class="page-heading row pb-0">
            <div class="heading col-sm-12 ">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item "><a routerLink="/dashboard" class="text-white"> Dashboard /
                      {{ quiz_details['quiz_name'] }}</a></li>
                </ol>
              </nav>
              <h2>{{ quiz_details['quiz_name'] }} </h2>
            </div>
          </div>
        </div>
        <div class="col text-right"><a routerLink="/my-learning" class="  link-white"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
            </svg> BACK TO MY LEARNING</a></div>
      </div>

    </div>
    </div>
    <div class="col-md-8 ">
    
      <div class="result-list-holder mt-3">

    
        <ol class="my-2 pl-5 pr-5 pt-4  border rounded ">
          <li *ngFor="let q of quiz_details['details'];let r = index">
            <label [innerHTML]="q.question"  > </label>
            <ng-container *ngIf="q.question_type == 'MCQ';else otherOption">
              <div class="form-check p-2 pl-4 mb-2 rounded" *ngFor="let opt of q.options;let p=index"
                [ngClass]="{'wrong-color': (q.GivenAnswer == opt.option_value) || (q.answer == opt.option_value), 'correct-color':((q.answer === opt.option_value) && opt.answer == 1) }">
                <input type="radio" id="{{p}}" class="form-check-input with-gap" disabled>
                <label class="form-check-label" for="{{p}}">{{ opt.option_value }} </label>
              </div>
            </ng-container>
            <ng-template #otherOption>
              <div [ngClass]="{'wrong-color': q.answer != true && q.Attempt == true, 'correct-color':q.answer == true }">
                <input type="radio" id="{{r}}" class="form-check-input with-gap" name="groupOfRadioGap" disabled>
                <label class="form-check-label" for="{{r}}">True</label><br /><br />
              </div> <br />
              <div [ngClass]="{'wrong-color': q.answer != false  && q.Attempt == true, 'correct-color': q.answer == false}">
                <input type="radio" id="{{r}}" class="form-check-input with-gap" name="groupOfRadioGap" disabled>
                <label class="form-check-label" for="{{r}}">False</label><br /><br />
              </div>
            </ng-template>
            <div class="row mt-3" >
              <div class="col-md-6"><label class="mb-0"><b>Correct Answer </b></label><p>{{ q.OriginalAnswer?q.OriginalAnswer:q.answer }}</p></div>
              <div class="col-md-6"><label class="mb-0"><b>Your Answer </b></label><p>{{ q.GivenAnswer?q.GivenAnswer:'Not Attempted' }}</p></div>
              <div class="col-md-12"> <label class="mb-0"><b>Answer Description</b></label>
                <p [innerHTML]="q.answer_description"></p>
              </div>
            </div>
             
            
            


            <p *ngIf="q?.answer_doc_url">
              <label *ngIf="(q?.answer_doc_url | json) != '{}'"><a href="{{q?.answer_doc_url.url}}" target="_blank">See
                  the Explanation</a> </label>
            </p>

            <p *ngIf="q?.url">
              <label *ngIf="q?.url != ''"><a href="{{q?.url}}" target="_blank">Uploaded Answer</a> </label>
            </p>
          </li>
       
        </ol>
        <div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>
      </div>

    </div>

    <div class="col-md-4">
      <app-result-description [correctAnswer]="correctAnswer" [wrongAnswer]="wrongAnswer" [attempted]="attempted" [resultInPercent]="resultInPercent" [notAttempted]="notAttempted" [totalQuizQuestions]="totalQuizQuestions"  [quizName]="quiz_details['quiz_name']" [userRole]="userDetails?.role"></app-result-description>

    </div>

  </div>
</div>
