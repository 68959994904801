import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { ActivatedRoute } from '@angular/router';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { SharedComponent } from '../shared/shared.component';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from "@angular/router"


@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.css']
})
export class OrderReviewComponent implements OnInit {

  payableAmount: number;
  isLoading: boolean = false;
  paymentType: string;
  show: boolean = false;
  userDetails: any;
  userInfo: any;
  aspirantAddress: any;
  quiz_details: any;
  states: any;
  aspirantDetails: any;
  billingAddress: any;
  id: any;
  showMsg: string = '';
  paymentCheck: boolean;


  constructor(private _header: HeaderComponent, private _route: ActivatedRoute, private _profileService: ManageProfileService, private _shared: SharedComponent, private firestore: AngularFirestore, private _router: Router) { }

  async ngOnInit() {
    this.isLoading = true;
    this.paymentType = this._route.snapshot.paramMap.get('name');
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (this.paymentType != 'counselling') {
      this.paymentCheck = await this.checkAmount('paid_quizzes', this.userInfo['uid'], this.paymentType);
      if (this.paymentCheck == true) {
        this._router.navigate(['/attempt', this.paymentType])
      }
      let quiz_result = await this._profileService.getQuizById('quizzes', this.paymentType);
      if (quiz_result.length > 0) {
        this.quiz_details = quiz_result[0];
        this.payableAmount = this.quiz_details['settings']['amount'];
      }
    } else {
      this.paymentCheck = await this.checkAmount('payments', this.userInfo['uid'], '');
      if (this.paymentCheck == true) {
        this._router.navigate(['/dashboard'])
      }
      this.payableAmount = await this._header.getCounsellingAmount();
    }

    this.isLoading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    this.states = this._shared.stateList();
    this.getAddress();
  }

  showForm() {
    this.show = true;
    this.showMsg = '';
  }

  async getAddress() {
    let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
    if (result != null && result['aspirant_personal'] != undefined && Object.keys(result['aspirant_personal']).length != 0) {
      this.aspirantDetails = result['aspirant_personal'];
      this.id = result.id;
      this.aspirantAddress = result['aspirant_personal']['current_address'];
      this.billingAddress = result['aspirant_personal']['billing_address'] ? result['aspirant_personal']['billing_address'] : {};
    } else {
      this.id = '';
      this.aspirantAddress = {};
      this.billingAddress = {};
    }
    this.isLoading = false;
  }

  cancelAddress() {
    this.show = false;
    this.showMsg = '';
  }

  async saveBillingAddress(form: NgForm) {
    if (form.valid) {
      this.isLoading = true;
      let billing_address = form.value;

      this.aspirantDetails['billing_address'] = billing_address;
      let getPersonalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getPersonalDetails != null) {
        getPersonalDetails['aspirant_personal'] = this.aspirantDetails;
        getPersonalDetails['aspirant_education'] = getPersonalDetails.aspirant_education ? getPersonalDetails.aspirant_education : {};
        getPersonalDetails['aspirant_skills'] = getPersonalDetails.aspirant_skills ? getPersonalDetails.aspirant_skills : {};
        getPersonalDetails['aspirant_fitness'] = getPersonalDetails.aspirant_fitness ? getPersonalDetails.aspirant_fitness : {};
        getPersonalDetails['aspirant_interests'] = getPersonalDetails.aspirant_interests ? getPersonalDetails.aspirant_interests : {};
        getPersonalDetails['aspirant_social_profile'] = getPersonalDetails.aspirant_social_profile ? getPersonalDetails.aspirant_social_profile : {};
        getPersonalDetails['aspirant_certifications'] = getPersonalDetails.aspirant_certifications ? getPersonalDetails.aspirant_certifications : {};
        getPersonalDetails['aspirant_language'] = getPersonalDetails.aspirant_language ? getPersonalDetails.aspirant_language : {};
      } else {
        getPersonalDetails = {};
        getPersonalDetails['aspirant_personal'] = this.aspirantDetails;
        getPersonalDetails['aspirant_education'] = {};
        getPersonalDetails['aspirant_skills'] = {};
        getPersonalDetails['aspirant_fitness'] = {};
        getPersonalDetails['aspirant_interests'] = {};
        getPersonalDetails['aspirant_social_profile'] = {};
        getPersonalDetails['aspirant_certifications'] = {};
        getPersonalDetails['aspirant_language'] = {};
      }

      getPersonalDetails['created_by'] = this.userInfo['uid'];
      getPersonalDetails['modified_by'] = this.userInfo['uid'];
      getPersonalDetails['created_date_time'] = new Date();
      getPersonalDetails['modified_date_time'] = new Date();
      getPersonalDetails['operation'] = 'created';
      getPersonalDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      if (this.id != '' || this.id != undefined) {
        let result = await this._profileService.updatePersonalDetails('aspirants', this.id, getPersonalDetails);
      } else {
        let result = await this._profileService.addPersonalDetails('aspirants', getPersonalDetails);
      }

      delete getPersonalDetails['userId'];
      delete getPersonalDetails['created_by'];
      delete getPersonalDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getPersonalDetails));
      this.show = false;
    }
    this.isLoading = false;
  }


  async checkAmount(collectionName, userId, quizId) {
    try {
      let paymentExist = await this._profileService.getPaymentStatus(collectionName, userId, quizId);
      if (paymentExist.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.ngOnInit();
    }
  }

  applyCoupon(value) {
    this.showMsg = 'Please enter valid coupon code!';
  }
}
