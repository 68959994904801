import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageProfileService } from '../manage-profile/manage-profile.service';

declare var $: any;
declare var Razorpay: any;

@Component({
  selector: 'app-attempt',
  templateUrl: './attempt.component.html',
  styleUrls: ['./attempt.component.css']
})

export class AttemptComponent implements OnInit {
  quiz_id: string;
  quiz_details = {};
  isLoading: boolean = true;
  show_quiz: boolean = false;
  userInfo: any;
  showResult: boolean = false;
  showContinue: boolean = false;
  buy: boolean = false;

  uniqueExtrnalId: string;
  courseConfig: any;
  examConfig: any;
  totalCourseRecords: number = 0;
  totalExamRecords: number = 0;
  filtered_suggested_exams = [];
  selectedIndex;
  wait: boolean = false;
  name: string;
  payableAmount: number;
  paymentSuccess: boolean = false;
  paymentFailed: boolean = false;
  detailsToSavedInDB = {};
  paidQuizDetails = {};
  amount = '';
  userDetails: any;


  constructor(private route: ActivatedRoute, private _profileService: ManageProfileService) { this.isLoading = true; }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    this.quiz_id = this.route.snapshot.paramMap.get('id');
    this.getQuizDetails(this.quiz_id);

    //while adedd in details
    this._profileService.refreshPaymentNeeded$.subscribe(() => {
      this.getQuizDetails(this.quiz_id);
    })
  }

  async getQuizDetails(quizid) {
    try {
      let exist_quiz = await this._profileService.getQuizFromResult('quiz_result', this.quiz_id, this.userInfo.uid);
      if (exist_quiz.length > 0) {
        this.quiz_details = exist_quiz[0];
        if (this.quiz_details['quiz_complete'] == true) {
          this.showResult = true;
          this.quiz_id = this.quiz_details['id'];
        } else {
          this.showContinue = true;
        }
        this.isLoading = false;
      } else {
        let quiz_result = await this._profileService.getQuizById('quizzes', quizid);
        if (quiz_result.length > 0) {
          this.quiz_details = quiz_result[0];
          this.amount = this.quiz_details['settings']['amount'];
          if (this.quiz_details['settings']['access_type'] == 'Paid') {
            let paid_quiz = await this._profileService.checkPaidQuizzes('paid_quizzes', this.quiz_id, this.userInfo.uid);
            if (paid_quiz.length > 0) {
              this.buy = false;
              this.showContinue = false;
              this.showResult = false;
            } else {
              this.buy = true;
            }
          }
        }
        this.isLoading = false;
      }

    } catch (errorResponse) {
      this.ngOnInit();
    }
  }



  async startQuiz() {
    this.show_quiz = true;
  }


  // async Checkout(amount: number) {

  //   var name = this.userDetails['first_name']+' '+this.userDetails['last_name'];
  //   var email = this.userInfo['email'];

  //   let details = {
  //     "name": name,
  //     "email": email
  //   };

  //   let orderResponse = await this.CreateOrder(amount);

  //   if ((orderResponse.status == 'Success' || orderResponse.Status == 'Success') && (orderResponse.message == 'Order created' || orderResponse.Data != '')) {
  //     var orderid = (orderResponse.data == undefined) ? orderResponse.Data : orderResponse.data.id;
  //     var ref = this;

  //     //payment paramter to pass in razor pay object 
  //     const options = {
  //       key: environment.razorPayApiKeyId,
  //       order_id: orderid,
  //       name: "Open Naukri Quiz",
  //       description: "Payment For Open Naukri Quiz",
  //       handler: function (response: object) {
  //         ref.isLoading = true;
  //         ref.VerifyPayment(response, amount);
  //       },
  //       prefill: details,
  //       theme: {
  //         "color": "#0478a7"
  //       }
  //     };

  //     //Open Gateway
  //     const rzp = await new Razorpay(options);
  //     rzp.open();
  //   } else {
  //     this.paymentFailed = true;
  //     this.isLoading = false;
  //   }
  // }



  // async CreateOrder(orderAmount) {
  //   this.isLoading = true;
  //   let order_request = {
  //     amount: orderAmount * 100,
  //     currency: "INR",
  //   }

  //   try {
  //     let order_id = await this._profileService.createOrder(order_request);
  //     return order_id;
  //   } catch (errorResponse) {
  //     this.isLoading = false;
  //     return errorResponse.error['Error'];
  //   }
  // }

  // async VerifyPayment(checkout_response: object, amount) {
  //   try {

  //     checkout_response['amount'] = amount * 100;

  //     let paymentResponse = await this._profileService.verifyPayment(checkout_response);

  //     if (paymentResponse['status'] == 'Success' || paymentResponse['Status'] == 'Success') {

  //       if (paymentResponse['data'].hasOwnProperty('id')) {

  //         //save details in db
  //         this.paymentSuccess = true;
  //         let parseAmount = Math.round(paymentResponse['data']['amount']) / 100;
  //         this.detailsToSavedInDB = {
  //           payment_id: paymentResponse['data']['id'],
  //           order_id: paymentResponse['data']['order_id'],
  //           captured: true,
  //           payment_date: paymentResponse['data']['created_at'],
  //           amount: parseAmount.toFixed(2),
  //           status: 'paid',
  //           currency: paymentResponse['data']['currency'],
  //           paid_for: 'quiz',
  //           userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
  //           created_at: new Date(),
  //           updated_at: new Date()
  //         }

  //         this.paidQuizDetails = {
  //           amount: parseAmount.toFixed(2),
  //           quiz_id: this.quiz_id,
  //           userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
  //           created_at: new Date(),
  //           updated_at: new Date()
  //         }

  //       } else {

  //         this.paymentFailed = true;
  //         this.detailsToSavedInDB = {
  //           payment_id: checkout_response['razorpay_payment_id'],
  //           order_id: checkout_response['razorpay_order_id'],
  //           captured: false,
  //           payment_date: new Date(),
  //           amount: (amount * 100).toFixed(2),
  //           status: 'failed',
  //           currency: 'INR',
  //           paid_for: 'quiz',
  //           userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
  //           created_at: new Date(),
  //           updated_at: new Date()
  //         }
  //       }

  //     } else {
  //       this.paymentFailed = true;
  //       this.detailsToSavedInDB = {
  //         payment_id: checkout_response['razorpay_payment_id'],
  //         order_id: checkout_response['razorpay_order_id'],
  //         captured: false,
  //         payment_date: new Date(),
  //         amount: (amount * 100).toFixed(2),
  //         status: 'failed',
  //         currency: 'INR',
  //         paid_for: 'quiz',
  //         userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
  //         created_at: new Date(),
  //         updated_at: new Date()
  //       }
  //     }

  //     let paymentDBResult = await this._profileService.addPaymentDetails('payments', this.detailsToSavedInDB);
  //     if (Object.keys(this.paidQuizDetails).length > 0) {
  //       let paidQuizResult = await this._profileService.addPaymentDetails('paid_quizzes', this.paidQuizDetails);
  //       this.showContinue = false;
  //       this.showResult = false;
  //       this.buy = false;
  //     }

  //     this.isLoading = false;
  //   } catch (errorResponse) {
  //     console.log('errorResponse :',errorResponse);
  //     this.paymentFailed = true;
  //     this.isLoading = false;
  //   }
  // }

}
