<!-- Body -->

<div class="page-heading row">
    <div class="heading col-lg-8 col-md-6 col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="/dashboard">{{'dashboard_label' | translate }}</a></li>
            </ol>
        </nav>
        <h2>{{'dashboard_label' | translate }}</h2>
    </div>

</div>


<!-- Success / Failed message start here-->

<app-payment #payment> </app-payment>

<!-- Success / Failed message end here-->


<div class="row">
    <!-- Profile -->
    <app-sidebar class="col-md-12 col-lg-3"></app-sidebar>
    <div class="col-md-12 col-lg-9">

        <div class="rounded border  mb-3 shadow-sm ">
            <div class="row ">
                <div class="col-md-8">
                    <h4 class="text-dark m-4"><span class="font-weight-light text-black-50">Welcome</span>
                        {{userDetails?.first_name }} {{ userDetails?.last_name }}
                        <span class=" badge-pill badge-light-orange text-black-50 pt-1 pb-1 pr-3 pl-3"
                            *ngIf="userDetails?.role == 'Aspirant';else employeeType">Student</span>
                        <ng-template #employeeType>
                            <span
                                class=" badge-pill badge-light-orange text-black-50 pt-1 pb-1 pr-3 pl-3">Employee</span>
                        </ng-template>
                    </h4>
                </div>

                <div class="col-md-4 text-right">
                    <div class="m-3"><button type="submit" class="btn btn-primary  m-0"
                            routerLink="/manage-profile/personal">UPDATE PROFILE</button>
                    </div>
                </div>
            </div>


            <div class="row bg-light-blue m-0 ">
                <div class="col-md-10">
                    <p class="pt-4 pb-4 pl-2 pr-2 m-0">OpenNaukri is a career counselling platform. We have expert
                        having excellent
                        knoweldge in
                        respective field. Who helps students for better career opportunities in different fields.</p>
                </div>
                <div class="col-md-2 text-right text-md-center">
                    <p class="p-md-4 pr-4 pt-0 pb-4  m-0"><img src="assets/img/icon-counselling.png"></p>
                </div>
            </div>

        </div>


        <div class="row" *ngIf="userDetails?.role == 'Aspirant'; else employeeQuiz">
            <div class="col-lg-4">
                <div class="card rounded border mb-3 shadow-sm">
                    <div class="card-body p-0 quiz-box">

                        <div class="row">
                            <div class="col-12">
                                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                                    <div class="heading  row">
                                        <div class="col">
                                            <h4 class="mb-0 px-3 pt-3 pb-3 border-bottom">Quizzes</h4>
                                        </div>
                                    </div>
                                    <div class="carousel-inner">
                                        <div [ngClass]="(i==0)?'carousel-item active':'carousel-item'"
                                            *ngFor="let list of pendingQuizzes;let i=index">
                                            <div class="quiz-mid-row bg-white">
                                                <h5 class="mb-2 w-100 text-truncate d-inline-block">
                                                    {{list.name?list.name:list.quiz_name}}</h5>
                                                <div class="d-block d-md-flex" style="height: 30px;">
                                                    <div class="col-md-6 p-0 "> <span
                                                            [ngClass]="{'badge bg-info text-dark  text-truncate d-inline-block wm-100': list?.category_name.length > 0, '': list?.category_name.length == 0}">{{list?.category_name.join(', ')}}</span>
                                                    </div>
                                                    <span class=" col-6 text-right pull-right small-text"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                            fill="currentColor" class="bi bi-clock mr-2"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                            <path
                                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                        </svg>{{list.duration?list.duration.minutes:list.settings.duration.minutes}}
                                                        min</span>
                                                </div>
                                                <div class="d-block d-md-flex mt-1  ">
                                                    <div class="col-md-8 p-0 free-text">
                                                        {{ list.settings?list.settings.access_type:list.access_type }}
                                                    </div>
                                                    <div class="col-md-4 p-0 attemptbtn">
                                                        <a href="#" *ngIf="!list.quiz_attempt"
                                                            routerLink="/attempt/{{list?.id}}">Attempt</a>
                                                        <a href="#"
                                                            *ngIf="list.quiz_attempt && list.quiz_attempt == true && list.quiz_complete == true"
                                                            routerLink="/result/{{list?.id}}">Result</a>
                                                        <a href="#"
                                                            *ngIf="list.quiz_attempt && list.quiz_attempt == true && list.quiz_complete == false"
                                                            routerLink="/attempt/{{list?.quiz_id}}">Continue</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-right mr-3 slidecontainer my-0" *ngIf="pendingQuizzes.length > 0">
                                <a class="mr-1 px-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                                    <i class="fa fa-angle-left "></i>
                                </a>
                                <a class="mb-3 px-1" href="#carouselExampleIndicators2" role="button" data-slide="next">
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8" *ngIf="userDetails?.role == 'Aspirant'">
                <div class="card rounded border mb-3 shadow-sm">
                    <div class="card-body p-0">
                        <ul class="list-unstyled mb-0">
                            <li class="media  d-block d-md-flex ">
                                <img src="assets/img/img-counselling.png" class="m-3">
                                <div class="media-body pr-3  pl-3 pt-md-3 pb-md-3 ">
                                    <h5 class="mt-0 mb-2 text-orange">Career Counselling </h5>
                                    Talk with our expert and get right career guidance for your careers.
                                    <div class="row pt-3">
                                        <div class="col-md-6">
                                            <p class="font-weight-bold" *ngIf="!paymentCheck">INR
                                                {{ payableAmount | number : '1.2-2'}}</p>
                                        </div>
                                        <div class="col-md-6 text-right mt-5 pt-1">
                                            <div class="mr-md-4" *ngIf="!paymentCheck;else Others">
                                                <a href="#" class="btn btn-orange"
                                                    routerLink="/order-review/counselling">PAY
                                                    NOW</a>
                                            </div>
                                            <ng-template #Others>
                                                <div class="mr-md-4"><a href="javascript:void(0)"
                                                        class="btn btn-orange">Subscribed</a></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Include Html For Employee Quiz Start -->
        <ng-template #employeeQuiz>
            <app-employee-quiz [employeeQuizzes]="pendingQuizzes"></app-employee-quiz>
        </ng-template>
        <!-- Include Html For Employee Quiz End -->
    </div>
</div>


<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>
<!-- Modal -->

<div class="modal fade" id="IntroModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-heading">
                    <h2>{{'welcome_label' | translate}} <span
                            class="orange-text">{{'title1_label' | translate }}</span><span
                            class="blue-text">{{'title2_label' | translate}}</span></h2>
                    <p> {{'details_label' | translate  }}</p>
                    <h3 class="mt-4 mb-4">{{'tellus_label' | translate }}</h3>
                </div>


                <form id="msform" #allDetails="ngForm" (ngSubmit)="addDetails(allDetails)" ngNativeValidate>
                    <!-- fieldsets -->
                    <fieldset>
                        <label>Highest Qualification</label>
                        <div class="form-group">
                            <select class="form-control" name="highest_qualification" ngModel>
                                <option value="">Select Education</option>
                                <option *ngFor="let item of qualifications" attr.myAttr="{{item.rank}}"
                                    [ngValue]="item.name">
                                    {{ item.display_name }}
                                </option>
                            </select>
                        </div>
                        <input type="button" name="next" class="btn btn-primary next mr-0 " value="Next" />
                    </fieldset>
                    <fieldset>
                        <label>Interested Career</label>
                        <div class="form-group">
                            <input type="text" class="form-control" name="interested_carrer" ngModel
                                [ngbTypeahead]="searchInterest" #instance="ngbTypeahead" />
                        </div>
                        <input type="button" name="previous" class="previous btn btn-secondary-outline"
                            value="Previous" />
                        <input type="button" name="next" class="next btn btn-primary mr-0" value="Next" />
                    </fieldset>
                    <fieldset>
                        <label>Skill</label>
                        <div class="form-group">
                            <input type="text" class="form-control" name="skills" ngModel [ngbTypeahead]="searchSkills"
                                #instance="ngbTypeahead" />
                        </div>
                        <!-- <p *ngIf="msg==true">Please fill any value to submit!</p> -->
                        <input type="button" name="previous" class="previous btn btn-secondary-outline"
                            value="Previous" />
                        <!-- <input type="submit" class="submit btn btn-primary" value="Submit"  /> -->
                        <button class="submit btn btn-primary" type="submit">Submit</button>

                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>