<div>
    <div class="container-fuild cs-quizpage">
        <div class="container">
            <div class="row cs-topcontainer">
                <div class="col-md-4"><img src="assets/img/logo.png"></div>
                <div class="col-md-4 text-center">
                    <div class="quiztime" *ngIf="this.timeLeftinMinute != 0">{{timeLeftinMinute}}m :
                        {{timeLeftinSeconds}}s
                        <span>
                        </span> </div>

                    <div *ngIf="pausableAllowed == true && quizComplete == false">
                        <button class="btn btn-info" (click)="pauseTimer()" *ngIf="showPause == true">Pause</button>
                        <button class="btn btn-info" (click)="startTimer()" *ngIf="showStart == true">Start</button>
                    </div>
                    <p style="color: red;" *ngIf="quit_quiz == true">*Keyboard activity is prohibited!</p>
                    <p style="color: red;" *ngIf="quit_quiz == true">*Pressing ESC button will close & complete your
                        quiz!</p>
                </div>


                <div class="col-md-4">
                    <a href="javascript:void(0)" data-toggle="modal" *ngIf="quizComplete == false"
                        (click)="showConfirmation()">
                        <div class="quitbtn"> <i class="fa fa-arrow-left" aria-hidden="true"></i> Quit </div>
                    </a>

                    <a href="javascript:void(0)" *ngIf="quizComplete == true">
                        <div class="quitbtn" (click)="backToQuiz()"> <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            Close</div>
                    </a>

                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-9 cs-left-sidebar">
                <div class="cs-topheading">
                    <h4> {{quiz_name}}</h4>

                    <a href="javascript:void(0)" class="banking-btn bankingbtn"
                        *ngIf="quiz_category.length > 0;else othercat">{{quiz_category.join(', ')}}</a>
                    <ng-template #othercat></ng-template>
                </div>

                <div class="cs-questioncontainer  mt-3" *ngIf="quizComplete == false">
                    <ol class="my-2 pl-5 pr-5 pt-4  border rounded ">
                        <li class="position-relative">
                            <span class="position-absolute ml-n4">{{i+1}}</span>
                            <label [innerHTML]="questions[i]?.question"></label>
                            <ng-container *ngIf="questions[i].question_type == 'MCQ'">
                                <div class="form-check" *ngFor="let opt of questions[i].options;let k=index">
                                    <input type="radio" id="{{k}}" class="form-check-input with-gap"
                                        name="groupOfRadioGap" value="{{opt.option_value}}"
                                        (change)="selectAnswer(opt.option_value,i)"
                                        [(ngModel)]="questions[i].GivenAnswer" [disabled]="showPause == false">
                                    <label class="form-check-label" for="{{k}}">{{opt.option_value}}</label>
                                </div>
                            </ng-container>

                            <div class="form-check" *ngIf="questions[i].question_type == 'True/False'">
                                <input type="radio" id="true" class="form-check-input with-gap" name="groupOfRadioGap"
                                    value="true" (change)="selectAnswer('true',i)"
                                    [(ngModel)]="questions[i].GivenAnswer">
                                <label class="form-check-label" for="true">True</label><br /><br />

                                <input type="radio" id="false" class="form-check-input with-gap" name="groupOfRadioGap"
                                    value="false" (change)="selectAnswer('false',i)"
                                    [(ngModel)]="questions[i].GivenAnswer">
                                <label class="form-check-label" for="false">False</label>
                            </div>
                        </li>
                        <label style="color: red;">{{msg}}</label><br />
                        <label style="color:red" *ngIf="nextAllowed == true && questions[i].result == null">*Please
                            select
                            answer before proceeding next!</label>

                        <div class="cs-btnrow">
                            <button class="clearbtn" (click)="clearAnswer(i)"
                                *ngIf="questions[i].Attempt == true &&  hideButtons != true && nextAllowed == false"> <i
                                    class="fa fa-times" aria-hidden="true"></i> Clear
                                Answer </button>
                            <button class="previous" (click)="PreviousQuestion(i)"
                                *ngIf="i!=0 && hideButtons != true && previousAllowed==true"
                                [disabled]="showPause == false">Previous Question <i class="fa fa-arrow-left"
                                    aria-hidden="true"></i></button>
                            <button class="next" (click)="NewQuestion(i)"
                                *ngIf="hideNextQuestion != i && hideButtons != true"
                                [disabled]="showPause == false ||  (nextAllowed == true && questions[i].result == null)">NEXT
                                Question <i class="fa fa-arrow-right" aria-hidden="true"></i></button>


                            <input class="inputFile" type="file" name="image" *ngIf="questions[i].required == true"
                                (change)="uploadAnswer($event)" #file>



                            <button class="next" (click)="afterQuizSummary(i,true,false)"
                                *ngIf="hideNextQuestion == i && hideButtons != true"
                                [disabled]="showPause == false || (nextAllowed == true && questions[i].result == null)">SUBMIT
                                QUIZ <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </ol>
                </div>


                <!--- After Quiz Submit Result -->

                <div class="cs-questioncontainer" *ngIf="quizComplete == true">

                    <app-result-description [correctAnswer]="correctAnswer" [wrongAnswer]="wrongAnswer"
                        [attempted]="attempted" [resultInPercent]="resultInPercent" [notAttempted]="notAttempted"
                        [totalQuizQuestions]="totalLength" [quizName]="quiz_name" [userRole]="userDetails?.role">
                    </app-result-description>

                </div>

                <!--- After Quiz Submit Result -->

                <div style="height: 150px;"></div>
            </div>
            <div class="col-md-3 cs-rightcontainer">
                <div class="cs-right-sidebar">
                    <h4> QUESTIONS </h4>
                    <div class="d-flex flex-wrap pr-2">
                    <a href="javascript:void(0)" style="cursor:default" *ngFor="let total of questions;let j = index"
                        [ngClass]="{'success': questions[j]['Attempt'] == true, 'danger':questions[j]['Attempt'] == false,'light':questions[j]['Attempt'] == undefined }">{{j+1}}</a>
                    </div>
                </div>
                <div class="cs-rightsiderbar-btm">
                    <ul>
                        <li> Attempted</li>
                        <li> Not Attempted</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>

<!-- Modal -->
<!-- Modal -->
<div id="myModal2" class="modal fade" role="dialog">

    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Confirm</h4>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="quit_quiz == true;else secondMsg">Are you sure want to quit this quiz?Quiz will be auto submit
                    & you are not
                    able to attempt it again!</p>
                <ng-template #secondMsg>
                    <p>Are you sure want to quit the quiz?</p>
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="close(quit_quiz)">Yes Close</button>
                <button type="button" class="btn btn-primary" (click)="closeModal()">No</button>
            </div>

        </div>
    </div>
    <!-- Modal -->