import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import { ManageProfileService } from '../manage-profile.service';
import { HeaderComponent } from '../../header/header.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SharedComponent } from '../../shared/shared.component';

declare var $: any;

@Component({
  selector: 'app-social-profiles',
  templateUrl: './social-profiles.component.html',
  styleUrls: ['./social-profiles.component.css']
})
export class SocialProfilesComponent implements OnInit {

  userInfo;
  aspirant_social: FormGroup;
  inputSkills: object = {};
  isLoading: boolean = false;
  details;
  noSkills: boolean = false;
  detailsid;
  skills;
  show: boolean = false;
  selectedIndex;
  myClass = 'hide-class';
  uniqueSocialId: string;
  cancelButton: boolean = false;
  maxCharacters = 500;
  characters = this.maxCharacters;
  profile_list = [];
  submitted = false;
  urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  msg: boolean = false;


  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private fb: FormBuilder, private afAuth: AngularFireAuth, private firestore: AngularFirestore, private _common: SharedComponent) { }


  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
      this.uniqueSocialId = 'aspirant_social_' + uuid();
      this.aspirant_social = new FormGroup({
        SocialProfile: new FormArray([
          this.initProfiles(),
        ]),
      });
    } else {
      this.router.navigate(['home']);
    }
    this.profile_list = this._common.socailProfileList();
  }


  count(value: string) {
    this.characters = this.maxCharacters - value.length;
  }


  initProfiles() {
    return new FormGroup({
      profile_name: new FormControl('', Validators.required),
      profile_url: new FormControl('', [Validators.required, Validators.pattern(this.urlRegex)]),
      profile_description: new FormControl(''),
      external_id: new FormControl(this.uniqueSocialId),
      created_at: new FormControl(new Date())
    });
  }


  addSocialProfile() {
    const control = <FormArray>this.aspirant_social.get('SocialProfile');
    control.push(this.initProfiles());
    let index = control.length - 1;
    this.show = true;
    this.selectedIndex = index;
    this.myClass = 'hide-class';
  }

  getProfiles(form) {
    return form.controls.SocialProfile.controls;
  }

  removeSkills(i) {
    const control = <FormArray>this.aspirant_social.get('SocialProfile');
    control.removeAt(i);
  }

  async addDetails(form) {
    this.submitted = true;

    if (form.valid) {

      this.isLoading = true;
      let inputData = form.value;
      const link = inputData['SocialProfile'][this.selectedIndex].profile_url;
      const name = inputData['SocialProfile'][this.selectedIndex].profile_name;


      let validity = this._common.validateSocialProfiles(name, link);
      if (validity == true) {
        this.msg = false;
        let getSocialDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (getSocialDetails != null) {

          getSocialDetails['aspirant_personal'] = getSocialDetails.aspirant_personal ? getSocialDetails.aspirant_personal : {};
          getSocialDetails['aspirant_education'] = getSocialDetails.aspirant_education ? getSocialDetails.aspirant_education : {};
          getSocialDetails['aspirant_skills'] = getSocialDetails.aspirant_skills ? getSocialDetails.aspirant_skills : {};
          getSocialDetails['aspirant_fitness'] = getSocialDetails.aspirant_fitness ? getSocialDetails.aspirant_fitness : {};
          getSocialDetails['aspirant_interests'] = getSocialDetails.aspirant_interests ? getSocialDetails.aspirant_interests : {};
          getSocialDetails['aspirant_certifications'] = getSocialDetails.aspirant_certifications ? getSocialDetails.aspirant_certifications : {};
          getSocialDetails['aspirant_language'] = getSocialDetails.aspirant_language ? getSocialDetails.aspirant_language : {};
          getSocialDetails['aspirant_social_profile'] = inputData;


        } else {

          getSocialDetails = {};
          getSocialDetails['aspirant_personal'] = {};
          getSocialDetails['aspirant_education'] = {};
          getSocialDetails['aspirant_skills'] = {};
          getSocialDetails['aspirant_fitness'] = {};
          getSocialDetails['aspirant_interests'] = {};
          getSocialDetails['aspirant_certifications'] = {};
          getSocialDetails['aspirant_language'] = {};
          getSocialDetails['aspirant_social_profile'] = inputData;
        }


        getSocialDetails['created_by'] = this.userInfo['uid'];
        getSocialDetails['modified_by'] = this.userInfo['uid'];
        getSocialDetails['created_date_time'] = new Date();
        getSocialDetails['modified_date_time'] = new Date();
        getSocialDetails['operation'] = 'created';
        getSocialDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


        let result = await this._profileService.addSocialProfileDetails('aspirants', getSocialDetails);
        let data = await this._header.getDetails();
        if (data != undefined) {
          this.submitted = false;
          this.getDetails();
        }
        this.isLoading = false;
      } else {
        this.msg = true;
        this.isLoading = false;
      }
    }
  }



  getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        //let result = await this._profileService.getSkillsDetails('aspirant_skills', user.uid);
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_social_profile != undefined && Object.keys(result.aspirant_social_profile).length != 0) {
          this.details = _.orderBy(result.aspirant_social_profile.SocialProfile, [(profile) => profile.created_at], ["desc"]);
          this.detailsid = result.id;
          this.noSkills = false;
          this.aspirant_social.setControl('SocialProfile', this.setExistingProfile(this.details))
          this.show = false;
          this.cancelButton = true;
          this.isLoading = false;
        } else {
          this.detailsid = (result == null) ? '' : result.id;
          this.noSkills = true;
          this.show = true;
          this.selectedIndex = 0
          this.cancelButton = false;
          this.myClass = 'hide-class';
        }
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });

  }


  setExistingProfile(profileSet) {
    let formarray = new FormArray([]);
    profileSet.forEach(s => {
      formarray.push(this.fb.group({
        profile_name: s.profile_name,
        profile_url: s.profile_url,
        profile_description: s.profile_description,
        external_id: s.external_id,
        created_at: s.created_at
      }))
    })
    return formarray;
  }



  async updateDetails(form) {
    this.submitted = true;
    if (form.valid) {

      this.isLoading = true;
      let inputData = form.value;
      const link = inputData['SocialProfile'][this.selectedIndex].profile_url;
      const name = inputData['SocialProfile'][this.selectedIndex].profile_name;


      let validity = this._common.validateSocialProfiles(name, link);

      if (validity == true) {
        this.msg = false;
        let getSocialDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

        getSocialDetails['aspirant_personal'] = getSocialDetails.aspirant_personal ? getSocialDetails.aspirant_personal : {};
        getSocialDetails['aspirant_education'] = getSocialDetails.aspirant_education ? getSocialDetails.aspirant_education : {};
        getSocialDetails['aspirant_skills'] = getSocialDetails.aspirant_skills ? getSocialDetails.aspirant_skills : {};
        getSocialDetails['aspirant_fitness'] = getSocialDetails.aspirant_fitness ? getSocialDetails.aspirant_fitness : {};
        getSocialDetails['aspirant_interests'] = getSocialDetails.aspirant_interests ? getSocialDetails.aspirant_interests : {};
        getSocialDetails['aspirant_certifications'] = getSocialDetails.aspirant_certifications ? getSocialDetails.aspirant_certifications : {};
        getSocialDetails['aspirant_language'] = getSocialDetails.aspirant_language ? getSocialDetails.aspirant_language : {};
        getSocialDetails['aspirant_social_profile'] = inputData;

        getSocialDetails['created_by'] = this.userInfo['uid'];
        getSocialDetails['modified_by'] = this.userInfo['uid'];
        getSocialDetails['created_date_time'] = new Date();
        getSocialDetails['modified_date_time'] = new Date();
        getSocialDetails['operation'] = 'created';
        getSocialDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

        let result = await this._profileService.updateSocialProfileDetails('aspirants', this.detailsid, getSocialDetails);

        delete getSocialDetails['userId'];
        delete getSocialDetails['created_by'];
        delete getSocialDetails['modified_by'];

        sessionStorage.setItem('aspirantInfo', JSON.stringify(getSocialDetails));
        this.submitted = false;
        this.ngOnInit();
        this.isLoading = false;
      } else {
        this.msg = true;
        this.isLoading = false;
      }
    }
  }



  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  cancel() {
    this.getDetails();
    this.myClass = 'hide-class';
    this.show = false;
    this.cancelButton = true;
    this.submitted = false;
  }

  showForm(index) {
    this.selectedIndex = index;
    this.show = true;
  }

  resetForm() {
    this.getDetails();
    this.show = false;
  }

}
