<div class="page-heading row">
    <div class="heading col-lg-8 col-md-6 col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="dashboard">Dashboard / My Orders</a></li>
            </ol>
        </nav>
        <h2>My Orders</h2>
    </div>

    </div>

<!-- Tab start here-->
<div class="op-tab row">
    <div class="col-md-7 col-lg-9 p-0">
        <ul class="list-unstyled list-inline m-md-0 mt-2">
            <li class="list-inline-item mr-4">
                <a routerLink="/orders">Top Orders</a></li>
            <li class="list-inline-item active">
                <a routerLink="/history">History</a></li>
        </ul>
    </div>
    <div class="col-md-5 col-lg-3 pl-0 pr-0" >
        <div class="input-group bg-light">
            <input type="text" class="form-control border-right-0 bg-light"  name="payments" #paymentId aria-label="Search Orders"
                placeholder="Search Invoice">
            <div class="input-group-append ">
                <span class="input-group-text  bg-transparent form-control border-left-0"><a href="javascript:void()" (click)="searchPayments(paymentId.value)"><i class="fa fa-search" aria-hidden="true"></i></a></span>
            </div>
        </div>
    </div>

</div>
<!-- Tab message end here-->

<div class="rounded border mt-3  mb-3 shadow-sm">
    <div class="row ">
        <div class="col-md-12">
            <ul class="list-unstyled mb-0">
                <li class="media  d-block d-md-flex" *ngFor="let list of payment_list">
                    <img src="assets/img/img-counselling.png" class="m-3">
                    <div class="media-body pr-3  pl-3 pt-md-3 pb-md-3">
                        <div class="row  ">
                            <div class="col-md-8 ">
                                <h5 class="mt-0 mb-2 text-orange">{{ list.payment_id }}</h5>
                                Career Counselling
                            </div>
                            <div class="col-md-4 text-right">
                                <p class="pr-4"><br>
                                    <span><small>{{list.payment_date * 1000 | date:'dd-MM-yyyy'   }}</small></span>
                                </p>
                            </div>
                        </div>


                        <div class="row pt-3">
                            <div class="col-md-12 text-right">
                                <div class="pr-md-4 "><button type="button" class="btn btn-orange  " (click)="downloadInvoice(list.payment_id)">Download
                                        Invoice</button> </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>

</div>

<div class="row " *ngIf="payment_list?.length == 0">
    <div class="col-md-12">


        <ul class="list-unstyled mb-0">
            <li class="media d-block d-md-flex">
                No History Found!
            </li>

        </ul>
    </div>
</div>

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>