<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
      </ol>
    </nav>
    <h2>Manage Profile</h2>
  </div>
</div>

<div class="row">
  <!-- Aside container -->

  <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

  <!-- Aside container end-->

  <!-- Profile container -->
  <div class="col-md-9 col-lg-9">

    <div class="filter-tab ">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/skills">Skill</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
        <li class="list-inline-item active"><a routerLink="/manage-profile/fitness">Fitness</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
      </ul>
    </div>



    <div class="data-list p-3 mb-3  ">

      
      <!-- <div class="row mb-3" *ngIf="show==false">
      <div class="col-md-12">
        <div class="pull-right"><a class="btn btn-primary-outline m-0 " href="javascript:void(0)">+ Add fitness</a>
        </div>
        <p class="form-msg">Add your fitness</p>
      </div>
    </div> -->

      <div class="card bg-form mb-3" *ngIf="show==false">
        <div class="cardHeader ">
          <div class="row">

            <div class="col-md-12">
              <div class="pull-right"><a href="javascript:void(0)" (click)="showForm()"
                  class="btn btn-secondary-outline btn-small ">Edit</a> </div>
              <div class="profile-details">
                <h3>{{ details['body_type'] }} <span class="badge">Eye Sight
                    <strong>{{ details['eye_sight'] }}</strong></span> </h3>
                <ul>
                  <li><span class="fs11 mr-2">Weight</span> <strong>{{ details['weight'] ? details['weight'] : 'N/A' }}
                      kg</strong></li>
                  <li><span class="fs11 mr-2">Height</span> <strong>{{ details['height'] ? details['height'] : 'N/A' }}
                      cm</strong></li>
                </ul>
              </div>
            </div>



          </div>
        </div>
      </div>


      <form #fitness="ngForm" *ngIf="show==true"
        (ngSubmit)="details['id'] ? updateDetails(fitness) : !details['id'] ? addDetails(fitness):addDetails(fitness)"
        ngNativeValidate>

        <div class="row">
          <div class="col-md-6"><label>Body Type<span class="required">*</span></label>
            <div class="form-group"> <select class="form-control" name="body_type" [(ngModel)]="details['body_type']"
                required>
                <option value="">Please select</option>
                <option value="slimfit">Slim Fit</option>
                <option vaue="muscular">Muscular</option>
                <option vaue="overweight">overweight</option>
              </select>
            </div>
          </div>
          <div class="col-md-6"><label>Height<span class="required">*</span></label>
            <div class="form-group"> <input type="text" class="form-control" name="height" maxlength="3"
                (keypress)="numberOnly($event)" [(ngModel)]="details['height']" placeholder="height (in cm)" required>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-6"><label>Weight<span class="required">*</span></label>
            <div class="form-group"> <input type="text" class="form-control" name="weight" maxlength="3"
                (keypress)="numberOnly($event)" [(ngModel)]="details['weight']" placeholder="weight (in kg)" required>
            </div>
          </div>
          <div class="col-md-6"><label>Eye Sight<span class="required">*</span></label>
            <div class="form-group"> <select class="form-control" name="eye_sight" [(ngModel)]="details['eye_sight']"
                required>
                <option value="">Please select</option>
                <option value="6/6">6/6</option>
                <option value="below 6/20">Below 6/20</option>
                <option value="above 6/20">Above 6/20</option>
              </select>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-6"><label>Any Physical Inability</label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <div class="custom-control custom-radio mb-3">
                  <input type="radio" class="custom-control-input" id="customControlValidation4"
                    name="physical_inability" value="yes" [(ngModel)]="details['physical_inability']"
                    (change)="showSection('Yes')">

                  <label class="custom-control-label" for="customControlValidation4">Yes</label>
                </div>

              </div>
              <div class="form-check form-check-inline">
                <div class="custom-control custom-radio mb-3">
                  <input type="radio" class="custom-control-input" id="customControlValidation5"
                    name="physical_inability" value="no" [(ngModel)]="details['physical_inability']"
                    (change)="showSection('No')">
                  <label class="custom-control-label" for="customControlValidation5">No</label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="hideSection == true">
              <div class="form-group col-sm-12">
                <select class="form-control" name="type_of_inability" [(ngModel)]="details['type_of_inability']"
                  [attr.required]="show == true">
                  <option value="">Select Type of physical inability</option>
                  <option *ngFor="let item of disablilityList" [ngValue]="item.name">{{ item.display_name }}</option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <select class="form-control" name="inability_percent" [(ngModel)]="details['inability_percent']"
                  [attr.required]="show == true">
                  <option value="">Inability %</option>
                  <option value="10">10 %</option>
                  <option value="25">25 %</option>
                  <option value="50">50 %</option>
                </select>
              </div>
            </div>
          </div>

        </div>


        <div class="row mt-3">
          <div class="col-md-12 text-right">
            <button class="btn btn-secondary-outline" *ngIf="cancelButton == true" (click)="resetForm()">Cancel</button>
            <button class="btn btn-primary" type="submit">Submit</button>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>