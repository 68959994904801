<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
      </ol>
    </nav>
    <h2>Manage Profile</h2>
  </div>
</div>


<div class="row">
  <!-- Aside container -->

  <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

  <!-- Aside container end-->

  <!-- Profile container -->
  <div class="col-md-9 col-lg-9">

    <div class="filter-tab ">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item"><a routerLink="/manage-profile/personal">Personal Details</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/skills">Skill</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
        <li class="list-inline-item active"><a routerLink="/manage-profile/certificate">Certifications</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
      </ul>
    </div>

    <div class="data-list p-3 mb-3 ">
      <div class="row " *ngIf="show==false">
        <div class="col-md-12">
          <div class="pull-right"><a class="btn btn-primary-outline m-0 " (click)="addCertificates()"
              href="javascript:void(0)">+ Add
              Certifications</a>
          </div>
          <h5 class="font-weight-bold">Certifications</h5>
          <p>Add details of Certifications you have achieved/completed</p>
        </div>
      </div>

      <div class="accordion mt-3" id="accordionCertificate" *ngIf="show==false">

        <div class="card bg-form mb-3" *ngFor="let aspirant of details;let k=index">
          <div class="cardHeader ">
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right"><a href="javascript:void(0)" (click)="showForm(k)"
                    class="btn btn-secondary-outline btn-small mr-2">Edit</a>
                </div>
                <div class="profile-details">
                  <h3 class="mb-0"> {{aspirant.certification_name}} </h3>
                  <ul>
                    <li>{{aspirant.certified_by}}</li>
                    <li>{{aspirant.year_of_completion}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <form [formGroup]="aspirant_certifications"
        (ngSubmit)="detailsid ? updateDetails(aspirant_certifications) : !detailsid ? addDetails(aspirant_certifications):addDetails(aspirant_certifications)"
        ngNativeValidate>


        <div formArrayName="Certificates" *ngIf="show==true">

          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

            <div class=" panel-default">

              <div id="collapseOne" class="panel-collapse " role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">

                  <div [formGroupName]="i" id="certificatesid"
                    *ngFor="let certificates of getCertificates(aspirant_certifications); let i = index"
                    [ngClass]="selectedIndex!=i?myClass:''">

                    <div class="row">
                      <div class="col-md-6"><label>Certification Name<span class="required">*</span></label>
                        <div class="form-group"><input type="text" class="form-control"
                            formControlName="certification_name" placeholder="Enter Certification Name" required></div>
                      </div>

                      <div class="col-md-6"><label>Certified By<span class="required">*</span></label>
                        <div class="form-group"><input type="text" class="form-control" formControlName="certified_by"
                            placeholder="Mention organization/institute name" required="required"></div>
                      </div>

                      <div class="col-md-6"><label>Year Of Completion<span class="required">*</span></label>
                        <div class="form-group"><select class="form-control" formControlName="year_of_completion"
                            required>
                            <option value="">Years</option>
                            <option *ngFor="let years of allYears" value="{{years}}">{{years}}</option>
                          </select></div>
                      </div>

                      <input type="hidden" formControlName="external_id">
                    </div>

                  </div>

                  <div class="row mt-3">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-secondary-outline" *ngIf="cancelButton == true"
                        (click)="cancel()">Cancel</button>
                      <!-- <button class="btn btn-secondary-outline" (click)="resetForm()">Reset</button> -->
                      <button class="btn btn-primary" type="submit">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>