import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';


@Component({
  selector: 'app-career-suggestions',
  templateUrl: './career-suggestions.component.html',
  styleUrls: ['./career-suggestions.component.css']
})
export class CareerSuggestionsComponent implements OnInit {

  isLoading: boolean = false;
  suggested_exams: any = [];
  config: any;
  totalRecords: number = 0;
  filteredSector = [];

  constructor(private _header: HeaderComponent) {
    this.isLoading = true;
  }

  async ngOnInit() {
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.totalRecords
    };

    let data = await this._header.allSuggestedExams();             //suggest exams to aspirant based on cuurentQualifications//
    if (data.length > 0) {
      this.suggested_exams = data;
      this.filteredSector  = data;
      this.config.totalItems = data.length;
    } else {
      this.suggested_exams = [];
      this.config.totalItems = 0;
    }
    this.isLoading = false;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }


  async selectedExamFilter(selected_sector: string) {
    if (selected_sector != '') {
      this.config.currentPage = 1;
      let examInfo = JSON.parse(sessionStorage.getItem('exam_suggestions'));
      let examResult = examInfo.filter(o => o.job_sector === selected_sector);
      this.suggested_exams = examResult;
      this.config.totalItems = examResult.length;
    } else {
      this.ngOnInit();
    }
  }
}
