    <!-- Breadcrumb -->
    <div class="page-heading row">
    <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
    <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item"  ><a routerLink="/careers">Careers</a></li>
    <li class="breadcrumb-item active" aria-current="page">Defence</li>
    </ol>
    </nav>
    <h2>Defence </h2>
    </div>
    </div>
    
      <!-- container -->
    <div class="row">
    <div class="col-md-10">
    <div class="data-list ">
    
    <!-- Summary Start -->
    <div id="summary" class="p-5 mb-2" >
    <div class="row"><div class="col-md-12 col-lg-6"> <h1>Defence</h1>
    
    <p class="pt-3 lead">A career in the Defence Force gives you real purpose. You'll work as part of a tight-knit team, protecting your whānau, your country and our environment from harm. And you'll have the honour of representing India wherever    you go.</p>
    </div><div class="col-md-12  col-lg-6 text-center"> <img src="assets/img/careers/career/defence.png"  class="img-fluid"  /></div>
    
    
        <div class="col-md-12">
        <div class="row mt-5">
        <div class="col-md-12 col-lg-4 mb-4">
        <div class="row p-0" >
        <div  class="col-md-2 col-lg-3"><img src="assets/img/careers/career/icon-skill.png" class="img-fluid mb-3"></div>
        <div class="media-body col-md-10 col-lg-9"><h5  class="mt-0 mb-1 font-weight-bold">Skill</h5>
        <p>Provide training for new skill so that be ready for new chalanges</p>
        </div></div>
        </div>
        
        <div class="col-md-12 col-lg-4 mb-4">
        <div class="row p-0" >
        <div  class="col-md-2 col-lg-3"><img src="assets/img/careers/career/icon-fitness.png" class="img-fluid mb-3"></div>
        <div class="media-body col-md-10 col-lg-9"><h5  class="mt-0 mb-1 font-weight-bold">Fitness</h5>
        <p>Provide training for fitness , take  
        care of health and routine diet</p>
        </div></div>
        </div>
        
        <div class="col-md-12 col-lg-4 mb-4">
        <div class="row p-0" >
        <div  class="col-md-2 col-lg-3"><img src="assets/img/careers/career/icon-growth.png" class="img-fluid mb-3"></div>
        <div class="media-body col-md-10 col-lg-9"><h5  class="mt-0 mb-1 font-weight-bold">Growth</h5>
        <p>Good salary after retirement provide pension, medical insurance and other facility</p>
        </div></div>
        </div>
        </div>
        </div>
        
        
    </div>
    </div>
    <!-- Summary End -->
    
    
     <!-- Category Start -->
    <div id="categories" class="p-5 mb-2 bg-light-blue" >
    
    <div class="p-3 text-center">
    <h3>Defence Categories</h3>
    <p> Defence divided by three categories</p>
    </div>
    
    
    <div class="row">
    <div class="col-md-6 col-lg-4">
    <div class="grid data-list">
        <figure class="effect-white small-device">
        <img   src="assets/img/careers/career/army.png"  >
        <figcaption>
        <h2>Army</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

        </p>
        <a href="#" routerLink="/careerdetails">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>
    </div>
    
    
    <div class="col-md-6 col-lg-4">
    <div class="grid data-list">
        <figure class="effect-white small-device">
        <img   src="assets/img/careers/career/navy.png"  >
        <figcaption>
        <h2>Navy</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <a href="#" routerLink="/careerdetails">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>
    </div>
    
    
    <div class="col-md-6 col-lg-4">
    <div class="grid data-list">
        <figure class="effect-white small-device">
        <img   src="assets/img/careers/career/airforce.png"  >
        <figcaption>
        <h2>Airforce</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <a href="#" routerLink="/careerdetails">&nbsp;</a>
        </figcaption>			
        </figure>
        </div>
    </div>
    
    </div>
    
    </div>
    <!-- Category End -->
    
    
     <!-- Vacancies -->
    <div id="vacancies" class="p-5" >
    <p class="font-weight-bold">256 Vacancies</p>
    
    <div class="border rounded">
    <table class="table table-striped mb-0 table-responsive">
    <thead>
    <tr>
    <th scope="col">Name</th>
    <th scope="col">Eligibility</th>
    <th scope="col">Last Date</th>
    <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
    <td>10th Pass</td>
    <td>02/10/2020</td>
    <td><a   class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">View</a></td>
    </tr>
    <tr>
     <tr>
    <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
    <td>10th Pass</td>
    <td>02/10/2020</td>
    <td><a   class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">View</a></td>
    </tr>
    <tr>
     <tr>
    <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
    <td>10th Pass</td>
    <td>02/10/2020</td>
    <td><a   class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">View</a></td>
    </tr>
    <tr>
     <tr>
    <th scope="row"><span class="font-weight-bold">Army Officer</span></th>
    <td>10th Pass</td>
    <td>02/10/2020</td>
    <td><a   class="btn btn-primary btn-sm m-0" routerLink="/careerdetails">View</a></td>
    </tr>
    <tr>
    
    </tbody>
    </table>
    </div>
    <nav aria-label="Page navigation example" class="mt-4">
    <ul class="pagination justify-content-end mb-0">
    <li class="page-item disabled page-prev">
    <a class="page-link" href="#" tabindex="-1">Previous</a>
    </li>
    <li class="page-item page-next">
    <a class="page-link" href="#">Next</a>
    </li>
    </ul>
    </nav>
    </div>

   <!-- vacancies End -->
    
    
    </div>
    </div>
    
    
    
    
     <!-- Right nav -->   
    <div class="col-md-2">
     <!-- aside container -->
     <div class=" aside"> 
        <nav class="nav mb-5">
            <a class="nav-link active w-100" href="#">Summary</a>
            <a class="nav-link w-100" href="#">Defence Categories	</a>	
            <a class="nav-link" href="#">Vacancies </a>	  	
        </nav>
       
        <div id="aside-category">
          <p class="text-muted pl-3 mb-0 small-text font-weight-bold">OTHER CAREER OPTION</p> 
          <nav class="nav mb-5">
          <a class="nav-link w-100"  href=" " routerLink="/career">Healthcare</a>
          <a class="nav-link w-100" href=" " routerLink="/career"> Management</a>
         <a class="nav-link w-100" href=" " routerLink="/career">  Railway</a>
         <a class="nav-link w-100" href=" " routerLink="/career">Banking	</a>
         </nav>	
         </div>
    
    </div>
      <!-- aside container end-->
    </div>   
    <!-- Right nav end -->
    
    <!-- Enquiry & Support -->      
    <div class="col-md-10">
    <div class="data-list">
    <div class="row d-flex ">
    <div class="col-12 col-md-6 ">
    <div class="p-5">
    <h4>Enquiry</h4>
    <div class="row">
    <div class="col-md-12">
    <label>Title</label>
    <div class="form-group"><input type="text" required="" class="form-control "></div> 
    </div>
    <div class="col-md-12">
    <label>Message</label>
    <div class="form-group"><textarea required="" class="form-control " cols="5" rows="5"></textarea></div> 
    </div>
    <div class="col-md-12"> 
    <button  type="submit" class="btn btn-primary">Submit Message</button>
    </div>
    </div>
    </div>      
    </div>
    
  
    <div class="col-12 col-md-6 bg-light-blue d-flex align-items-center justify-content-center">
    <div class="  p-3 text-center  ">
    <img   src="assets/img/icon-support.png"  >
    <h4>Support</h4>
    <p class="pt-1">We provide information about colleges, <br />
    career guidance and many more...</p>
    <p><a   class="btn-primary-outline btn "  >UPGRADE PLAN</a></p></div></div>
    </div>
    </div>
    </div>
    <!-- Enquiry & Support end--> 
    
    </div>
    