<div class="bg-blue-dark text-white rounded  mb-3">
  <div class="row align-items-center px-4 py-3 ">
    <div class="col">
      <div class="page-heading row pb-0">
        <div class="heading col-sm-12 ">
          <h2 class="pt-0">Payment Review</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Success / Failed message start here-->

<app-payment #payment> </app-payment>

<!-- Success / Failed message end here-->


<!--  body content start here -->
<div class="row mb-3">
  <div class="col-md-8 order-2 order-md-1">
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card shadow-none border">
          <div class="card-body">
            <!-- <a href="#">Signin</a> or <a href="#">Register</a> -->
            <div class="row">
              <div class="col"><span class="text-small text-muted d-block">Signin with</span>
                <strong>{{userDetails?.first_name}} {{ userDetails?.last_name}}</strong></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card shadow-none border">
          <div class="card-body">
            <div id="view-address">
              <div class="row" *ngIf="show == false">
                <div class="col-6">
                  <h5 class="pb-3 font-weight-bold">Billing Address</h5>
                </div>
                <div class="col-6 text-right"><button class="btn btn-sm btn-primary-outline m-0"
                    (click)="showForm()">Edit</button> </div>
                <div class="col-12">
                  <p class="font-weight-bold mb-0">{{userDetails?.first_name}} {{ userDetails?.last_name}}</p>
                  <p class="text-muted mb-0"> {{billingAddress.address?billingAddress.address:aspirantAddress?.address}}
                    {{billingAddress.city?billingAddress.city:aspirantAddress?.city}}
                    {{billingAddress.state?billingAddress.state:aspirantAddress?.state}}
                    {{billingAddress.country?billingAddress.country:aspirantAddress?.country}}
                    {{billingAddress.zip_code?billingAddress.zip_code:aspirantAddress?.zip_code}}</p>
                  <p class="text-muted"> Email - {{userInfo?.email}}<br />
                    Mobile - {{aspirantAddress.phone? aspirantAddress?.phone:'N/A'}}
                  </p>
                </div>
              </div>
            </div>
            <div id="edit-address" *ngIf="show == true">
              <form #billing="ngForm" (ngSubmit)="saveBillingAddress(billing)" ngNativeValidate>
                <h5 class="pt-2 pb-3 font-weight-bold">Billing Address</h5>
                <div class="row">
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="first_name"
                      placeholder="First Name " [(ngModel)]="aspirantDetails.first_name" disabled>
                  </div>
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="last_name"
                      placeholder="Last Name" [(ngModel)]="aspirantDetails.last_name" disabled></div>
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="email"
                      placeholder="Email Address" [(ngModel)]="aspirantDetails.email" disabled>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="input-group input-contact  ">
                      <span class="input-group-text" id="inputGroup-sizing-sm">+91</span>
                      <input type="text" class="form-control" name="phone" [ngModel]="billingAddress?.phone"
                        maxlength="10" placeholder="XXX XXX XXXX">
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <select class="form-control" aria-label="">
                      <option value="India">India</option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <select class="form-control" aria-label="" [ngModel]="billingAddress?.state" name="state" required>
                      <option value="''">Please select</option>
                      <option *ngFor="let list of states" value="{{list.name}}">{{list.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="city" placeholder="Town/City"
                      [ngModel]="billingAddress?.city" required></div>
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="zip_code"
                      placeholder="Zip Code" [ngModel]="billingAddress?.zip_code" maxlength="6" required></div>
                  <div class="col-md-6 mb-3"><input type="text" class="form-control" name="address"
                      placeholder="Address 1 " [ngModel]="billingAddress?.address" required></div>
                  <!-- <div class="col-md-6 mb-3"><input type="text" class="form-control" placeholder="Address 2"></div> -->

                  <div class="col-md-12">
                    <button type="submit" class="btn btn-sm btn-fourth m-0">Save
                      Address</button>
                    <button type="button" class="btn btn-sm btn-fourth" (click)="cancelAddress()">Cancel</button>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 order-1 order-md-2">
    <div class="card shadow-sm border">
      <div class="card-header bg-light-blue">
        <div class="row">
          <div class="col-12 text-right"><span
              class="badge badge-small rounded-pill bg-warning text-dark">Assessment</span></div>
          <div class="col-3 col-md-12 col-lg-3 "><img src="assets/img/thumb-assessment.jpg"
              class="border border-2 border-white" /></div>
          <div class="col-9 col-md-12 pb-3 col-lg-9" *ngIf="paymentType == 'counselling';else otherType">
            <h5 class="h6 font-weight-bold mb-0 px-2">Career Counselling</h5>
            <p class="mb-0 px-2"><span class="badge badge-small bg-white font-normal"><span
                  class="text-primary font-normal text-truncate wm-100 d-inline-block">Talk with our expert and get
                  right career guidance for your careers.
                </span></span></p>
          </div>
          <ng-template #otherType>
            <div class="col-9 col-md-12 pb-3 col-lg-9">
              <h5 class="h6 font-weight-bold mb-0 px-2">{{quiz_details?.name}}</h5>
              <p class="mb-0 px-2"><span class="badge badge-small bg-white font-normal"><span
                    class="text-primary font-normal text-truncate wm-100 d-inline-block">{{quiz_details?.category_name.join(', ')}}</span></span>
              </p>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="card-body">
        <div class="row  border-bottom py-3">
          <div class="col"><span class="text-muted fs-6  ">Sab Total</span></div>
          <div class="col text-right"><span class="font-weight-bold text-muted"><i class="fa fa-inr"
                aria-hidden="true"></i> {{ payableAmount | number : '1.2-2' }}</span></div>
        </div>
        <div class="row py-3">
          <div class="col"><span class=" fs-6 text-muted font-weight-bold">Total</span></div>
          <div class="col text-right"><span class="display-5 font-weight-bold"><i class="fa fa-inr"
                aria-hidden="true"></i> {{ payableAmount | number : '1.2-2'}}</span></div>
        </div>

        <h6 class="px-2 px-md-2 px-lg-5 py-3  text-center mb-0 text-muted ">
          <i class="fa fa-lock" aria-hidden="true"></i> Seller never see your credit card information.
        </h6>

      </div>
    </div>
    <!--  coupon start here -->
    <div class="row py-3">
      <div class="col-9 col-md-12 col-lg-9 pr-lg-0 mb-md-2 mb-lg-0"><input #coupon type="text" class="form-control"
          placeholder="Enter coupon code" maxlength="10"></div>
      <div class="col-3 col-md-12 col-lg-3 pl-2"><button type="button" class="btn btn-sm btn-primary-outline m-0 w-100"
          (click)="applyCoupon(coupon.value)">Apply</button></div>
      <p style="color:red; margin-left: 16px;">{{showMsg}}</p>
    </div>
    <!--  coupon end here -->

    <ng-container *ngIf="paymentType == 'counselling' && payment.paymentSuccess == false && show == false;">
      <button type="button" class="btn btn-lg btn-secondary w-100"
        (click)="payment.Checkout(payableAmount,'Counselling')"><i class="fa fa-lock" aria-hidden="true"></i>
        Confirm &amp; Pay</button>
    </ng-container>

    <ng-container *ngIf="paymentType != 'counselling' && payment.paymentSuccess == false && show == false;">
      <button type="button" class="btn btn-lg btn-secondary w-100"
        (click)="payment.Checkout(quiz_details['settings']['amount'],'Quiz',paymentType)"><i class="fa fa-lock"
          aria-hidden="true"></i>
        Confirm &amp; Pay</button></ng-container>

    <ng-container *ngIf="payment.paymentSuccess == true && paymentType == 'counselling'">
      <a href="#" class="btn btn-lg btn-secondary w-100" routerLink="/dashboard"><i class="fa fa-lock"
          aria-hidden="true"></i>
        Go Home</a>
    </ng-container>

    <ng-container *ngIf="payment.paymentSuccess == true && paymentType != 'counselling'">
      <a href="#" class="btn btn-lg btn-secondary w-100" routerLink="/attempt/{{paymentType}}"><i class="fa fa-lock"
          aria-hidden="true"></i>
        Back To Quiz</a>
    </ng-container>
  </div>

</div>
<!--  body content end here -->

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>