<div class="row">
    <div class="col-lg-4" *ngFor="let list of employeeQuizzes;let i=index">
        <div class="card rounded border mb-4 shadow-sm">
            <div class="card-body p-0 quiz-box">
                <div class="row">
                    <div class="col-12">
                        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                            <div class="heading px-3 pt-3 pb-3 row">
                                <div class="col">
                                    <h4 class="mb-0">Quiz {{i+1}}</h4>
                                </div>
                            </div>
                            <div class="carousel-inner">
                                <div>
                                    <div class="quiz-mid-row">
                                        <h5 class="mb-2 w-100 text-truncate d-inline-block">
                                            {{list.name?list.name:list.quiz_name}}</h5>
                                        <div class="d-block d-md-flex" style="height: 25px;">
                                            <div class="col-md-6 p-0 "> <span
                                                    [ngClass]="{'banking-btn w-100 text-truncate d-inline-block': list?.category_name.length > 0, '': list?.category_name.length == 0}">{{list?.category_name.join(', ')}}</span>
                                            </div>
                                            <span class=" col-6 text-right pull-right small-text"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                    fill="currentColor" class="bi bi-clock mr-2" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path
                                                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>{{list.duration?list.duration.minutes:list.settings.duration.minutes}}
                                                min</span>
                                        </div>
                                        <div class="d-block d-md-flex mt-1  ">
                                            <div class="col-md-8 p-0 free-text">
                                                {{ list.settings?list.settings.access_type:list.access_type }}
                                            </div>
                                            <div class="col-md-4 p-0 attemptbtn">

                                                <a href="#" routerLink="/attempt/{{list?.id}}">Attempt</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf="employeeQuizzes.length == 0">
    <div class="col-lg-12">
        <div class="row bg-light-blue m-0 ">
            <div class="col-md-10">
                <p class="pt-4 pb-4 pl-2 pr-2 m-0">Currently there is no test assigned to you!!!.</p>
            </div>
            <div class="col-md-2 text-right text-md-center">
                <p class="p-md-4 pr-4 pt-0 pb-4  m-0"><img src="assets/img/icon-counselling.png"></p>
            </div>
        </div>
    </div>
</div>