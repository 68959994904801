import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ManageProfileModule } from './manage-profile/manage-profile.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AuthService } from './auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { AngularFireAnalyticsModule, DEBUG_MODE, CONFIG, UserTrackingService, ScreenTrackingService } from '@angular/fire/analytics';

import {
  MatAutocompleteModule,
  MatInputModule
} from '@angular/material';
import { CoursesComponent } from './courses/courses.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CourseComponent } from './course/course.component';
import { CareersComponent } from './careers/careers.component';
import { CareerComponent } from './career/career.component';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { CourseSuggestionsComponent } from './course-suggestions/course-suggestions.component';
import { CareerSuggestionsComponent } from './career-suggestions/career-suggestions.component';
import { HomeComponent } from './home/home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomPipe } from './custom.pipe';
import { BlogComponent } from './blog/blog.component';
import { UniquePipe } from './unique.pipe';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { HistoryComponent } from './history/history.component';
import { environment } from '../environments/environment';
import { QuizComponent } from './quiz/quiz.component';
import { AttemptComponent } from './attempt/attempt.component';
import { MyLearningComponent } from './my-learning/my-learning.component';
import { ResultDetailsComponent } from './result-details/result-details.component';
import { ResultDescriptionComponent } from './result-description/result-description.component';
import { PaymentComponent } from './payment/payment.component';
import { EmployeeQuizComponent } from './employee-quiz/employee-quiz.component';
import { OrderReviewComponent } from './order-review/order-review.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    SignupComponent,
    //LoginComponent,
    CoursesComponent,
    CourseDetailsComponent,
    CourseComponent,
    CareersComponent,
    CareerComponent,
    CareerDetailsComponent,
    CourseSuggestionsComponent,
    CareerSuggestionsComponent,
    HomeComponent,
    CustomPipe,
    BlogComponent,
    UniquePipe,
    NewDashboardComponent,
    OrdersComponent,
    HistoryComponent,
    QuizComponent,
    AttemptComponent,
    ResultDetailsComponent, MyLearningComponent, ResultDescriptionComponent, PaymentComponent, EmployeeQuizComponent,OrderReviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ManageProfileModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.config),
    AngularFireAuthModule,
    NgbModule,
    NgxPaginationModule,
    //AngularFireAnalyticsModule
  ],
  providers: [AuthService, HeaderComponent,
    //   ScreenTrackingService, UserTrackingService, {
    //   provide: CONFIG, useValue: {
    //     send_page_view: false,
    //     allow_ad_personalization_signals: false,
    //     anonymize_ip: true
    //   }
    // },{ provide: DEBUG_MODE, useValue: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}