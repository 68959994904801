<div class="bg-blue-dark text-white rounded  ">
  <div class="row align-items-center p-4 ">
    <div class="col">
      <!-- Breadcrumb -->
      <div class="page-heading row">
        <div class="heading col-sm-12 ">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a routerLink="/dashboard" class="text-white">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">My Learning</li>
            </ol>
          </nav>
          <h2>My Learning </h2>
        </div>
      </div>
    </div>
    <div class="col text-right"><a routerLink="/dashboard" class="  link-white"><svg xmlns="http://www.w3.org/2000/svg"
          width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg> BACK TO DASHBOARD</a></div>
  </div>

  <div class="row mx-2">
    <div class="col">
      <ul class="nav nav-pills nav-glb-pills" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link " id="pills-course-tab" data-bs-toggle="pill" href="javascript:void(0)" role="tab"
            aria-controls="pills-course" aria-selected="true">Courses</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-quizz-tab " data-bs-toggle="pill" href="javascript:void(0)" role="tab"
            aria-controls="pills-quizz" aria-selected="false">Quizz</a>
        </li>
      </ul>
    </div>
  </div>
</div>


<div class="tab-content" id="pills-tabContent">

  <div class="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
    <div class="row py-3 px-2">
      <div class="col-md-8 py-2"><select class="form-control w-auto d-inline-block mr-2" #categories name="categories"
          (change)="categoryFilters('category',categories.value)">
          <option selected="" disabled>Select Category</option>
          <option _ngcontent-cnw-c110="" *ngFor="let list of categories_list" value="{{list.name}}">{{list.name}}
          </option>
          <!-- <option _ngcontent-cnw-c110="">Quiz Type</option> -->
        </select>
        <select class="form-control w-auto d-inline-block mr-2" #accessType name="access_type"
          (change)="accessTypeFilters('access_type',accessType.value)">
          <option selected="" disabled>Select Access Type</option>
          <option _ngcontent-cnw-c110="">Free</option>
          <option _ngcontent-cnw-c110="">Paid</option>
        </select>
        <select class="form-control w-auto d-inline-block" #status name="status"
          (change)="statusFilters('status',status.value)">
          <option selected="" disabled>Select Status</option>
          <option _ngcontent-cnw-c110="" value="new">New</option>
          <option _ngcontent-cnw-c110="" value="attempted">Attempted</option>
          <option _ngcontent-cnw-c110="" value="completed">Completed</option>
        </select>

        <span class="clfilter pl-2"> <a href="javascript:void(0)"
            *ngIf="typeFilter != '' || statusFilter != '' || accessTypeFilter != ''" (click)="clearAllfilters()"> Clear
            Filters</a>
        </span>

      </div>


      <div class="col-md-4 py-2 text-right">
        <div class="d-inline-block mr-2">
          <div class="input-group  ">
            <input type="text" class="form-control  " #search (change)="searchQuizzes(search.value)"
              style="padding-right:30px; border-radius:5px;" placeholder="Search My Quizz">
            <span class="input-group-text bg-white position-absolute "
              style="border:0; z-index:100;     top: 8px; right:5px; border-radius:0 .25rem .25rem 0;"><svg
                xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg></span>
          </div>
        </div>
        <!-- <select class="form-control w-auto d-inline-block">
          <option selected="">Select</option>
           <option _ngcontent-cnw-c110="" value="latest">Latest</option> 
        </select> -->
      </div>
    </div>


    <div class="card-container">
      <div class="row   ">
        <div class="col-md-6 col-lg-4 mb-4" *ngFor="let list of listQuizzes;let i = index">
          <div class="card">
            <div class="card-body">
              <h4 class="pb-2 h5 text-truncate"><strong>{{list.name?list.name:list.quiz_name}}</strong></h4>
              <p class="card-text pb-3 row" style="height: 30px;">

                <span class=" col-6  " *ngIf="list.category_name.length > 0;else other"><span
                    class="badge bg-info text-dark  text-truncate d-inline-block wm-100">{{ list.category_name?list.category_name.join(', '):'' }}</span></span>
                <ng-template #other>
                  <span class="badge col-6">&nbsp;</span>
                </ng-template>
                <span class=" col-6 text-right pull-right small-text"><svg xmlns="http://www.w3.org/2000/svg" width="14"
                    height="14" fill="currentColor" class="bi bi-clock mr-2" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>{{list.duration?list.duration.minutes:list.settings.duration.minutes}} min</span>
              </p>

              <a href="javascript:void(0)"
                class="card-link mt-3 d-inline-block">{{list.access_type?list.access_type:list.settings.access_type}}
              </a>

              <a routerLink="/attempt/{{ (this.statusFilter != '' && this.statusFilter != 'new')?list.quiz_id:list.id }}"
                class="btn btn-outline-warning pull-right btn-sm"
                *ngIf="(list.settings.access_type == 'Paid' && totalPaidQuizzes(this.statusFilter !=''?list.quiz_id:list.id) == true && totalCompleteQuizzes(this.statusFilter !=''?list.quiz_id:list.id) == false) || (list.settings.access_type == 'Free' && totalCompleteQuizzes(this.statusFilter !=''?list.quiz_id:list.id) == false)">
                Attempt</a>


              <a routerLink="/attempt/{{ (this.statusFilter != '' && this.statusFilter != 'new')?list.quiz_id:list.id }}"
                class="btn btn-outline-warning pull-right btn-sm"
                *ngIf="list.settings.access_type == 'Paid' && totalPaidQuizzes(this.statusFilter !=''?list.quiz_id:list.id) == false">
                <i class="fas fa-rupee-sign"></i> {{ list?.settings?.amount }} - BUY</a>


              <a routerLink="/attempt/{{ (this.statusFilter != '' && this.statusFilter != 'new')?list.quiz_id:list.id }}"
                class="btn btn-outline-warning pull-right btn-sm"
                *ngIf="totalCompleteQuizzes(this.statusFilter !=''?list.quiz_id:list.id)">Result</a>

            </div>
          </div>
        </div>
        <div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>
        <div class="col-md-6 col-lg-4 mb-4" *ngIf="listQuizzes.length == 0">

          <div class="card">
            <div class="card-body">
              No quizzes available!
            </div>
          </div>

        </div>
      </div>
      <div class="text-center py-3">
        <button type="button" class="btn btn-primary-outline btn-sm" (click)="loadMore()"
          *ngIf="disable_next == false">Load More</button>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="pills-quizz" role="tabpanel" aria-labelledby="pills-quizz-tab">2</div>
</div>