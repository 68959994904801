import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class CustomPipe implements PipeTransform {

  transform(val:string, params:string)  {

    let finalSummary = val.split(params);
    if(finalSummary.length > 1) {
      return finalSummary[0];
    }else{
      return finalSummary[0];
    }
  }

}