import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { EducationalDetailsComponent } from './educational-details/educational-details.component';
import { SkillsComponent } from './skills/skills.component';
import { InterestComponent } from './interest/interest.component';
import { FitnessComponent } from './fitness/fitness.component';
import { ManageProfileService } from './manage-profile.service';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule, } from '@angular/material/core';
import { MatInputModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CertificationsComponent } from './certifications/certifications.component';
import { SocialProfilesComponent } from './social-profiles/social-profiles.component';
import { LanguageComponent } from './language/language.component';
import { SharedComponent } from '../shared/shared.component';

const routes: Routes = [
  {
    path: 'manage-profile',
    children: [
      {
        path: 'personal',
        component: PersonalDetailsComponent
      },
      {
        path: 'educational',
        component: EducationalDetailsComponent
      },
      {
        path: 'interest',
        component: InterestComponent
      },
      {
        path: 'fitness',
        component: FitnessComponent
      },
      {
        path: 'skills',
        component: SkillsComponent
      },
      {
        path: 'social',
        component: SocialProfilesComponent
      },
      {
        path: 'certificate',
        component: CertificationsComponent
      },
      {
        path: 'language',
        component: LanguageComponent
      }
    ]
  }
];


@NgModule({
  declarations: [PersonalDetailsComponent, EducationalDetailsComponent, SkillsComponent, InterestComponent, FitnessComponent, SidebarComponent, CertificationsComponent, SocialProfilesComponent,LanguageComponent, SharedComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatInputModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    ImageCropperModule
  ],

  exports: [RouterModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatAutocompleteModule, SidebarComponent],
  providers: [ManageProfileService, PersonalDetailsComponent, SharedComponent]
})

export class ManageProfileModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
