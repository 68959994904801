import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-result-description',
  templateUrl: './result-description.component.html',
  styleUrls: ['./result-description.component.css']
})
export class ResultDescriptionComponent implements OnInit {

  @Input()
  correctAnswer: number;
  @Input()
  wrongAnswer: number;
  @Input()
  attempted: number;
  @Input()
  resultInPercent: number;
  @Input()
  notAttempted: number;
  @Input()
  totalQuizQuestions: any;
  @Input()
  quizName:any;
  @Input()
  userRole:string;
  
  minimumPercentage:number = 40;
  
  constructor() { }

  ngOnInit(): void {
  }

}
