import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
//import { DashboardComponent } from './dashboard/dashboard.component'
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { HomeComponent } from './home/home.component'
//Component } from './login/login.component'
import { SignupComponent } from './signup/signup.component'
import { AuthGuardService } from './auth-guard.service';
import { CourseComponent } from './course/course.component'
import { CoursesComponent } from './courses/courses.component'
import { CourseDetailsComponent } from './course-details/course-details.component'
import { CourseSuggestionsComponent } from './course-suggestions/course-suggestions.component'
import { CareerSuggestionsComponent } from './career-suggestions/career-suggestions.component'
import { CareersComponent } from './careers/careers.component'
import { CareerComponent } from './career/career.component'
import { CareerDetailsComponent } from './career-details/career-details.component'
import { BlogComponent } from './blog/blog.component';
import { HistoryComponent } from './history/history.component';
import { OrdersComponent } from './orders/orders.component';
import { QuizComponent } from './quiz/quiz.component';
import { AttemptComponent } from './attempt/attempt.component';
//import { LearningComponent  } from './learning/learning.component';
import { MyLearningComponent } from './my-learning/my-learning.component';
import { ResultDetailsComponent } from './result-details/result-details.component';
import { OrderReviewComponent } from './order-review/order-review.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'discipline/:name', component: CourseComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'coursedetails/:id', component: CourseDetailsComponent },
  { path: 'coursesuggestions', component: CourseSuggestionsComponent },
  { path: 'careersuggestions', component: CareerSuggestionsComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'career', component: CareerComponent },
  { path: 'careerdetails', component: CareerDetailsComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'quiz/:id', component: QuizComponent },
  { path: 'attempt/:id', component: AttemptComponent },
  { path: 'result/:id', component: ResultDetailsComponent },
  { path: 'my-learning', component: MyLearningComponent },
  { path: 'order-review/:name', component: OrderReviewComponent },
  { path: 'dashboard', component: NewDashboardComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
