import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import * as _ from 'lodash';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import {HeaderComponent} from '../header/header.component';

@Component({
  selector: 'app-my-learning',
  templateUrl: './my-learning.component.html',
  styleUrls: ['./my-learning.component.css']
})
export class MyLearningComponent implements OnInit {

  isLoading: boolean = false;
  listQuizzes = [];
  userInfo: any;
  categories_list = [];
  typeFilter = '';
  accessTypeFilter = '';
  statusFilter = '';
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  aspirantBasicInfo: any;
  userCompleteQuizzes = [];
  disable_next: boolean = false;
  allPaidQuizzes = [];
  finalQualification: any;

  @ViewChild("categories", { static: false }) categories: ElementRef;
  @ViewChild("accessType", { static: false }) accessType: ElementRef;
  @ViewChild("status", { static: false }) status: ElementRef;


  constructor(private _service: ManageProfileService, public afs: AngularFirestore,private _header:HeaderComponent) { }

  async ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.aspirantBasicInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
    this._header.getUserStatus(this.userInfo.uid);
    if (this.aspirantBasicInfo != null && Object.keys(this.aspirantBasicInfo['aspirant_education']).length > 0 && this.aspirantBasicInfo['aspirant_education']['AllEducation'].length > 0) {

      this.aspirantBasicInfo['aspirant_education']['AllEducation'].push('All');
      this.finalQualification = this.aspirantBasicInfo['aspirant_education']['AllEducation'];
      this.allQuizzes(this.finalQualification);
    } else {
      let AllEducation = ['All'];
      this.finalQualification = AllEducation;
      this.allQuizzes(AllEducation);
    }

    this.getQuizCategories();
  }


  totalPaidQuizzes(id) {
    let result = this.allPaidQuizzes.filter(function (cv) {
      return cv.quiz_id == id;
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  totalCompleteQuizzes(id) {
    let result = this.userCompleteQuizzes.filter(function (cv) {
      return cv.quiz_id == id && cv.quiz_complete == true
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  totalContinueQuizzes(id) {
    let result = this.userCompleteQuizzes.filter(function (cv) {
      return cv.quiz_id == id && cv.quiz_complete == false;
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  async allQuizzes(qualification) {

    try {
      this.isLoading = true;
      this.listQuizzes = [];
      this.allPaidQuizzes = await this._service.checkPaidQuizzes('paid_quizzes', '', this.userInfo.uid);
      this.userCompleteQuizzes = await this._service.getAspirantQuizzes('quiz_result', this.userInfo['uid']);

      this.afs.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('status','==','Published').where('settings.allowed_user','==','Aspirant').where('settings.eligibility', 'in', qualification)
        .limit(10)
        .orderBy('created_at', 'desc')
      ).snapshotChanges()
        .subscribe(response => {
          if (!response.length) {
            this.disable_next = true;
            this.isLoading = false;
            return false;
          }

          this.firstInResponse = response[0].payload.doc;
          this.lastInResponse = response[response.length - 1].payload.doc;

          if (response.length < 10) {
            this.disable_next = true;
            this.firstInResponse = response[0].payload.doc;
            this.lastInResponse = response[response.length - 1].payload.doc;

            this.tableData = [];
            for (let item of response) {
              let result = item.payload.doc.data();
              result['id'] = item.payload.doc.id;
              var index = this.listQuizzes.findIndex(x => x.id == item.payload.doc.id);
              if (index == -1) {
                this.listQuizzes.push(result);
              }
            }

            this.isLoading = false;
            return;
          }

          this.tableData = [];
          for (let item of response) {
            let result = item.payload.doc.data();
            result['id'] = item.payload.doc.id;

            var index = this.listQuizzes.findIndex(x => x.id == item.payload.doc.id);
            if (index == -1) {
              this.listQuizzes.push(result);
            }
          }
          this.isLoading = false;
        })

      this.disable_next = false;

    } catch (errorResponse) {
      console.log('error :', errorResponse);
      this.ngOnInit();
    }
  }

  async loadMore() {
    this.isLoading = true;
    this.afs.collection('quizzes', ref => ref.where('isDeleted', '==', false).where('status','==','Published').where('settings.allowed_user','==','Aspirant').where('settings.eligibility', 'in', this.finalQualification)
      .orderBy('created_at', 'desc')
      .limit(10)
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {
        if (!response.docs.length) {
          this.disable_next = true;
          this.isLoading = false;
          return;
        }

        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        if (response.docs.length < 10) {
          this.disable_next = true;
          this.firstInResponse = response.docs[0];

          this.lastInResponse = response.docs[response.docs.length - 1];
          this.tableData = [];
          for (let item of response.docs) {
            let result = item.data();
            result['id'] = item.id;
            var index = this.tableData.findIndex(x => x.id == item.id);
            if (index == -1) {
              this.tableData.push(result);
            }
          }

          const final_quizzes = [...this.listQuizzes, ...this.tableData];
          this.listQuizzes = final_quizzes;

          this.isLoading = false;
          return;
        }

        this.tableData = [];
        for (let item of response.docs) {
          let result = item.data();
          result['id'] = item.id;
          var index = this.tableData.findIndex(x => x.id == item.id);
          if (index == -1) {
            this.tableData.push(result);
          }
        }

        const final_quizzes = [...this.listQuizzes, ...this.tableData];
        this.listQuizzes = final_quizzes;
        this.disable_next = false;
        this.isLoading = false;
      })
  }

  async getQuizCategories() {
    let categories = await this._service.getAllCategories('quiz_category');
    if (categories.length > 0) {
      this.categories_list = categories;
    } else {
      this.categories_list = [];
    }
  }

  async categoryFilters(key, value) {
    this.isLoading = true;
    this.typeFilter = value;

    if (this.statusFilter == 'new') {
      let allQuizzes = await this._service.getAll('quizzes', this.typeFilter, '', '', this.userInfo['uid'], this.finalQualification);

      let userQuizzes = await this._service.getAspirantQuizzes('quiz_result', this.userInfo['uid']);

      let userPendingQuizzes = allQuizzes.filter(function (cv) {
        return !userQuizzes.find(function (e) {
          return e['quiz_id'] == cv.id;
        });
      })

      this.listQuizzes = userPendingQuizzes;
    }
    else if (this.statusFilter != '' && this.accessTypeFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', this.typeFilter, this.statusFilter, this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.statusFilter == '' && this.accessTypeFilter != '') {
      this.listQuizzes = await this._service.getAll('quizzes', this.typeFilter, '', this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.accessTypeFilter == '' && this.statusFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', this.typeFilter, this.statusFilter, '', this.userInfo['uid'], this.finalQualification);
    }

    else {

      this.listQuizzes = await this._service.getAll('quizzes', this.typeFilter, '', '', this.userInfo['uid'], this.finalQualification);
    }

    this.disable_next = true;
    this.isLoading = false;
  }

  async statusFilters(key, value) {
    this.isLoading = true;
    this.statusFilter = value;
    if (this.typeFilter != '' && this.accessTypeFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', this.typeFilter, this.statusFilter, this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.typeFilter == '' && this.accessTypeFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', '', this.statusFilter, this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.accessTypeFilter == '' && this.typeFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', this.typeFilter, this.statusFilter, '', this.userInfo['uid'], this.finalQualification);
    } else if (this.statusFilter == 'new' && this.typeFilter == '' && this.accessTypeFilter == '') {

      let allQuizzes = await this._service.getAll('quizzes', '', this.statusFilter, '', this.userInfo['uid'], this.finalQualification);

      let userQuizzes = await this._service.getAspirantQuizzes('quiz_result', this.userInfo['uid']);

      let userPendingQuizzes = allQuizzes.filter(function (cv) {
        return !userQuizzes.find(function (e) {
          return e['quiz_id'] == cv.id;
        });
      })

      this.listQuizzes = userPendingQuizzes;
    } else {

      this.listQuizzes = await this._service.getAll('quiz_result', '', this.statusFilter, '', this.userInfo['uid'], this.finalQualification);
    }

    this.disable_next = true;
    this.isLoading = false;
  }

  async accessTypeFilters(key, value) {
    this.isLoading = true;
    this.accessTypeFilter = value;
    if (this.statusFilter != '' && this.typeFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', this.typeFilter, this.statusFilter, this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.statusFilter == '' && this.typeFilter != '') {
      this.listQuizzes = await this._service.getAll('quizzes', this.typeFilter, '', this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else if (this.typeFilter == '' && this.statusFilter != '') {
      this.listQuizzes = await this._service.getAll('quiz_result', '', this.statusFilter, this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    } else {
      this.listQuizzes = await this._service.getAll('quizzes', '', '', this.accessTypeFilter, this.userInfo['uid'], this.finalQualification);
    }

    this.disable_next = true;
    this.isLoading = false;
  }

  async clearAllfilters() {
    this.status.nativeElement.value = 'Select Status';
    this.accessType.nativeElement.value = 'Select Access Type';
    this.categories.nativeElement.value = 'Select Category';
    this.typeFilter = '';
    this.accessTypeFilter = '';
    this.statusFilter = '';
    if (this.aspirantBasicInfo != null && Object.keys(this.aspirantBasicInfo['aspirant_education']).length > 0 && this.aspirantBasicInfo['aspirant_education']['AllEducation'].length > 0) {
      if (!this.aspirantBasicInfo['aspirant_education']['AllEducation'].includes('All')) {
        this.aspirantBasicInfo['aspirant_education']['AllEducation'].push('All');
      }
      this.allQuizzes(this.aspirantBasicInfo['aspirant_education']['AllEducation']);
    }
  }


  async searchQuizzes(value: string) {
    this.isLoading = true;
    if (value != '') {
      let finalResult;
      if (this.listQuizzes.length > 0) {
        finalResult = _.filter(this.listQuizzes, aspirant => (aspirant.name == value || aspirant.quiz_name == value));
        if (finalResult.length > 0) {
          this.listQuizzes = finalResult;
          this.isLoading = false;
        } else {
          this.listQuizzes = [];
          this.isLoading = false;
        }
        this.disable_next = true;
      } else {
        this.ngOnInit();
      }
    } else {
      this.ngOnInit();
    }
  }
}
