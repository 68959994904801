import { Injectable, NgZone } from '@angular/core';
//import { Observable } from 'rxjs';
//import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
//import { User } from 'firebase';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any;
  isLoading: boolean = true;

  constructor(private router: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore) {
    this.isLoading = true;
    this.afAuth.authState.subscribe(user => {
      if (!user) {
        this.isLoading = false;
        localStorage.setItem('userInfo', null);
      }
      //else {
        // let redirectLocation = "http://localhost:4200/home"; // Redirect destination
        // let redirectLocation = "http://aspirant-assessment.com.s3-website-us-east-1.amazonaws.com/home";

        // if (window.location.href !== redirectLocation) {
        //   window.location.replace('home');
        // }
        //this.router.navigate(['home']);
      //}
    })
  }

  // Returns true when user is looged in and email is verified && user.emailVerified !== false
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    return (user !== null) ? true : false;
  }
}
