import { Component, OnInit } from '@angular/core';
import {ManageProfileService} from '../manage-profile/manage-profile.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  userInfo:object;
  isLoading:boolean=false;
  payment_list: { id: string; }[];
  constructor(private _profileService: ManageProfileService,public router: Router) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(this.userInfo != null){
      this.getOrders();
    }else{
      this.router.navigate(['home']);
    }
  }

  async getOrders(){
    this.isLoading=true;
  try{
    let orders_list = await this._profileService.getUserTopOrders('payments',this.userInfo['uid']);
    if(orders_list.length > 0){
      this.payment_list = orders_list;   
    }else{
      this.payment_list = [];
    }
    this.isLoading=false;
  }catch(errorResponse){
    this.ngOnInit();
  }
}

  async searchOrders(orderid:string){
    this.isLoading=true;
    let list = await this._profileService.getOrderById('payments',this.userInfo['uid'],orderid);
    if(list.length > 0){
      this.payment_list = list;   
    }else{
      this.payment_list = [];
    }
    this.isLoading=false;
  }

}
