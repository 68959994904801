import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import { ManageProfileService } from '../manage-profile.service';
import { HeaderComponent } from '../../header/header.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Skills } from '../../models/skills.model'
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})

export class SkillsComponent implements OnInit {

  userInfo;
  aspirant_skills: FormGroup;
  inputSkills: object = {};
  isLoading: boolean = false;
  details;
  noSkills: boolean = false;
  detailsid;
  skills;
  show: boolean = false;
  selectedIndex;
  myClass = 'hide-class';
  uniqueSkillsId: string;
  cancelButton: boolean = false;
  numerOfExperience = [];
  submitted = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searchSkills = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.skills
        : this.skills.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }


  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private fb: FormBuilder, private afAuth: AngularFireAuth, private firestore: AngularFirestore) { }


  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
      this.getAllSkills();
      this.uniqueSkillsId = 'aspirant_skills_' + uuid();
      this.aspirant_skills = new FormGroup({
        Skills: new FormArray([
          this.initSkills(),
        ]),
      });
    } else {
      this.router.navigate(['home']);
    }

    let totalExperience = 12;
    for (let i = 1; i <= totalExperience; i++) {
      this.numerOfExperience.push(i);
    }
  }


  initSkills() {
    return new FormGroup({
      name: new FormControl(''),
      experience: new FormControl('', Validators.required),
      external_id: new FormControl(this.uniqueSkillsId)
    });
  }

  addSkills() {
    const control = <FormArray>this.aspirant_skills.get('Skills');
    control.push(this.initSkills());
    let index = control.length - 1;
    this.show = true;
    this.selectedIndex = index;
    this.myClass = 'hide-class';
  }

  getSkills(form) {
    return form.controls.Skills.controls;
  }

  removeSkills(i) {
    const control = <FormArray>this.aspirant_skills.get('Skills');
    control.removeAt(i);
  }

  async addDetails(form) {
    this.submitted = true;
    if (form.valid) {

      this.isLoading = true;
      let inputData: Skills = form.value;
      let getSkillDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (getSkillDetails != null) {

        getSkillDetails['aspirant_personal'] = getSkillDetails.aspirant_personal ? getSkillDetails.aspirant_personal : {};
        getSkillDetails['aspirant_education'] = getSkillDetails.aspirant_education ? getSkillDetails.aspirant_education : {};
        getSkillDetails['aspirant_skills'] = inputData;
        getSkillDetails['aspirant_fitness'] = getSkillDetails.aspirant_fitness ? getSkillDetails.aspirant_fitness : {};
        getSkillDetails['aspirant_interests'] = getSkillDetails.aspirant_interests ? getSkillDetails.aspirant_interests : {};
        getSkillDetails['aspirant_social_profile'] = getSkillDetails.aspirant_social_profile ? getSkillDetails.aspirant_social_profile : {};
        getSkillDetails['aspirant_certifications'] = getSkillDetails.aspirant_certifications ? getSkillDetails.aspirant_certifications : {};
        getSkillDetails['aspirant_language'] = getSkillDetails.aspirant_language ? getSkillDetails.aspirant_language : {};


      } else {

        getSkillDetails = {};
        getSkillDetails['aspirant_personal'] = {};
        getSkillDetails['aspirant_education'] = {};
        getSkillDetails['aspirant_skills'] = inputData;
        getSkillDetails['aspirant_fitness'] = {};
        getSkillDetails['aspirant_interests'] = {};
        getSkillDetails['aspirant_social_profile'] = {};
        getSkillDetails['aspirant_certifications'] = {};
        getSkillDetails['aspirant_language'] = {};

      }


      getSkillDetails['created_by'] = this.userInfo['uid'];
      getSkillDetails['modified_by'] = this.userInfo['uid'];
      getSkillDetails['created_date_time'] = new Date();
      getSkillDetails['modified_date_time'] = new Date();
      getSkillDetails['operation'] = 'created';
      getSkillDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;


      let result = await this._profileService.addSkillsDetails('aspirants', getSkillDetails);
      let data = await this._header.getDetails();
      this.submitted = false;
      if (data != undefined) {
        this.getDetails();
      }
      this.isLoading = false;
    }
  }



  getDetails() {

    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        //let result = await this._profileService.getSkillsDetails('aspirant_skills', user.uid);
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_skills != undefined && Object.keys(result.aspirant_skills).length != 0) {
          this.details = result.aspirant_skills.Skills;
          this.detailsid = result.id;
          this.noSkills = false;
          this.aspirant_skills.setControl('Skills', this.setExistingSkills(this.details))
          this.show = false;
          this.cancelButton = true;
          this.isLoading = false;
        } else {
          this.detailsid = (result == null) ? '' : result.id;
          this.noSkills = true;
          this.show = true;
          this.selectedIndex = 0
          this.cancelButton = false;
          this.myClass = 'hide-class';
        }
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });

  }


  setExistingSkills(skillsSet) {
    let formarray = new FormArray([]);
    skillsSet.forEach(s => {
      formarray.push(this.fb.group({
        experience: s.experience,
        name: s.name,
        external_id: s.external_id
      }))
    })
    return formarray;
  }



  async updateDetails(form) {
    this.submitted = true;
    if (form.valid) {

      this.isLoading = true;
      let inputData: Skills = form.value;
      let getSkillDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getSkillDetails['aspirant_personal'] = getSkillDetails.aspirant_personal ? getSkillDetails.aspirant_personal : {};
      getSkillDetails['aspirant_education'] = getSkillDetails.aspirant_education ? getSkillDetails.aspirant_education : {};
      getSkillDetails['aspirant_skills'] = inputData;
      getSkillDetails['aspirant_fitness'] = getSkillDetails.aspirant_fitness ? getSkillDetails.aspirant_fitness : {};
      getSkillDetails['aspirant_interests'] = getSkillDetails.aspirant_interests ? getSkillDetails.aspirant_interests : {};
      getSkillDetails['aspirant_social_profile'] = getSkillDetails.aspirant_social_profile ? getSkillDetails.aspirant_social_profile : {};
      getSkillDetails['aspirant_certifications'] = getSkillDetails.aspirant_certifications ? getSkillDetails.aspirant_certifications : {};
      getSkillDetails['aspirant_language'] = getSkillDetails.aspirant_language ? getSkillDetails.aspirant_language : {};


      getSkillDetails['created_by'] = this.userInfo['uid'];
      getSkillDetails['modified_by'] = this.userInfo['uid'];
      getSkillDetails['created_date_time'] = new Date();
      getSkillDetails['modified_date_time'] = new Date();
      getSkillDetails['operation'] = 'created';
      getSkillDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.updateSkillsDetails('aspirants', this.detailsid, getSkillDetails);

      delete getSkillDetails['userId'];
      delete getSkillDetails['created_by'];
      delete getSkillDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getSkillDetails));
      this.submitted = false;
      this.ngOnInit();
      this.isLoading = false;
    }
  }


  async getAllSkills() {
    let result = await this._profileService.getSkillList('skills');
    this.skills = result[0]['name'];
  }

  textOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  cancel() {
    this.getDetails();
    this.myClass = 'hide-class';
    this.show = false;
    this.cancelButton = true;
  }

  showForm(index) {
    this.selectedIndex = index;
    this.show = true;
  }

  resetForm() {
    this.getDetails();
    this.show = false;
  }

}
