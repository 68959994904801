import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { HeaderComponent } from '../header/header.component';
import { NgForm } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, first, } from 'rxjs/operators';
import { Educational } from '../models/educational.model';
import { Skills } from '../models/skills.model';
import { Interests } from '../models/interests.model'
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
//import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { PaymentComponent } from '../payment/payment.component';

declare var $: any;
declare var Razorpay: any;

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit {


  userInfo;
  eduDetails: object = {};
  isLoading: boolean = false;
  msg: boolean = false;
  skills;
  interest;
  detailsid: string;
  qualifications;
  aspirantAllQualification = [];
  suggested_courses = [];
  suggested_exams = [];
  selectedExam;
  uniqueExtrnalId: string;
  courseConfig: any;
  examConfig: any;
  totalCourseRecords: number = 0;
  totalExamRecords: number = 0;
  filtered_suggested_exams = [];
  selectedIndex;
  wait: boolean = false;
  name: string;
  payableAmount: number;
  paymentSuccess: boolean = false;
  paymentFailed: boolean = false;
  detailsToSavedInDB = {};
  pendingQuizzes = [];
  userDetails: any;
  personalDetails: any;
  allQuizzes = [];
  employeeQuizzes = [];
  paymentCheck: boolean;


  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searchInterest = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.interest
        : this.interest.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  searchSkills = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.skills
        : this.skills.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }



  constructor(private location: LocationStrategy, private _profileService: ManageProfileService, private _header: HeaderComponent, public router: Router, private afAuth: AngularFireAuth, private firestore: AngularFirestore) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isLoading = true;
    if (this.userInfo != null) {
      this.name = this.userInfo['displayName'];
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
      $('.modal-backdrop').remove();
      $('.modal-open').css('overflow', 'visible');

      this.courseConfig = {
        id: 'pagination2',
        itemsPerPage: 3,
        currentPage: 1,
        totalItems: 0
      };

      this.examConfig = {
        id: 'pagination1',
        itemsPerPage: 4,
        currentPage: 1,
        totalItems: 0
      };

      this._header.getUserStatus(this.userInfo.uid);
    } else {
      this.router.navigate(['home']);
    }
  }



  async ngOnInit() {
    this.uniqueExtrnalId = 'education_' + uuid();
    $(document).ready(() => {
      var current_fs, next_fs, previous_fs;
      var left, opacity, scale;
      var animating;
      $(".next").click(function () {
        if (animating) return false;
        animating = true;
        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            scale = 1 - (1 - now) * 0.2;
            left = now * 50 + "%";
            opacity = 1 - now;
            current_fs.css({
              'transform': 'scale(' + scale + ')',
              'position': 'absolute'
            });
            next_fs.css({ 'left': left, 'opacity': opacity });
          },
          duration: 800,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          easing: 'easeInOutBack'
        });
      });

      $(".previous").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        previous_fs.show();
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            scale = 0.8 + (1 - now) * 0.2;
            left = (1 - now) * 50 + "%";
            opacity = 1 - now;
            current_fs.css({ 'left': left });
            previous_fs.css({ 'transform': 'scale(' + scale + ')', 'opacity': opacity });
          },
          duration: 800,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          //this comes from the custom easing plugin
          easing: 'easeInOutBack'
        });

      });
    })


    this.paymentCheck = await this.checkAmount();
    this.payableAmount = await this._header.getCounsellingAmount();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getEducationStatus();

    this._profileService.refreshCourseNeeded$.subscribe(() => {
      this.getEducationStatus();
    })

    //while load page
    this._profileService.refreshGetNeeded$.subscribe(() => {
      this.getPersonalDetails();
    });

  }

  async checkAmount() {
    try {
      let paymentExist = await this._profileService.getPaymentStatus('payments', this.userInfo.uid, '');
      if (paymentExist.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.ngOnInit();
    }
  }

  async getPersonalDetails() {
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result['aspirant_personal'] != undefined && Object.keys(result['aspirant_personal']).length != 0) {
          this.personalDetails = result['aspirant_personal'];
          this.userDetails['first_name'] = this.personalDetails['first_name'];
          this.userDetails['last_name'] = this.personalDetails['last_name'];
        }
      } else {
        this._header.signOut();
      }
    });
  }

  coursePageChanged(event) {
    this.courseConfig.currentPage = event;
  }


  examPageChanged(event) {
    this.examConfig.currentPage = event;
  }


  async totalQualification() {
    this.qualifications = await this._profileService.getQualificationList('educational_qualification');
  }


  async getEducationStatus() {

    this.isLoading = true;
    this.wait = true;

    await this.afAuth.onAuthStateChanged(async (user) => {

      if (user != null) {

        let result = await this._header.getDetails();
        if (result != undefined && result['aspirant_education'] != undefined && result['aspirant_education']['Education'] != undefined) {

          this.detailsid = result.id;
          // let coursedata = await this._header.allSuggestedCourses();  //suggest courses to aspirant based on cuurentHighestQualifications//
          // if (coursedata.length > 0) {
          //   this.suggested_courses = coursedata;
          //   this.courseConfig.totalItems = coursedata.length;
          // } else {
          //   this.suggested_courses = [];
          //   this.courseConfig.totalItems = 0;
          // }

          // let examdata = await this._header.allSuggestedExams();  //suggest exams to aspirant based on cuurentHighestQualifications//

          // if (examdata.length > 0) {
          //   this.suggested_exams = examdata;
          //   this.selectedExamFilter(examdata[0].job_sector, 0);
          // } else {
          //   this.suggested_exams = [];
          // }
          this.wait = false;
          //this.isLoading = false;
        } else {

          //$("#IntroModal").modal('show');
          this.totalQualification();
          this.getAllSkills();
          this.getAllInterest();
          this.detailsid = (result == undefined) ? '' : result.id;
          // this.isLoading = false;
          this.wait = false;
        }
        if (this.userDetails.role == 'Employee') {
          this.assignedEmployeeQuizzesList();
        } else {
          this.allPendingQuizzes();
        }
      } else {
        this._header.signOut();
      }
    })
  }


  async selectedExamFilter(selected_exam: string, index) {

    this.examConfig.currentPage = 1;
    this.selectedIndex = index;
    this.selectedExam = selected_exam;
    let examInfo = JSON.parse(sessionStorage.getItem('exam_suggestions'));
    let examResult = examInfo.filter(o => o.job_sector === selected_exam);
    this.filtered_suggested_exams = examResult;
    this.examConfig.totalItems = examResult.length;
  }



  async addDetails(form: NgForm) {

    this.isLoading = true;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let enteredQualification = form.value['highest_qualification'];
    let skills = form.value['skills'];
    let interest = form.value['interested_carrer'];

    let userQualification: Educational = {
      Education: [{
        highest_qualification: enteredQualification,
        course: '',
        university: '',
        course_type: '',
        year_of_passing: '',
        medium_of_study: '',
        percentage: '',
        external_education_id: this.uniqueExtrnalId,
        subjects: [
          {
            name: '',
            marks: '',
            external_id: this.uniqueExtrnalId,
            education_subject_id: uuid()
          }
        ],
      }],
      HighestQualification: enteredQualification,
      AllEducation: [enteredQualification],
    }

    if (enteredQualification != '') {

      let geteducationalDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (geteducationalDetails != null) {

        geteducationalDetails['aspirant_personal'] = geteducationalDetails.aspirant_personal ? geteducationalDetails.aspirant_personal : {};
        geteducationalDetails['aspirant_education'] = userQualification;
        geteducationalDetails['aspirant_skills'] = geteducationalDetails.aspirant_skills ? geteducationalDetails.aspirant_skills : {};
        geteducationalDetails['aspirant_fitness'] = geteducationalDetails.aspirant_fitness ? geteducationalDetails.aspirant_fitness : {};
        geteducationalDetails['aspirant_interests'] = geteducationalDetails.aspirant_interests ? geteducationalDetails.aspirant_interests : {};

      } else {

        geteducationalDetails = {};
        geteducationalDetails['aspirant_personal'] = {};
        geteducationalDetails['aspirant_education'] = userQualification;
        geteducationalDetails['aspirant_skills'] = {};
        geteducationalDetails['aspirant_fitness'] = {};
        geteducationalDetails['aspirant_interests'] = {};
      }


      geteducationalDetails['created_by'] = this.userInfo['uid'];
      geteducationalDetails['modified_by'] = this.userInfo['uid'];
      geteducationalDetails['created_date_time'] = new Date();
      geteducationalDetails['modified_date_time'] = new Date();
      geteducationalDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      if (this.detailsid != '') {
        let educationResult = await this._profileService.updateEducationalDetails('aspirants', this.detailsid, geteducationalDetails);

      } else {
        let educationResult = await this._profileService.addEducationalDetails('aspirants', geteducationalDetails);
      }
      let aspirantEducationData = await this._header.getDetails();

      if (aspirantEducationData != undefined) {
        if (skills != '' || interest != '') {
          this.addSkills(skills, interest, aspirantEducationData)
        }
      }
      this.getEducationStatus();
      $("#IntroModal").modal('hide');
      this.isLoading = false;
    } else {
      if (skills != '' || interest != '') {
        this.addSkills(skills, interest, {})
      }

      $("#IntroModal").modal('hide');
      this.isLoading = false;
    }
  }

  async addSkills(skillDetails, interestDetails, updatedData) {

    if (skillDetails != '') {
      let userSkills: Skills = {
        Skills: [{
          name: skillDetails,
          experience: '',
          external_id: 'aspirant_skills_' + uuid()
        }],
      }

      if (Object.keys(updatedData).length != 0) {

        updatedData['aspirant_skills'] = userSkills;
        // updatedData['created_by'] = this.userInfo['uid'];
        // updatedData['modified_by'] = this.userInfo['uid'];
        // updatedData['operation'] = 'created';
        // updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

        this.detailsid = updatedData.id;

      } else {

        //console.log('sab kahali hai skills me');
        updatedData['aspirant_personal'] = {};
        updatedData['aspirant_education'] = {};
        updatedData['aspirant_skills'] = userSkills;
        updatedData['aspirant_fitness'] = {};
        updatedData['aspirant_interests'] = {};
      }

      updatedData['created_by'] = this.userInfo['uid'];
      updatedData['modified_by'] = this.userInfo['uid'];
      updatedData['created_date_time'] = new Date();
      updatedData['modified_date_time'] = new Date();
      updatedData['operation'] = 'created';
      updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      this.isLoading = true;

      if (this.detailsid != '') {
        let skillResult = await this._profileService.updateSkillsDetails('aspirants', this.detailsid, updatedData);
      } else {
        let skillResult = await this._profileService.addSkillsDetails('aspirants', updatedData);
      }

      let aspirantSkillsData = await this._header.getDetails();

      if (aspirantSkillsData != undefined) {
        if (interestDetails != '') {
          this.addInterest(interestDetails, aspirantSkillsData);
        }
      }
      this.msg = false;
      //this.isLoading = false;
      $("#IntroModal").modal('hide');
    } else {
      if (interestDetails != '') {
        this.addInterest(interestDetails, updatedData);
      }
    }
  }

  async addInterest(interestDetails, updatedData) {

    if (interestDetails != '') {

      let userInterest: Interests = {
        interested_carrer: interestDetails,
        job_type: '',
        department: '',
        career_type: '',
        desired_location: '',
        external_id: 'aspirant_interest_' + uuid()
      }

      if (Object.keys(updatedData).length != 0) {

        //console.log('yaha aa gaya');
        updatedData['aspirant_interests'] = userInterest;
        //updatedData['created_by'] = this.userInfo['uid'];
        //updatedData['modified_by'] = this.userInfo['uid'];
        //updatedData['operation'] = 'created';
        //updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;
        this.detailsid = updatedData.id;

      } else {

        // console.log('sab kahali hai inreste me');
        updatedData['aspirant_personal'] = {};
        updatedData['aspirant_education'] = {};
        updatedData['aspirant_skills'] = {};
        updatedData['aspirant_fitness'] = {};
        updatedData['aspirant_interests'] = userInterest;
      }


      updatedData['created_by'] = this.userInfo['uid'];
      updatedData['modified_by'] = this.userInfo['uid'];
      updatedData['created_date_time'] = new Date();
      updatedData['modified_date_time'] = new Date();
      updatedData['operation'] = 'created';
      updatedData['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      if (this.detailsid != '') {
        // console.log('update hua interest');
        let interesrResult = await this._profileService.updateInterestDetails('aspirants', this.detailsid, updatedData);
      } else {
        // console.log('insert hua interest');
        let interesrResult = await this._profileService.addInterestDetails('aspirants', updatedData);
      }

      this._header.getDetails();
      $("#IntroModal").modal('hide');
      this.isLoading = false;
    }
  }


  getAllSkills() {
    this.isLoading = true;
    this.afAuth.currentUser.then(async user => {
      if (user != null) {
        let result = await this._profileService.getSkillList('skills');
        if (result.length > 0) {
          this.skills = result[0]['name']
          this.isLoading = false;
        }
      }
    });
  }

  getAllInterest() {
    this.isLoading = true;
    this.afAuth.currentUser.then(async user => {
      if (user != null) {
        let result = await this._profileService.getInterestList('interests');
        if (result.length > 0) {
          this.interest = result[0]['name'];
        }
        this.isLoading = false;
      }
    });
  }


  async allPendingQuizzes() {
    try {
      this.pendingQuizzes = [];
      let aspirantBasicInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
      if (aspirantBasicInfo != null && Object.keys(aspirantBasicInfo['aspirant_education']).length > 0 && aspirantBasicInfo['aspirant_education']['AllEducation'].length > 0) {
        aspirantBasicInfo['aspirant_education']['AllEducation'].push('All');
        this.allQuizzes = await this._profileService.getAllQuizzes('quizzes', aspirantBasicInfo['aspirant_education']['AllEducation']);
      } else {
        let AllEducation = ['All'];
        this.allQuizzes = await this._profileService.getAllQuizzes('quizzes', AllEducation);
      }

      let userQuizzes = await this._profileService.getAspirantQuizzes('quiz_result', this.userInfo['uid']);

      let userPendingQuizzes = this.allQuizzes.filter(function (cv) {
        return !userQuizzes.find(function (e) {
          return e['quiz_id'] == cv.id;
        });
      })

      var finalQuizzes = [...userPendingQuizzes, ...userQuizzes];
      this.pendingQuizzes = finalQuizzes;
      this.isLoading = false;
    } catch (errorResponse) {
      this.isLoading = false;
    }
  }


  async assignedEmployeeQuizzesList() {
    try {
      let allEmployeeQuiz = [];
      this.employeeQuizzes = await this._profileService.getAssignedQuizes('users', this.userInfo.uid, 'assigned_quizzes');
      if (this.employeeQuizzes.length > 0) {
        let quizzes = this.employeeQuizzes[0];

        let allQuizzes = quizzes.quiz_id;

        if (allQuizzes.length > 10) {

          let finalArray = await this.chunk(allQuizzes, 10);

          for (let i = 0; i < finalArray.length; i++) {

            let question_bank = await this._profileService.getAllEmployeesQuizzes('quizzes', finalArray[i]);
            allEmployeeQuiz.push(question_bank);
          }

          let finalList = await allEmployeeQuiz.reduce(async function (arr, e) {
            return arr.concat(e);
          })

          this.allQuizzes = finalList;

        } else {
          let question_bank = await this._profileService.getAllEmployeesQuizzes('quizzes', allQuizzes);
          this.allQuizzes = question_bank;
        }

        let empQuizzes = await this._profileService.getCompletedQuizes('quiz_result', this.userInfo['uid']);
        let empPendingQuizzes = this.allQuizzes.filter(function (cv) {
          return !empQuizzes.find(function (e) {
            return e['quiz_id'] == cv.id;
          });
        })
        this.pendingQuizzes = empPendingQuizzes;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log('error ', error);
    }
  }


  async chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  }
}
