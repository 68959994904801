<div class="page-heading row">
    <div class="heading col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
            </ol>
        </nav>
        <h2>Manage Profile</h2>
    </div>
</div>


<div class="row">
    <!-- Aside container -->

    <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

    <!-- Aside container end-->

    <!-- Profile container -->
    <div class="col-md-9 col-lg-9">

        <div class="filter-tab ">
            <ul class="list-unstyled list-inline">
                <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
                <li class="list-inline-item active"><a routerLink="/manage-profile/skills">Skill</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
            </ul>
        </div>

        <div class="data-list p-3 mb-3 ">
            <div class="row " *ngIf="show==false">
                <div class="col-md-12">
                    <div class="pull-right"><a class="btn btn-primary-outline m-0 " (click)="addSkills()"
                            href="javascript:void(0)">+ Add
                            Skill</a>
                    </div>
                    <p class="form-msg">minimum 3 skills needed</p>
                </div>
            </div>

            <div class="accordion mt-3" id="accordionskill" *ngIf="show==false">

                <div class="card bg-form mb-3" *ngFor="let skillset of details;let k=index">
                    <div class="cardHeader ">
                        <div class="row">

                            <div class="col-md-12">
                                <div class="pull-right"><a href="javascript:void(0)" (click)="showForm(k)"
                                        class="btn btn-secondary-outline btn-small mr-2">Edit</a>
                                    <!-- <a href="javascript:void(0)" 
                                    class="btn btn-secondary-outline btn-small mr-2">Delete</a> -->
                                </div>
                                <div class="profile-details">
                                    <h3 class="mb-0"> {{skillset.name}} </h3>
                                    <ul>
                                        <li>{{skillset.experience}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row" *ngIf="noSkills == true" class="default-message">No Skills set! </div> -->



                <!-- <div class="row">
                            <div class="col-md-6">
                                <div class="form-group"><select class="form-control">
                                        <option>Balance Sheet</option>
                                    </select></div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group"><select class="form-control">
                                        <option>2 Years</option>
                                    </select></div>
                            </div>

                        </div> -->
            </div>


            <form [formGroup]="aspirant_skills"
                (ngSubmit)="detailsid ? updateDetails(aspirant_skills) : !detailsid ? addDetails(aspirant_skills):addDetails(aspirant_skills)"
                ngNativeValidate>


                <div formArrayName="Skills" *ngIf="show==true">

                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div class=" panel-default">

                            <div id="collapseOne" class="panel-collapse " role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">

                                    <div [formGroupName]="i" id="skillid"
                                        *ngFor="let Skills of getSkills(aspirant_skills); let i = index"
                                        [ngClass]="selectedIndex!=i?myClass:''">


                                        <div class="row">
                                            <div class="col-md-8"><label>Skills<span class="required">*</span></label>
                                                <div class="form-group mb-1">

                                                    <input type="text" class="form-control" formControlName="name"
                                                        (keypress)="textOnly($event)" placeholder="skills"
                                                        [ngbTypeahead]="searchSkills" #instance="ngbTypeahead"
                                                        required />
                                                </div>
                                            </div>
                                            <div class="col-md-4"><label>Experience<span
                                                        class="required">*</span></label>
                                                <div class="form-group mb-1"> <select class="form-control"
                                                        formControlName="experience">
                                                        <option value="">Please select</option>
                                                        <option value="3 months">3 Months</option>
                                                        <option value="6 months">6 Months</option>
                                                        <option *ngFor="let list of numerOfExperience"
                                                            value="{{list}} years">{{list}} Years</option>
                                                    </select>
                                                    <div *ngIf="submitted && Skills.get('experience').errors"
                                                        style="color:red">
                                                        <small *ngIf="Skills.get('experience').errors.required">This
                                                            is required</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="hidden" formControlName="external_id">
                                        </div>

                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-12 text-right">
                                            <button class="btn btn-secondary-outline" *ngIf="cancelButton == true"
                                                (click)="cancel()">Cancel</button>
                                            <!-- <button class="btn btn-secondary-outline" (click)="resetForm()">Reset</button> -->
                                            <button class="btn btn-primary" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>