<div class="page-heading row">
    <div class="heading col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
            </ol>
        </nav>
        <h2>Manage Profile</h2>
    </div>
</div>


<div class="row">
    <!-- Aside container -->

    <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

    <!-- Aside container end-->

    <!-- Profile container -->
    <div class="col-md-9 col-lg-9">

        <div class="filter-tab ">
            <ul class="list-unstyled list-inline">
                <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
                <li class="list-inline-item "><a routerLink="/manage-profile/skills">Skill</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
                <li class="list-inline-item active"><a routerLink="/manage-profile/language">Language</a></li>
            </ul>
        </div>

        <div class="data-list p-3 mb-3 ">
            <div class="row " *ngIf="show==false">
                <div class="col-md-12">
                    <div class="pull-right"><a class="btn btn-primary-outline m-0 " (click)="addLanguage()"
                            href="javascript:void(0)">+ Add
                            Language</a>
                    </div>
                    <h5 class="font-weight-bold">Languages</h5>
                    <!-- <p class="form-msg">Add links to your social profiles (e.g. Linkedin Profile,Facebook Profile etc)
                    </p> -->
                </div>
            </div>

            <div class="accordion mt-3" id="accordionskill" *ngIf="show==false">

                <div class="card bg-form mb-3">
                    <div class="cardHeader ">

                        <table class="table  table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Langauge</th>
                                    <th scope="col">Proficiency</th>
                                    <th scope="col">Read</th>
                                    <th scope="col">Write</th>
                                    <th scope="col">Speak</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let langugae_set of details?.Language;let k=index">
                                    <td><span class="h6 fw-bold">{{langugae_set.language}}</span> </td>
                                    <td>{{langugae_set.proficiency}}</td>
                                    <td *ngIf="langugae_set.ability.indexOf('Read') > -1;else otherRead"><i
                                            class="fa fa-check" aria-hidden="true"></i> </td>
                                    <ng-template #otherRead>
                                        <td></td>
                                    </ng-template>
                                    <td *ngIf="langugae_set.ability.indexOf('Write') > -1; else otherWrite"> <i
                                            class="fa fa-check" aria-hidden="true"></i></td>
                                    <ng-template #otherWrite>
                                        <td></td>
                                    </ng-template>
                                    <td *ngIf="langugae_set.ability.indexOf('Speak') > -1; else otherSpeak"> <i
                                            class="fa fa-check" aria-hidden="true"></i></td>
                                    <ng-template #otherSpeak>
                                        <td></td>
                                    </ng-template>
                                    <td>
                                        <a href="javascript:void(0)" (click)="showForm(k)"
                                            class="btn btn-secondary-outline btn-small mr-2">Edit</a>
                                        <a href="javascript:void(0)" (click)="removeForm(k)"
                                            class="btn btn-secondary-outline btn-small mr-2">Delete</a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <form #personal="ngForm"
                (ngSubmit)="detailsid ? updateDetails(personal) : !detailsid ? addDetails(personal):addDetails(personal)"
                ngNativeValidate>


                <div *ngIf="show==true">

                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div class=" panel-default">

                            <div id="collapseOne" class="panel-collapse " role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-md-6"><label>Language<span class="required">*</span></label>
                                                <div class="form-group mb-1">

                                                    <input type="text" class="form-control" name="language"
                                                        [ngModel]="language" (keypress)="textOnly($event)"
                                                        placeholder="Enter Language" [ngbTypeahead]="searchLanguage"
                                                        #instance="ngbTypeahead" required />
                                                </div>
                                            </div>
                                            <div class="col-md-6"><label>Proficiency<span
                                                        class="required">*</span></label>
                                                <div class="form-group mb-1"> <select class="form-control"
                                                        name="proficiency" [(ngModel)]="proficiency" required>
                                                        <option value="">Select proficiency</option>
                                                        <option value="Beginner">Beginner</option>
                                                        <option value="Intermediate">Intermediate</option>
                                                        <option value="Expert">Expert</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row pt-2 mx-0">
                                                    <div class="form-check col-md-4"
                                                        *ngFor="let items of abilityList; let j = index">

                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckChecked" checked name="{{ability}}" id="{{j}}"
                                                            (change)="onCheck(items, $event.target.checked)"
                                                            [checked]="checked(items)" [required]="required" />
                                                        <label for="{{j}}" class="form-check-label">{{items}}</label>

                                                    </div>
                                                </div>




                                            </div>

                                            <input type="hidden" name="external_id">
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-12 text-right">
                                            <button class="btn btn-secondary-outline" *ngIf="cancelButton == true"
                                                (click)="cancel()">Cancel</button>
                                            <button class="btn btn-primary" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- <pre>
    {{aspirant_language.value | json}}
</pre> -->

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>