<!-- Breadcrumb -->
<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Career Suggestions</li>
      </ol>
    </nav>
    <h2>Career Suggestions</h2>
  </div>
</div>


<!-- container -->
<div class="row">
  <div class="col-md-9">
    <!-- filter -->
    <div class="row">
      <div class="col-md-7">
        <p class="pb-0 pt-3 font-weight-bold ">{{suggested_exams.length}} <span class="text-muted">Jobs</span></p>
      </div>
      <div class="col-md-3 ">
        <div class="form-group">
          <select class="form-control">
            <option selected="" (click)="selectedExamFilter('')">All Career</option>
            <option *ngFor="let job_sector of filteredSector | filterUnique;let i=index" (click)="selectedExamFilter(job_sector)"
              value="{{job_sector}}">{{job_sector}}</option>

          </select>
        </div>
      </div>
      <div class="col-md-2 pl-0">
        <div class="form-group"><a href="javascript:void(0)" routerLink="/careers"
            class="btn btn-secondary-outline pl-3 pr-3 pt-2 pb-2 m-0 w-100 text-left">All Careers
            <i class="fa fa-angle-right pull-right pt-small" aria-hidden="true"></i></a></div>
      </div>
    </div>
    <!-- filter end -->
    <div class="data-list ">
      <!-- <pre>{{suggested_exams | json}}</pre> -->
      <ul class="list-unstyled list-mob  mb-0  courses-list  " *ngFor="let examlist of suggested_exams  | paginate: config">
        <li class="media row   p-4 border-bottom">
          <div class="col-md-9">
            <a href="javascript:void(0)" class="p-0 row" routerLink="/careerdetails">

              <div class="col-md-4 col-lg-5"><img class="img-fluid" src="assets/img/careers/defence.png "
                  style="height: 110px;"></div>
              <div class="media-body col-md-8 col-lg-7 ">
                <h5 class="mt-0 mb-2 font-weight-bold">{{examlist?.exam_name}}</h5>
                <span class="badge badge-pill badge-light badge-outline pt-3 pb-4 pr-3 pl-3 mr-2 mb-2">
                  {{examlist?.job_department}} </span>
                <p class="pt-2">{{examlist?.exam_body}} </p>
              </div>
            </a>
          </div>
          <div class="col-md-3">
            <div class="btn-group  action-holder">
              <a class="dropdown-item">Info</a>
              <a class="dropdown-item">Basic Highlights</a>
              <a class="dropdown-item">Qualifications</a>
              <a class="dropdown-item">Others</a>
            </div>


          </div>
        </li>
      </ul>

      <ul class="list-unstyled list-mob  mb-0  courses-list  " *ngIf="suggested_exams.length == 0">
        <li class="media row   p-4 border-bottom">
          <div class="col-md-9">
            No careers suggestions found!
          </div>
        </li>
      </ul>


      <nav aria-label="Page navigation example" class="p-4">
        <ul class="pagination justify-content-end mb-0 mt-2">
          <!-- <li class="page-item disabled page-prev">
            <a class="page-link" href="#" tabindex="-1">Previous</a>
          </li>
          <li class="page-item page-next">
            <a class="page-link" href="#">Next</a>
          </li> -->
          <pagination-controls  [autoHide]="true" (pageChange)="pageChanged($event)"></pagination-controls>
        </ul>
      </nav>
    </div>



    <!-- Enquiry & Support -->
    <div class="row">
      <div class="col-md-12">
        <div class="data-list">
          <div class="row d-flex ">
            <div class="col-12 col-md-6 ">
              <div class="p-5">
                <h4>Enquiry</h4>
                <div class="row">
                  <div class="col-md-12">
                    <label>Title</label>
                    <div class="form-group"><input type="text" required="" class="form-control "></div>
                  </div>
                  <div class="col-md-12">
                    <label>Message</label>
                    <div class="form-group"><textarea required="" class="form-control " cols="5" rows="5"></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary">Submit Message</button>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-12 col-md-6 bg-light-blue d-flex align-items-center justify-content-center">
              <div class="  p-3 text-center  ">
                <img src="assets/img/icon-support.png">
                <h4>Support</h4>
                <p class="pt-1">We provide information about colleges, <br />
                  career guidance and many more...</p>
                <p><a class="btn-primary-outline btn ">UPGRADE PLAN</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Enquiry & Support end-->

  </div>

  <div class="col-md-3">
    <!-- aside container -->
    <div class="mt-5 pt-4 aside">
      <!-- top careers -->
      <div id="top-careers">
        <h4 class="mb-2">Top Careers</h4>
        <ul class="list-unstyled list-mob  mb-0  ">
          <li class="media row  ">
            <div class="col-md-12">
              <a href="#" class=" row p-0 " routerLink="/careerdetails">
                <div class="col-md-4 col-lg-5"><img class="img-fluid  " src="assets/img/courses/thumb-course-1.png">
                </div>
                <div class="media-body col-md-8 col-lg-7 ">
                  <h5 class="mt-0 mb-1 font-weight-bold">CA</h5>
                  <span class="badge badge-pill badge-light badge-outline pt-2 pb-2 pr-3 pl-3 mr-2 mb-2">Finance</span>
                </div>
              </a>
            </div>

          </li>
          <li class="media row">
            <div class="col-md-12">
              <a href="#" class="row p-0" routerLink="/coursedetails">
                <div class="col-md-4 col-lg-5"><img class="img-fluid" src="assets/img/courses/thumb-course-2.png"></div>
                <div class="media-body col-md-8 col-lg-7 ">
                  <h5 class="mt-0 mb-1 font-weight-bold">Manager in IT</h5>
                  <span class="badge badge-pill badge-light badge-outline pt-2 pb-2 pr-3 pl-3 mr-2 mb-2">Business</span>
                </div>
              </a>
            </div>

          </li>
        </ul>
      </div>
      <!-- top careers end-->


      <!-- Other Categories -->
      <div id="other-categories" class="mt-5">
        <h4 class="mb-2">Other Categories</h4>
        <ul class="list-unstyled list-mob  mb-0  colleges-list  ">
          <li class="media row   ">
            <div class="col-md-12">
              <a href="#" class=" row p-0 " routerLink="/careerdetails">
                <div class="col-md-4 col-lg-5">
                  <div class="thumb-container">
                    <img class="img-fluid  " src="assets/img/colleges/thumb-1.png">
                  </div>
                </div>
                <div class="media-body col-md-8 col-lg-7 ">
                  <h5 class="mt-3 mb-1 font-weight-bold">Education</h5>

                </div>
              </a>
            </div>

          </li>
          <li class="media row ">
            <div class="col-md-12">
              <a href="#" class=" row p-0 " routerLink="/careerdetails">
                <div class="col-md-4 col-lg-5">
                  <div class="thumb-container">
                    <img class="img-fluid  " src="assets/img/colleges/thumb-2.png">
                  </div>
                </div>
                <div class="media-body col-md-8 col-lg-7 ">
                  <h5 class="mt-3 mb-1 font-weight-bold">Healthcare</h5>
                </div>
              </a>
            </div>

          </li>

        </ul>
        <a href="#" routerLink="/careers"
          class="btn btn-secondary-outline pl-3 pr-3 pt-2 pb-2 m-0 w-100 text-center mt-3">More </a>
      </div>
      <!-- Other Categories end-->
    </div>
    <!-- aside container end-->
  </div>

</div>
<!-- container end-->
<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>