import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

declare var $: any;
declare var Razorpay: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {


  userInfo;
  eduDetails: object = {};
  isLoading: boolean = false;
  msg: boolean = false;
  skills;
  interest;
  detailsid: string;
  qualifications;
  aspirantAllQualification = [];
  suggested_courses = [];
  suggested_exams = [];
  selectedExam;
  uniqueExtrnalId: string;
  courseConfig: any;
  examConfig: any;
  totalCourseRecords: number = 0;
  totalExamRecords: number = 0;
  filtered_suggested_exams = [];
  selectedIndex;
  wait: boolean = false;
  name: string;
  payableAmount: number;
  paymentSuccess: boolean = false;
  paymentFailed: boolean = false;
  detailsToSavedInDB = {};
  pendingQuizzes = [];
  userDetails: any;
  paidQuizDetails = {};
  showContinue: boolean = false;
  buy: boolean = false;
  showResult: boolean = false;
  payment_type: string;

  constructor(private _profileService: ManageProfileService, public router: Router, private firestore: AngularFirestore) { }


  async ngOnInit() { }


  async Checkout(amount: number, type: string, quizId = null) {

    this.isLoading = true;
    this.payment_type = type;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.name = this.userInfo['displayName'];
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }
    var name = this.userDetails['first_name'] + ' ' + this.userDetails['last_name'];
    var email = this.userInfo['email'];

    let details = {
      "name": name,
      "email": email
    };

    let orderResponse = await this.CreateOrder(amount);

    if ((orderResponse.status == 'Success' || orderResponse.Status == 'Success') && (orderResponse.message == 'Order created' || orderResponse.Data != '')) {

      var orderid = (orderResponse.data == undefined) ? orderResponse.Data : orderResponse.data.id;
      var ref = this;
      this.isLoading = false;
      //payment paramter to pass in razor pay object 
      const options = {
        key: environment.razorPayApiKeyId,
        order_id: orderid,
        name: "OpenNaukri",
        description: (type == 'Quiz') ? 'Payment For OpenNaukri Quiz' : 'Payment For OpenNaukri CareerCounselling',
        handler: function (response: object) {
          ref.VerifyPayment(response, amount, type, quizId);
        },
        prefill: details,
        theme: {
          "color": "#0478a7"
        }
      };

      //Open Gateway
      const rzp = await new Razorpay(options);
      rzp.open();
    } else {
      this.paymentFailed = true;
      this.isLoading = false;
    }
  }



  async CreateOrder(payableAmount: number) {
    this.isLoading = true;
    let order_request = {
      amount: payableAmount * 100,
      currency: "INR",
    }

    try {
      let order_id = await this._profileService.createOrder(order_request);
      return order_id;
    } catch (errorResponse) {
      this.isLoading = false;
      return errorResponse.error['Error'];
    }
  }

  async VerifyPayment(checkout_response: object, amount: number, type: string, quiz_id) {
    try {
      this.isLoading=true;
      checkout_response['amount'] = amount * 100;

      let paymentResponse = await this._profileService.verifyPayment(checkout_response);

      if (paymentResponse['status'] == 'Success' || paymentResponse['Status'] == 'Success') {

        if (paymentResponse['data'].hasOwnProperty('id')) {

          //save details in db
          this.paymentSuccess = true;
          let parseAmount = Math.round(paymentResponse['data']['amount']) / 100;
          this.detailsToSavedInDB = {
            payment_id: paymentResponse['data']['id'],
            order_id: paymentResponse['data']['order_id'],
            captured: true,
            payment_date: paymentResponse['data']['created_at'],
            amount: parseAmount.toFixed(2),
            status: 'paid',
            currency: paymentResponse['data']['currency'],
            paid_for: (type == 'Quiz') ? 'quiz' : 'career_counselling',
            userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
            created_at: new Date(),
            updated_at: new Date()
          }


          if (type == 'Quiz') {
            this.paidQuizDetails = {
              amount: parseAmount.toFixed(2),
              quiz_id: quiz_id,
              userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
              created_at: new Date(),
              updated_at: new Date()
            }
          }

        } else {

          this.paymentFailed = true;
          this.detailsToSavedInDB = {
            payment_id: checkout_response['razorpay_payment_id'],
            order_id: checkout_response['razorpay_order_id'],
            captured: false,
            payment_date: new Date(),
            amount: amount.toFixed(2),
            status: 'failed',
            currency: 'INR',
            paid_for: (type == 'Quiz') ? 'quiz' : 'career_counselling',
            userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
            created_at: new Date(),
            updated_at: new Date()
          }
        }

      } else {
        this.paymentFailed = true;
        this.detailsToSavedInDB = {
          payment_id: checkout_response['razorpay_payment_id'],
          order_id: checkout_response['razorpay_order_id'],
          captured: false,
          payment_date: new Date(),
          amount: amount.toFixed(2),
          status: 'failed',
          currency: 'INR',
          paid_for: (type == 'Quiz') ? 'quiz' : 'career_counselling',
          userId: this.firestore.doc('/users/' + this.userInfo['uid']).ref,
          created_at: new Date(),
          updated_at: new Date()
        }
      }

      let paymentDBResult = await this._profileService.addPaymentDetails('payments', this.detailsToSavedInDB);

      if (Object.keys(this.paidQuizDetails).length > 0 && type == 'Quiz') {
        let paidQuizResult = await this._profileService.addPaymentDetails('paid_quizzes', this.paidQuizDetails);
      }
      this.isLoading = false;
    } catch (errorResponse) {
      console.log('eer ', errorResponse);
      this.isLoading = false;
      this.paymentFailed = true;
      let paymentDBResult = await this._profileService.addPaymentDetails('payments', this.detailsToSavedInDB);   
    }
  }
}
