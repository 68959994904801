import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'aspirant-assessment';

  showHead: boolean = false;

  constructor(public router: Router) {}
    
    onActivate(event: Event) {
      window.scrollTo(0, 0);
    }
  
  }



