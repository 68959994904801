<!-- Body -->

<div class="page-heading row">
  <div class="heading col-lg-8 col-md-6 col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="dashboard">{{'dashboard_label' | translate }}</a></li>
      </ol>
    </nav>
    <h2>{{'dashboard_label' | translate }}</h2>
  </div>
  <div class=" col-lg-4 col-sm-12 col-md-6 heading ">
    <form class="form-inline search  mt-2">
      <div class="form-group  col-8 mb-0">
        <input type="text" class="form-control w-100" placeholder="Search by Careers, Courses or Colleges">
      </div>
      <div class="col-4 text-right pl-0 pr-1"><button type="submit"
          class="btn btn-primary mb-2  ">{{'search_label' | translate }}</button></div>
    </form>
  </div>

</div>

<div class="alert alert-warning alert-dismissible fade show row   mb-3" role="alert">
  {{'msg1_label' | translate }} &nbsp; <strong class="btext">{{'msg2_label' | translate }}</strong>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row">
  <!-- Profile -->
  <app-sidebar class="col-md-12 col-lg-3"></app-sidebar>

  <!-- Career Suggestion -->
  <div class="col-md-12 col-lg-9">
    <div class="data-list block-height-max">
      <div class="filter-data row">
        <div class="filter-career col-lg-6 col-md-6 ">
          <div class="p-3">
            <h3>{{'career_label' | translate }}</h3>
            <div class="form-group">
              <select class="form-control">
                <option selected="">Select</option>
                <option>...</option>
              </select>
              <a href="javascript:void(0)">ADD CAREER</a><br/>
              <a class="btn btn-primary-outline w-100 m-0" href="javascript:void()" (click)="Checkout()">PAY HERE</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 ">
          <div class="filter-sug-career  p-3">
            <h4>{{'suggested_label' | translate }}</h4>

            <ul class="list-inline mt-3">
              <li class="list-inline-item mr-2 mb-2 text-center"><a href="javascript:void(0)"><img
                    src="assets/img/icon-bank.png"><span>{{'banking_label' | translate}}</span></a></li>
              <li class="list-inline-item mr-2 mb-2 text-center"><a href="javascript:void(0)"><img
                    src="assets/img/icon-rail.png"><span>{{'railway_label' | translate}}</span></a></li>
              <li class="list-inline-item mr-2 mb-2 text-center"><a href="javascript:void(0)"><img
                    src="assets/img/icon-it.png"><span>{{'it_label' | translate}}</span></a></li>
              <li class="list-inline-item mr-2 mb-2 text-center"><a href="javascript:void(0)"><img
                    src="assets/img/icon-edu.png"><span>{{'education_label' | translate}}</span></a></li>
              <li class="list-inline-item  mb-2 text-center"><a href="javascript:void(0)"><img
                    src="assets/img/icon-defence.png"><span>{{'defence_label' | translate}}</span></a></li>
            </ul>
          </div>

          <!-- <span class="form-inline">
            <select 
                class="form-control" 
                #selectedLang 
                (change)="switchLang(selectedLang.value)">
              <option *ngFor="let language of translate.getLangs()" 
                [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
              </option>
            </select>
          </span> -->
        </div>
      </div>
      <div class="row m-0">
        <div class="col-lg-12 col-md-12">
          <div class="p-3">
            <div
              [ngClass]="(i==selectedIndex) ? 'badge badge-pill badge-light badge-outline active p-0  mr-2': 'badge badge-pill badge-light p-0 mr-2 badge-outline'" *ngFor="let job_sector of suggested_exams | filterUnique;let i=index"><a
                class="pt-2 pb-2 pr-2 pl-3 d-inline-block" href="javascript:void(0)"
                (click)="selectedExamFilter(job_sector,i)">{{job_sector}}</a>
              <a href="javascript:void(0)" class="ml-2 pt-2 pb-2 pr-3 pl-0 d-inline-block"><i class="fa fa-times"
                  aria-hidden="true"></i></a>
            </div>

            <a href="javascript:void(0)" routerLink="/careersuggestions"
              class="btn btn-secondary-outline btn-small mt-1 pull-right" *ngIf="suggested_exams.length > 1">View
              All</a>

          </div>
        </div>

      </div>

      <div class="row m-0">
        <div class="col-lg-7 col-md-7">
          <div class="row">
            <div class="col-lg-12">
              <div class="career-data pl-3 pt-3 pb-3">
                <h4 class="font-weight-bold "><a href="javascript:void(0)" routerLink="/career">{{selectedExam}} </a>
                </h4>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="pl-3 pr-3 pb-0">
                <ul class="list-unstyled list-mob  mb-0   row">
                  <li class="media   block-list  col-md-6 mb-3 "
                    *ngFor="let jobs of filtered_suggested_exams | paginate: examConfig">
                    <div class="row border m-0 w-100 pt-3 pb-1 block-hover">
                      <div class="col-md-10">
                        <a href="javascript:void(0)" class="p-0" routerLink="/careerdetails">
                          <h5 class="mt-0 mb-1 font-weight-bold ellipsis">{{jobs?.exam_name}}</h5>
                          <span class="badge badge-pill badge-light badge-outline badge-small mb-2 mt-2"
                            *ngIf="jobs?.job_department;else positionTemplate">
                            {{jobs?.job_department}} </span>
                          <ng-template #positionTemplate><span
                              class="badge badge-pill badge-light badge-outline badge-small mb-2 mt-2">{{ jobs?.job_position }}</span>
                          </ng-template>
                          <p class="mb-0 pb-2 ellipsis">
                            {{jobs?.exam_body}}
                          </p>
                        </a>
                      </div>
                      <div class="col-md-2">
                        <div class="btn-group dropright show action-holder">
                          <a class="btn btn-transparent dropdown-toggle p-2" href="#" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                              class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item">Info</a>
                            <a class="dropdown-item">Basic Highlights</a>
                            <a class="dropdown-item">Qualifications</a>
                            <a class="dropdown-item">Others</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
 
                 <!-- <span *ngIf="wait == true"><img src="assets/img/loading.svg" width="30px">Please wait</span> -->
                <ul class="list-unstyled list-mob  mb-0   row" *ngIf="suggested_exams.length == 0">
                  <li class="media   block-list  col-md-6 mb-3 ">
                    <div class="row border m-0 w-100 pt-3 pb-1 block-hover">
                      <div class="col-md-10">
                        No exam suggestions found!
                      </div>
                    </div>
                  </li>
                </ul>


              </div>
            </div>
            <nav aria-label="Page navigation example" class="w-100">
              <ul class="pagination justify-content-end mb-0 ">
                <!-- <li class="page-item disabled page-prev">
                  <a class="page-link" href="#" tabindex="-1">Previous</a>
                </li>
                <li class="page-item page-next">
                  <a class="page-link" href="#">Next</a>
                </li> -->
                <pagination-controls id="pagination1" [autoHide]="true" (pageChange)="examPageChanged($event)">
                </pagination-controls>
              </ul>
            </nav>
          </div>
        </div>


        <div class="col-lg-5 col-md-5">
          <div class="p-3">
            <p class="heading  pb-3 font-weight-bold">Compare Parameters</p>
            <div class="row">
              <div class="col-lg-6 text-center col-md-6"><img src="assets/img/pie.png" class="img-fluid">
                <p class="small-text text-center pt-3">20% Match with your profile</p>
              </div>
              <div class="col-lg-6 col-md-6">
                <ul class="list-unstyled">
                  <li class="mb-3 d-none">
                    <div class="progress-label">Personality</div>
                    <div class="progress">
                      <div class="progress-bar bg-danger" role="progressbar" style="width: 25%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </li>
                  <li class="mb-3">
                    <div class="progress-label">Education</div>
                    <div class="progress">
                      <div class="progress-bar bg-warning" role="progressbar" style="width: 50%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </li>

                  <li class="mb-3">
                    <div class="progress-label">Skill</div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 10%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </li>

                  <li>
                    <div class="progress-label">Interest</div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" style="width: 70%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- suggested courses -->
<div class="row ">
  <div class="col-md-12 col-lg-6">
    <div class="data-list p-3">
      <div class="row">
        <div class="col-md-8">
          <h4 class="mb-4 mt-2 font-weight-bold">Suggested Courses
          </h4>
        </div>
        <!-- <span  *ngIf="wait == true"><img src="assets/img/loading.svg"  style="width:30px;margin-left: 50px;">Please wait</span> -->

        <div class="col-md-4" *ngIf="suggested_courses.length > 3">
          <a href="javascript:void(0)" routerLink="/coursesuggestions"
            class="btn btn-secondary-outline btn-small mt-1 pull-right">View
            All</a></div>
      </div>

      <ul class="list-unstyled list-mob  mb-0 media-list-striped  courses-list "
        *ngFor="let courses of suggested_courses | paginate: courseConfig">
        <li class="media row  pt-3 pb-1 border-bottom">
          <div class="col-md-10">
            <a href="javascript:void(0)" class=" row p-0 " routerLink="/coursedetails/{{courses.courseid}}">
              <div class="col-md-4 col-lg-5 text-center"><img class="img-fluid mb-3" style="width:165px;height:60px"
                  *ngIf="courses.image;else imageSection" src="{{courses.image}}" onerror="this.src='assets/img/courses/finance/img-finance.png'">
                <ng-template #imageSection><img class="img-fluid mb-3" style="width:165px;height:60px"
                    src="assets/img/courses/finance/img-finance.png"></ng-template>
              </div>
              <div class="media-body col-md-8 col-lg-7 ">
                <h5 class="mt-0 mb-1 font-weight-bold"><a
                    routerLink="/coursedetails/{{courses.courseid}}">{{courses.title}}</a></h5>
                <a routerLink="/discipline/{{courses.discipline}}"><span
                    class="badge badge-pill badge-light badge-outline pt-2 pb-2 pr-3 pl-3 mr-2 mb-2">{{courses.discipline}}
                  </span>
                  <p [innerHTML]="courses?.description | split:'.'"></p>
                </a>
              </div>
            </a>
          </div>
          <div class="col-md-2">
            <div class="btn-group dropleft show action-holder">
              <a class="btn btn-transparent dropdown-toggle p-2" href="#" role="button" id="dropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v"
                  aria-hidden="true"></i></a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" routerLink="/coursedetails/{{courses.courseid}}" fragment="summary">Summary</a>
                <a class="dropdown-item" routerLink="/coursedetails/{{courses.courseid}}" fragment="entry">Entry
                  Requirement</a>
                  <a class="dropdown-item" routerLink="/coursedetails/{{courses.courseid}}" fragment="highlights">Major Highlights</a>
                  <a class="dropdown-item" routerLink="/coursedetails/{{courses.courseid}}" fragment="apply" >How to apply</a>
                  <a class="dropdown-item" routerLink="/coursedetails/{{courses.courseid}}" fragment="career" >Career Options</a>
              </div>
            </div>
          </div>


        </li>
      </ul>

      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end mb-0 mt-3">
          <!-- <li class="page-item disabled page-prev">
            <a class="page-link" href="#" tabindex="-1">Previous</a>
          </li>
          <li class="page-item page-next">
            <a class="page-link" href="#">Next</a>
          </li> -->
          <pagination-controls id="pagination2" [autoHide]="true" (pageChange)="coursePageChanged($event)">
          </pagination-controls>
        </ul>
      </nav>

      <ul class="list-unstyled list-mob  mb-0 courses-list " *ngIf="suggested_courses.length == 0">
        <li class="media row  pt-3 pb-1 border-bottom">
          <div class="col-md-10">
            No course suggestion found!
          </div>
        </li>

      </ul>

    </div>
  </div>


  <div class="col-md-12 col-lg-6">

    <div class="row">
      <!-- Assessment Score -->
      <div class="col-md-12">
        <div class="data-list p-3">
          <h4 class="font-weight-bold mb-4 mt-2">Assessment Score</h4>
          <div class="row">
            <div class="col-md-6"><img src="assets/img/range.png" class="img-fluid"> </div>
            <div class="col-md-6">
              <p class="pt-4">According to this ranked you best fit for banking job</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Support -->
      <div class="col-md-6">
        <div class="data-list p-3 text-center" style="height:240px;">
          <h4 class="font-weight-bold">Support</h4>
          <img src="assets/img/icon-support.png">
          <p class="pt-1">Speak to us for career solutions
            anytime from anywhere</p>
          <p><a href="javascript:void(0)">1800-2000-300</a></p>
        </div>
      </div>

      <!-- Membership -->
      <div class="col-md-6">
        <div class="data-list  " style="height:240px;">
          <div class="text-center p-3">
            <h4 class="text-orange pt-3 font-weight-bold">Free Membership</h4>
            <p class="mb-0">Current plan</p>
          </div>
          <div class="msg-bg-light p-3">
            <div>
              <p>Talk with our expert and get more
                right opportunities.</p>
              <p><a href="javascript:void(0)">UPGRADE PLAN</a></p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>
<!-- Modal -->

<div class="modal fade" id="IntroModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-heading">
          <h2>{{'welcome_label' | translate}} <span class="orange-text">{{'title1_label' | translate }}</span><span
              class="blue-text">{{'title2_label' | translate}}</span></h2>
          <p> {{'details_label' | translate  }}</p>
          <h3 class="mt-4 mb-4">{{'tellus_label' | translate }}</h3>
        </div>


        <form id="msform" #allDetails="ngForm" (ngSubmit)="addDetails(allDetails)" ngNativeValidate>
          <!-- fieldsets -->
          <fieldset>
            <label>Highest Qualification</label>
            <div class="form-group">
              <select class="form-control" name="highest_qualification" ngModel>
                <option value="">Select Education</option>
                <option *ngFor="let item of qualifications" attr.myAttr="{{item.rank}}" [ngValue]="item.name">
                  {{ item.display_name }}
                </option>
              </select>
            </div>
            <input type="button" name="next" class="btn btn-primary next mr-0 " value="Next" />
          </fieldset>
          <fieldset>
            <label>Interested Career</label>
            <div class="form-group">
              <input type="text" class="form-control" name="interested_carrer" ngModel [ngbTypeahead]="searchInterest"
                #instance="ngbTypeahead" />
            </div>
            <input type="button" name="previous" class="previous btn btn-secondary-outline" value="Previous" />
            <input type="button" name="next" class="next btn btn-primary mr-0" value="Next" />
          </fieldset>
          <fieldset>
            <label>Skill</label>
            <div class="form-group">
              <input type="text" class="form-control" name="skills" ngModel [ngbTypeahead]="searchSkills"
                #instance="ngbTypeahead" />
            </div>
            <!-- <p *ngIf="msg==true">Please fill any value to submit!</p> -->
            <input type="button" name="previous" class="previous btn btn-secondary-outline" value="Previous" />
            <!-- <input type="submit" class="submit btn btn-primary" value="Submit"  /> -->
            <button class="submit btn btn-primary" type="submit">Submit</button>

          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>