import { Component, OnInit } from '@angular/core';
import { ManageProfileService } from '../manage-profile.service';
import { NgForm } from '@angular/forms';
import { HeaderComponent } from '../../header/header.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Fitness } from '../../models/fitness.model';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';


@Component({
  selector: 'app-fitness',
  templateUrl: './fitness.component.html',
  styleUrls: ['./fitness.component.css']
})
export class FitnessComponent implements OnInit {

  userInfo;
  details: object = { body_type: '', eye_sight: '', physical_inability: 'no', type_of_inability: '', inability_percent: '' };
  isLoading: boolean = true;
  show: boolean = false;
  hideSection: boolean = false;
  fitness: Fitness;
  disablilityList;
  cancelButton: boolean = false;
  external_id='';

  constructor(private _profileService: ManageProfileService, public router: Router, private _header: HeaderComponent, private afAuth: AngularFireAuth, private firestore: AngularFirestore) { }


  ngOnInit(): void {
    this.isLoading = false;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getDetails();
    } else {
      this.router.navigate(['home']);
    }
  }

  async addDetails(form: NgForm) {

    this.isLoading = true;
    if (form.valid) {
      if (form.value['physical_inability'] == 'no') {
        form.value['inability_percent'] = '';
        form.value['type_of_inability'] = '';
      }

      let inputData: Fitness = {
        body_type: form.value['body_type'],
        height: form.value['height'],
        weight: form.value['weight'],
        eye_sight: form.value['eye_sight'],
        physical_inability: form.value['physical_inability'],
        inability_percent: form.value['inability_percent'],
        type_of_inability: form.value['type_of_inability'],
        external_id: 'aspirant_fitness_' + uuid()
      }


      let getFitnessDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      if (getFitnessDetails != null) {

        getFitnessDetails['aspirant_personal'] = getFitnessDetails.aspirant_personal ? getFitnessDetails.aspirant_personal : {};
        getFitnessDetails['aspirant_education'] = getFitnessDetails.aspirant_education ? getFitnessDetails.aspirant_education : {};
        getFitnessDetails['aspirant_skills'] = getFitnessDetails.aspirant_fitness ? getFitnessDetails.aspirant_fitness : {};
        getFitnessDetails['aspirant_fitness'] = inputData;
        getFitnessDetails['aspirant_interests'] = getFitnessDetails.aspirant_interests ? getFitnessDetails.aspirant_interests : {};
        getFitnessDetails['aspirant_social_profile'] = getFitnessDetails.aspirant_social_profile ? getFitnessDetails.aspirant_social_profile : {};
        getFitnessDetails['aspirant_certifications'] = getFitnessDetails.aspirant_certifications ? getFitnessDetails.aspirant_certifications : {};
        getFitnessDetails['aspirant_language'] = getFitnessDetails.aspirant_language ? getFitnessDetails.aspirant_language : {};

      } else {

        getFitnessDetails = {};
        getFitnessDetails['aspirant_personal'] = {};
        getFitnessDetails['aspirant_education'] = {};
        getFitnessDetails['aspirant_skills'] = {};
        getFitnessDetails['aspirant_fitness'] = inputData;
        getFitnessDetails['aspirant_interests'] = {};
        getFitnessDetails['aspirant_social_profile'] =  {};
        getFitnessDetails['aspirant_certifications'] =  {};
        getFitnessDetails['aspirant_language'] = {};
     
      }


      getFitnessDetails['created_by'] = this.userInfo['uid'];
      getFitnessDetails['modified_by'] = this.userInfo['uid'];
      getFitnessDetails['created_date_time'] = new Date();
      getFitnessDetails['modified_date_time'] = new Date();
      getFitnessDetails['operation'] = 'created';
      getFitnessDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.addFitnessDetails('aspirants', getFitnessDetails);

      let data = await this._header.getDetails();
      if (data != undefined) {
        this.getDetails();
        //this._header.suggestedExamsOnFieldsUpdation();
      }
      this.show = false;
      this.hideSection = false;
      this.isLoading = false;

    } else {
      this.isLoading = false;
    }
  }


  getDetails() {

    this.isLoading = true;

    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {

        // let result = await this._profileService.getFitnessDetails('aspirant_fitness', user.uid);
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result.aspirant_fitness != undefined && Object.keys(result.aspirant_fitness).length != 0) {
          this.details = result.aspirant_fitness;
          this.details['id'] = result.id;
          this.external_id = this.details['external_id'];
          this.show = false;
          if (this.details['physical_inability'] == 'no') {
            this.hideSection = false;
            this.details['inability_percent'] = '';
            this.details['type_of_inability'] = '';
          } else {
            this.hideSection = true;
          }
          this.cancelButton = true;
          this.isLoading = false;
        } else {

          this.details = { body_type: '', eye_sight: '', physical_inability: 'no', type_of_inability: '', inability_percent: '' };
          this.details['id'] = (result == null) ? '' : result.id;
          this.hideSection = false;
          this.cancelButton = false;
          this.show = true;
        }
        this.isLoading = false;
      } else {
        this._header.signOut();
      }
    });
  }


  async updateDetails(form: NgForm) {
    this.isLoading = true;
    if (form.valid) {
      let detailsId = this.details['id'];
      if (form.value['physical_inability'] == 'no') {
        form.value['inability_percent'] = '';
        form.value['type_of_inability'] = '';
      }
      let inputData: Fitness = {
        body_type: form.value['body_type'],
        height: form.value['height'],
        weight: form.value['weight'],
        eye_sight: form.value['eye_sight'],
        physical_inability: form.value['physical_inability'],
        inability_percent: form.value['inability_percent'],
        type_of_inability: form.value['type_of_inability'],
        external_id: this.external_id != '' ? this.external_id : 'aspirant_fitness_' + uuid()
      }


      let getFitnessDetails = JSON.parse(sessionStorage.getItem('aspirantInfo'));

      getFitnessDetails['aspirant_personal'] = getFitnessDetails.aspirant_personal ? getFitnessDetails.aspirant_personal : {};
      getFitnessDetails['aspirant_education'] = getFitnessDetails.aspirant_education ? getFitnessDetails.aspirant_education : {};
      getFitnessDetails['aspirant_skills'] = getFitnessDetails.aspirant_skills ? getFitnessDetails.aspirant_skills : {};
      getFitnessDetails['aspirant_fitness'] = inputData;
      getFitnessDetails['aspirant_interests'] = getFitnessDetails.aspirant_interests ? getFitnessDetails.aspirant_interests : {};
      getFitnessDetails['aspirant_social_profile'] = getFitnessDetails.aspirant_social_profile ? getFitnessDetails.aspirant_social_profile : {};
      getFitnessDetails['aspirant_certifications'] = getFitnessDetails.aspirant_certifications ? getFitnessDetails.aspirant_certifications : {};
      getFitnessDetails['aspirant_language'] = getFitnessDetails.aspirant_language ? getFitnessDetails.aspirant_language : {};

      getFitnessDetails['created_by'] = this.userInfo['uid'];
      getFitnessDetails['modified_by'] = this.userInfo['uid'];
      getFitnessDetails['created_date_time'] = new Date();
      getFitnessDetails['modified_date_time'] = new Date();
      getFitnessDetails['operation'] = 'created';
      getFitnessDetails['userId'] = this.firestore.doc('/users/' + this.userInfo['uid']).ref;

      let result = await this._profileService.updateFitnessDetails('aspirants', detailsId, getFitnessDetails);

      delete getFitnessDetails['userId'];
      delete getFitnessDetails['created_by'];
      delete getFitnessDetails['modified_by'];

      sessionStorage.setItem('aspirantInfo', JSON.stringify(getFitnessDetails));
      //this._header.suggestedExamsOnFieldsUpdation();
      this.show = false;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }


  async getAllDisability() {
    let disablilityResult = await this._profileService.getDisabilityList('disability_type');
    this.disablilityList = disablilityResult;
  }

  showForm() {
    this.getAllDisability();
    this.cancelButton = true;
    this.show = true;
  }

  showSection(msg: string) {
    if (msg == 'Yes') {
      this.getAllDisability();
      this.hideSection = true;
    } else {
      this.hideSection = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  resetForm() {
    this.getDetails();
    this.show = false;
  }
}
