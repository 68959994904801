<div class="page-heading row">
  <div class="heading col-sm-12 ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
      </ol>
    </nav>
    <h2>Manage Profile</h2>
  </div>
</div>


<div class="row">
  <!-- Aside container -->

  <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

  <!-- Aside container end-->

  <!-- Profile container -->
  <div class="col-md-9 col-lg-9">

    <div class="filter-tab ">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
        <li class="list-inline-item active"><a routerLink="/manage-profile/educational">Education</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/skills">Skill</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/social">Social Profile</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
        <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
      </ul>
    </div>

    <!-- <pre>
  {{details |json}}
</pre> -->

    <form #formDir="ngForm" name="formDir"  [formGroup]="education"
      (ngSubmit)="details['id'] ? updateDetails(education) : !details['id'] ? addDetails(education):addDetails(education)"
      ngNativeValidate>
      <div class="data-list p-3 mb-3 " formArrayName="Education">

        <div class="row " *ngIf="show==false">
          <div class="col-md-12">
            <div class="pull-right"><a class="btn btn-primary-outline m-0 " (click)="addEducation()"
                href="javascript:void(0)">+ Add Education</a></div>
            <p class="form-msg">Add your academic education</p>
          </div>
        </div>



        <div class="accordion mt-3" id="accordionEducation">
          <div [formGroupName]="i" *ngFor="let edu of getEducations(education); let i = index"
            [ngClass]="currentIndex!=i?myClass:''">
            <div id="heading{{i}}" class="cardHeader card bg-form mb-3" *ngIf="show==false">
              <div class="row">
                <div class="col-md-12">
                  <div class="pull-right"><a data-toggle="collapse" (click)="expandRow(i)" href="#collapse{{i}}"
                      class="btn btn-secondary-outline btn-small mr-2">Edit</a>
                    <a class=" btn btn-secondary-outline btn-small " title="Delete Education"
                      *ngIf="getEducations(education).length > 0" (click)="removeEducation(i,education)"
                      href="javascript:void(0)">Delete
                    </a>
                  </div>
                  <div class="profile-details">
                    <h2>{{edu.value.course}}</h2>

                    <h3 class="nfont mb-1"> {{edu.value.highest_qualification}} <span class="badge">Marks
                        <strong>{{edu.value.percentage}}%</strong></span> </h3>
                    <ul>
                      <li>{{edu.value.university}}</li>
                      <li>{{edu.value.year_of_passing}}</li>
                    </ul>
                  </div>

                </div>

              </div>
            </div>
            <!-- class="card-body" -->
            <div id="collapse{{i}}" [ngClass]="{'collapse' : true,'show':selectedIndex==i}"
              attr.aria-labelledby="heading{{i}}" data-parent="#accordionEducation">
              <div *ngIf="show==true">
                <div class="  mb-3">

                  <div class="row">
                    <div class="col-md-6"><label>Highest Qualification <span class="required">*</span></label>
                      <div class="form-group">
                        <select class="form-control" formControlName="highest_qualification"
                          (change)="currentRank($event.target)">
                          <option value="">Select Education</option>
                          <option *ngFor="let item of filteredQualifications" attr.myAttr="{{item.rank}}"
                            value="{{item.name}}">
                            {{ item.display_name }}
                          </option>
                        </select>
                        <small *ngIf="edu.get('highest_qualification').invalid && submitted" style="color:red">
                          This is required </small>
                      </div>
                    </div>

                    <div class="col-md-6" *ngIf="showCourseField==true"><label>Course </label>
                      <div class="form-group">
                        <mat-form-field class="typeHeadBox">
                          <input type="text" matInput formControlName="course" (keypress)="textOnly($event)"
                            (keyup)="setModel($event.target.value,i)" [matAutocomplete]="auto">
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let cor of filteredValues" [value]="cor.name">
                              {{ cor.display_name }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>

                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6"><label>University <span class="required">*</span></label>
                      <div class="form-group">

                        <input type="text" class="form-control" formControlName="university"
                          (keypress)="textOnly($event)" [ngbTypeahead]="searchUniversity" #instance="ngbTypeahead"
                          placeholder="university" required />

                      </div>
                    </div>
                    <div class="col-md-6"><label>Course Type</label>
                      <div class="form-group">
                        <div class="form-check form-check-inline">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="customControlValidation2{{i}}"
                              value="full" formControlName="course_type">
                            <label class="custom-control-label" for="customControlValidation2{{i}}">Full
                              Time</label>
                          </div>
                        </div>
                        <div class="form-check form-check-inline">
                          <div class="custom-control custom-radio mb-3">
                            <input type="radio" class="custom-control-input" id="customControlValidation3{{i}}"
                              formControlName="course_type" value="part">
                            <label class="custom-control-label" for="customControlValidation3{{i}}">Part
                              Time</label>
                          </div>
                        </div>
                        <div class="form-check form-check-inline">
                          <div class="custom-control custom-radio mb-3">
                            <input type="radio" class="custom-control-input" id="customControlValidation4{{i}}"
                              formControlName="course_type" value="distance">
                            <label class="custom-control-label" for="customControlValidation4{{i}}">Distance</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-3"><label>Year of Passing <span class="required">*</span></label>
                      <div class="form-group"><select class="form-control" formControlName="year_of_passing" required>
                          <option value="">Please select</option>
                          <option *ngFor="let years of allYears" value="{{years}}">{{years}}</option>
                        </select></div>
                    </div>

                    <div class="col-md-3"><label>Percentage<span class="required">*</span></label>
                      <div class="form-group">
                        <input type="text" (keypress)="numberOnly($event,'percentage')"
                          (keyup)="validValue($event.target.value,'percentage',i)" class="form-control"
                          formControlName="percentage" placeholder="percentage" required />
                        <p style="color:red;width: 160px;">{{invalidPercentage}}</p>
                      </div>
                    </div>

                    <div class="col-md-3"><label>Medium of Study<span class="required">*</span></label>
                      <div class="form-group">
                        <select class="form-control" formControlName="medium_of_study" required>
                          <option value="">Please select</option>
                          <option value="hindi">Hindi</option>
                          <option value="english">English</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-3"><label>Status<span class="required">*</span></label>
                      <div class="form-group">
                        <select class="form-control" formControlName="status">
                          <option value="">Please select</option>
                          <option value="completed">Completed</option>
                          <option value="pursuing">Pursuing</option>
                        </select>
                        <small *ngIf="edu.get('status').invalid && submitted" style="color:red">
                          This is required </small>
                      </div>
                    </div>   

                    <input type="hidden" formControlName="external_education_id">
                  </div>


                  <div class="row">
                    <div class="col-md-12" formArrayName="subjects"><label>Add Specialized Subjects <span
                          class="required">*</span></label>
                      <div class="row" [formGroupName]="j" *ngFor="let question of getSubjects(edu); let j = index">

                        <div class="form-group col-sm-3 mb-1">
                          <input type="text" class="form-control" formControlName="name" (keypress)="textOnly($event)"
                            [ngbTypeahead]="searchSubjects" #instance="ngbTypeahead" placeholder="name" required />
                        </div>

                        <div class="form-group col-sm-3 pb-0 mb-0">

                          <input type="text" class="form-control" formControlName="marks"
                            (keypress)="numberOnly($event,'marks')" placeholder="marks" maxlength="3"
                            (keyup)="validValue($event.target.value,'marks',j)" required>

                          <p style="color:red" *ngIf="currentSubjectIndex == j">{{invalidMarks}}</p>

                        </div>


                        <input type="hidden" formControlName="external_id">
                        <input type="hidden" formControlName="education_subject_id">
                        <div class="form-group col-sm-1 mb-3 pt-2 pl-0 pr-4 text-left">
                          <a href="javascript:void(0)" *ngIf="getSubjects(edu).length > 1" (click)="removeSubjects(i,j)"
                            class="secondary-link" title="Remove Subject"><i class="fa fa-times-circle"
                              aria-hidden="true"></i></a>
                        </div>

                      </div>
                      <a href="javascript:void(0)" *ngIf="invalidMarks == ''" (click)="addSubjects(i)" class="link">+
                        Add
                        Subject</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" *ngIf="show==true">
            <div class="col-md-12 text-right">
              <button class="btn btn-secondary-outline" (click)="cancel()">Cancel</button>
              <!-- <button class="btn btn-secondary-outline" (click)="resetForm()">Reset</button> -->
              <button class="btn btn-primary" type="submit"
                [disabled]="invalidPercentage != '' || invalidMarks != ''">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <input type="hidden" formControlName="HighestQualification">
    </form>
  </div>
</div>
<!-- <pre>
  {{education.value | json}}
</pre> -->

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>