

<!-- Success message start here-->
<div class="rounded border  mb-3 shadow-sm bg-light-blue" *ngIf="paymentSuccess==true">
    <ul class="list-unstyled mb-0">
        <li class="media">
            <img src="assets/img/icon-check.png" class="m-3">
            <div class="media-body pt-3 pb-3">
                <h4 class="mt-0 mb-2 text-primary">You have successfully {{payment_type=='Quiz'?'purchase the quiz':'subscribed' }}. </h4>
                <p class="m-0" *ngIf="payment_type != 'Quiz'">Our expert will connect with you soon. Now you are able to get all inofrmation related to
                    your career.</p>

            </div>
        </li>
    </ul>
</div>
<!-- Success message end here-->

<!-- Failed message start here-->
<div class="rounded border  mb-3 shadow-sm bg-light-blue" *ngIf="paymentFailed==true">
    <ul class="list-unstyled mb-0">
        <li class="media">
            <img src="assets/img/icon-info.png" class="m-3">
            <div class="media-body pt-3 pb-3">
                <h4 class="mt-0 mb-2 text-orange">Your Payment Failed</h4>
                <p class="m-0">Please try after some time.</p>

            </div>
        </li>
    </ul>
</div>
<!-- Failed message end here-->

<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>