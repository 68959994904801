import { Component, OnDestroy, OnInit, HostListener, Inject } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import * as screenfull from 'screenfull';

declare var $: any;

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  questions: any[];
  i = 0;
  totalLength: number;
  hideNextQuestion: number;
  result: { Id: string; Question: string; AnswerDescription: string; Answer: string; QuestionType: string; option: { Id: string; OptionValue: string; Answer: string; }[]; }[];
  wrongAnswer: number = 0;
  statusMessage: string;
  correctAnswer: number = 0;
  resultInPercent: number = 0;
  hideButtons: boolean = false;
  attempted: number = 0;
  notAttempted: number = 0;
  quizComplete: boolean = false;
  quizSummary = {};
  quiz_data: any[];
  quiz_id: any;
  quiz_name: any;
  question_category: any;
  isLoading: boolean = false;
  allQuestions = [];
  result_details: boolean = false;
  quizDetails = [];
  quiz_type: any;
  quiz_duration: any;
  access_type: any;
  timeLeftinHours: number = 0;
  timeLeftinMinute: number = 0;
  timeLeftinSeconds: number = 0;
  interval;
  totalQuizQuestion: any;
  showStart: boolean = false;
  showPause: boolean = true;
  pausableAllowed: boolean = false;
  previousAllowed: boolean = false;
  quiz_category: any;
  uploadedSolution: any;
  msg: string;
  downloadURL: Observable<string>;
  maximumUploadSize: Number = 1000000;
  attempted_quiz = [];
  nextAllowed: any;
  elem: any;
  file_input: any;
  isFullScreen: boolean;
  randomQuestion: boolean = false;
  quit_quiz: any;
  userDetails: any;

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  //@HostListener('document:keydown', ['$event'])

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == 27 || event.keyCode == undefined) {
      if (screenfull.isEnabled) {
        this.closeQuiz(this.quit_quiz, true);
      }
    } else {
      this.showConfirmation();
    }
  }


  constructor(private _service: ManageProfileService, private route: ActivatedRoute, private storage: AngularFireStorage, private toastr: ToastrService, public router: Router, private location: LocationStrategy, @Inject(DOCUMENT) private document: any, private _firestore: AngularFirestore) {
    this.quiz_id = this.route.snapshot.paramMap.get('id');
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
  }



  onFileSelected(file) {
    this.isLoading = true;
    var n = Date.now();
    const filePath = `Quiz/${n}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(`Quiz/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.uploadedSolution = url;
              this.questions[this.i]['url'] = url;
              this.msg = '';
              this.isLoading = false;
            }
          });
        })
      )
      .subscribe(url => {
        if (url) {
          //console.log('URL ', url);
        }
      });
  }


  uploadAnswer(event: any): void {
    const file = event.target.files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'doc' || file.type == 'docx' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.isLoading = true;
      if (file.size > this.maximumUploadSize) {
        this.toastr.error('Please select less then 1mb file!');
      } else {
        this.onFileSelected(file);
      }
    } else {
      this.toastr.error('Invalid file format!');
    }
  }


  async ngOnInit() {

    try {

      this.isLoading = true;
      this.elem = document.documentElement;
      if (screenfull.isEnabled) {
        console.log('start');
        screenfull.request();
      }
      let id = JSON.parse(localStorage.getItem('userInfo'));
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

      this.attempted_quiz = await this._service.getQuizFromResult('quiz_result', this.quiz_id, id['uid']);
      let QuizQuestions = [];

      if (this.attempted_quiz.length > 0) {

        this.quiz_data = this.attempted_quiz;
        QuizQuestions = this.quiz_data[0].details;
        this.totalQuizQuestion = QuizQuestions.length;
        this.quiz_duration = this.quiz_data[0].settings.duration;
        this.pausableAllowed = this.quiz_data[0].settings.pausable;
        this.previousAllowed = this.quiz_data[0].settings.previous;
        this.nextAllowed = this.quiz_data[0].settings.next;
        this.quiz_category = this.quiz_data[0].category_name;
        this.randomQuestion = this.quiz_data[0].random_questions;
        this.quit_quiz = this.quiz_data[0].settings.quiz_stop;


        if (this.quiz_duration.required == false) {
          this.timeLeftinMinute = 0;
          this.timeLeftinSeconds = 0;
        } else {
          this.timeLeftinMinute = this.quiz_duration.minutes;
          this.timeLeftinSeconds = this.quiz_duration.seconds;
        }

        this.i = this.quiz_data[0].last_index;

        this.quiz_id = this.quiz_data[0].quiz_id;
        this.quiz_name = this.quiz_data[0].quiz_name;
        this.question_category = this.quiz_data[0].category_name.join();
        this.access_type = this.quiz_data[0].settings.access_type;
        this.questions = QuizQuestions;

      } else {

        this.quiz_data = await this._service.getQuizById('quizzes', this.quiz_id);

        this.quiz_duration = this.quiz_data[0].settings.duration;
        this.pausableAllowed = this.quiz_data[0].settings.pausable;
        this.previousAllowed = this.quiz_data[0].settings.previous;
        this.nextAllowed = this.quiz_data[0].settings.next;
        this.quiz_category = this.quiz_data[0].category_name;
        this.randomQuestion = this.quiz_data[0].random_questions;
        this.quit_quiz = this.quiz_data[0].settings.quiz_stop;

        if (this.quiz_duration.required == false) {
          this.timeLeftinMinute = 0;
          this.timeLeftinSeconds = 0;
        } else {
          this.timeLeftinMinute = this.quiz_duration.minutes;
          this.timeLeftinSeconds = 0;
        }


        this.quiz_id = this.quiz_data[0].id;
        this.quiz_name = this.quiz_data[0].name;
        this.question_category = this.quiz_data[0].category_name.join();
        this.access_type = this.quiz_data[0].settings.access_type;

        if (this.randomQuestion == true) {

          let available_filters = {
            "question_category": this.quiz_data[0].category_id,
            "question_type": this.quiz_data[0].question_type,
            "level": this.quiz_data[0].level,
            "featured": this.quiz_data[0].featured,
            "question_number": this.quiz_data[0].settings.number_of_questions
          };


          let result = await this._service.getRandomQuizQuestions(available_filters);

          if (result['status'] == 'Success' && result['data'].length > 0) {
            this.questions = result['data'];
            this.totalQuizQuestion = result['data'].length;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            $('#myModal').modal('hide');
            this.router.navigate(['my-learning'])
            return;
          }

        } else {

          QuizQuestions = this.quiz_data[0].questions;
          this.totalQuizQuestion = QuizQuestions.length;

          //for more than 10 questions//  
          if (QuizQuestions.length > 10) {

            let finalArray = this.chunk(QuizQuestions, 10);
            for (let i = 0; i < finalArray.length; i++) {
              let question_bank = await this._service.getQuizQuestions('quiz_questions', finalArray[i]);
              this.allQuestions.push(question_bank);
            }

            let finalList = this.allQuestions.reduce(function (arr, e) {
              return arr.concat(e);
            })

            this.questions = finalList;
          } else {
            let question_bank = await this._service.getQuizQuestions('quiz_questions', QuizQuestions);
            this.questions = question_bank;
          }
        }
      } //end else from quiz


      sessionStorage.setItem('quizid', this.quiz_id);
      sessionStorage.setItem('quizname', this.quiz_name);
      sessionStorage.setItem('quiz', JSON.stringify(this.questions));
      this.afterQuizSummary(this.i, false, false);
      if (this.timeLeftinMinute != 0) {
        this.startTimer();
      }

      this.totalLength = Object.keys(this.questions).length;
      this.hideNextQuestion = this.totalLength - 1;
      this.isLoading = false;
    } catch (errorResponse) {

      console.log('errorResponse ', errorResponse);
      this.isLoading = false;
    }
  }


  chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  }


  NewQuestion(index: number) {

    if (this.questions[index]['required'] == true && this.questions[index]['GivenAnswer'] != undefined && this.questions[index]['url'] == undefined) {
      this.msg = '*Please upload solution! Supported document type should be .jpg/.png/.doc & should not be more than 1 mb!';
    } else {

      this.hideNextQuestion = this.totalLength - 1;
      if (this.totalLength >= index) {
        this.i = index + 1;
      }


      let previousAttempt = this.questions[index]['GivenAnswer'];
      if (previousAttempt != '' && previousAttempt != undefined) {
        this.questions[index]['Attempt'] = true;
        sessionStorage.setItem('quiz', JSON.stringify(this.questions));
      } else {
        this.questions[index]['Attempt'] = false;
        this.selectAnswer("", index);
      }

      this.questions = JSON.parse(sessionStorage.getItem('quiz'))
    }
  }

  PreviousQuestion(index: number) {
    this.totalLength = Object.keys(this.questions).length;
    if (this.totalLength >= index) {
      this.i = index - 1;
    }

    let previousAttempt = this.questions[index]['GivenAnswer'];

    if (previousAttempt != '' && previousAttempt != undefined) {
      this.questions[index]['Attempt'] = true;
      sessionStorage.setItem('quiz', JSON.stringify(this.questions));
    } else {
      this.questions[index]['Attempt'] = false;
      this.selectAnswer("", index);
    }
    this.questions = JSON.parse(sessionStorage.getItem('quiz'));
  }

  selectAnswer(answerValue: string, index: number) {

    if (answerValue != '') {
      if (answerValue == 'true' || answerValue == 'false') {
        let originalAnswer = this.questions[index]['answer'];
        this.questions[index]['GivenAnswer'] = answerValue;
        this.questions[index]['OriginalAnswer'] = originalAnswer;

        if (originalAnswer == JSON.parse(answerValue)) {
          this.questions[index]['result'] = true;
        } else {
          this.questions[index]['result'] = false;
        }
      } else {

        let givenAnswer = this.questions[index]['options'].find(function (el) { return el.option_value === answerValue });
        let originalAnswer = this.questions[index]['options'].find(function (el) { return el.answer == '1' })

        if (givenAnswer != undefined) {

          switch (givenAnswer.answer) {

            case '0':
              this.questions[index]['GivenAnswer'] = answerValue;
              this.questions[index]['OriginalAnswer'] = originalAnswer.option_value;
              this.questions[index]['result'] = false
              break;

            case '1':
              this.questions[index]['GivenAnswer'] = answerValue;
              this.questions[index]['OriginalAnswer'] = originalAnswer.option_value;
              this.questions[index]['result'] = true
              break;

            default:
              this.questions[index]['GivenAnswer'] = '';
              this.questions[index]['OriginalAnswer'] = originalAnswer.option_value;
              this.questions[index]['result'] = null
              break;
          }
        } else {
          this.questions[index]['GivenAnswer'] = '';
          this.questions[index]['OriginalAnswer'] = originalAnswer.option_value;
          this.questions[index]['result'] = null
        }
      }

      sessionStorage.setItem('quiz', JSON.stringify(this.questions));
      this.questions = JSON.parse(sessionStorage.getItem('quiz'));

    } else {
      this.questions[index]['Attempt'] = false;
      this.questions[index]['result'] = null;
      sessionStorage.setItem('quiz', JSON.stringify(this.questions));
    }

    this.afterQuizSummary(this.i, false, false);
  }

  clearAnswer(index: number) {
    this.questions[index]['GivenAnswer'] = '';
    this.questions[index]['Attempt'] = false;
    this.questions[index]['result'] = null;
    sessionStorage.setItem('quiz', JSON.stringify(this.questions));
  }

  async afterQuizSummary(lastQuestion: number, status: boolean, emailSend) {

    let userid = JSON.parse(localStorage.getItem('userInfo'));

    if (status == true) {

      let lastAttempt = this.questions[lastQuestion]['GivenAnswer'];


      if (this.questions[lastQuestion]['required'] == true && this.questions[lastQuestion]['GivenAnswer'] != undefined && this.questions[lastQuestion]['url'] == undefined) {
        this.msg = '*Please upload solution!';
      } else {

        this.quizComplete = true;
        if (lastAttempt != undefined) {
          this.questions[lastQuestion]['Attempt'] = true;
          sessionStorage.setItem('quiz', JSON.stringify(this.questions));
        } else {
          this.questions[lastQuestion]['Attempt'] = false;
          this.selectAnswer("", lastQuestion);
        }

        let result = JSON.parse(sessionStorage.getItem('quiz'));

        if (result != null) {

          this.result_details = true;
          this.hideButtons = true;
          let finalResult = result.filter(function (el) { return el.result === true })
          let totalAttemptQuestions = result.filter(function (el) { return el.Attempt == true })

          this.correctAnswer = Object.keys(finalResult).length;
          this.attempted = Object.keys(totalAttemptQuestions).length;
          this.wrongAnswer = this.attempted - this.correctAnswer;
          this.resultInPercent = this.correctAnswer * 100 / this.totalLength;
          this.notAttempted = this.totalLength - this.attempted;


          this.quizSummary = {
            'userId': this._firestore.doc('/users/' + userid.uid).ref, 'details': result, 'quiz_id': this.quiz_id, 'quiz_name': this.quiz_name, 'created_by': userid.uid, 'modified_by': userid.uid, 'created_at': new Date(), 'updated_at': new Date(), 'category_name': this.quiz_category, 'quiz_attempt': true, 'quiz_complete': true, 'last_index': this.i, 'banner_image': this.quiz_data[0].banner_image != undefined ? this.quiz_data[0].banner_image : '', 'random_questions': this.randomQuestion, 'settings': { 'description': this.quiz_data[0].settings.description != undefined ? this.quiz_data[0].settings.description : '', 'duration': { 'minutes': this.quiz_duration.minutes, 'required': (this.quiz_duration != undefined) ? this.quiz_duration.required : false }, 'access_type': this.access_type, 'next': this.nextAllowed, 'pausable': this.pausableAllowed, previous: this.previousAllowed, 'quiz_stop': this.quit_quiz }
          };
        }

        if (screenfull.isEnabled) {
          screenfull.exit();
        }
        clearInterval(this.interval);
      }
    } else {

      let result = JSON.parse(sessionStorage.getItem('quiz'));

      this.quizSummary = {
        'userId': this._firestore.doc('/users/' + userid.uid).ref, 'details': result, 'quiz_id': this.quiz_id, 'quiz_name': this.quiz_name, 'created_by': userid.uid, 'modified_by': userid.uid, 'created_at': new Date(), 'updated_at': new Date(), 'category_name': this.quiz_category, 'quiz_attempt': true, 'quiz_complete': false, 'last_index': this.i, 'banner_image': this.quiz_data[0].banner_image != undefined ? this.quiz_data[0].banner_image : '', 'random_questions': this.randomQuestion, 'settings': { 'description': this.quiz_data[0].settings.description != undefined ? this.quiz_data[0].settings.description : '', 'duration': { 'minutes': this.timeLeftinMinute, 'seconds': this.timeLeftinSeconds, 'required': (this.quiz_duration != undefined) ? this.quiz_duration.required : false }, 'access_type': this.access_type, 'next': this.nextAllowed, 'pausable': this.pausableAllowed, 'previous': this.previousAllowed, 'quiz_stop': this.quit_quiz }
      };
    }

    let exist_quiz = await this._service.getQuizFromResult('quiz_result', this.quiz_id, userid.uid);
    if (exist_quiz.length > 0) {
      //update details in db
      let detailsSaved = await this._service.updateAspirantQuizDetails('quiz_result', exist_quiz[0]['id'], this.quizSummary);
    } else {
      //add details in db
      let detailsSaved = await this._service.addAspirantQuizDetails('quiz_result', this.quizSummary);
    }

    //send email to employeeResult start//
    if (status == true && this.userDetails.role == 'Employee' && emailSend == true) {
      let employee_data = {
        user_id: userid.uid,
        quiz_id: this.quiz_id,
        user_name: this.userDetails.first_name + ' ' + this.userDetails.last_name
      }

      let result = await this._service.sendEmail(employee_data);
    }
    //send email to employeeResult end// 

  }


  startTimer() {
    this.showPause = true;
    this.showStart = false;
    this.interval = setInterval(() => {
      if (this.timeLeftinSeconds > 0) {
        this.timeLeftinSeconds--;
      } else {
        this.timeLeftinSeconds = 60;
        this.timeLeftinMinute--;
        if (this.timeLeftinMinute == -1 || this.timeLeftinMinute < 0) {
          if (this.timeLeftinHours == 0) {
            this.timeLeftinMinute = 0;
          } else {
            this.timeLeftinMinute = 59;
          }
          this.timeLeftinSeconds = 0;
          this.timeLeftinHours--;

          if (this.timeLeftinMinute == 0) {
            this.timeLeftinSeconds = 0;
            this.timeLeftinHours = 0;
            this.pauseTimer();
            this.afterQuizSummary(this.totalQuizQuestion - 1, true, true);
          }
        }
        if (this.timeLeftinHours <= 0) {
          if (this.timeLeftinMinute == 0) {
            //this.timeLeftinMinute = -1
          } else {
            //this.timeLeftinMinute--;
            this.timeLeftinSeconds = 59;
          }
        }
      }
    }, 1000);
  }

  pauseTimer() {
    this.showStart = true;
    this.showPause = false;
    clearInterval(this.interval);
  }


  close(quiz_stop: boolean) {
    if (quiz_stop == true) {
      this.afterQuizSummary(this.i, true, false);
      $('#myModal2').modal('hide');
    } else {
      if (this.result_details == true) {
        this.afterQuizSummary(this.i, true, false);
      } else {
        this.afterQuizSummary(this.i, false, false);
      }
      $('#myModal').modal('hide');
      $('#myModal2').modal('hide');
      if (this.userDetails.role == 'Aspirant') {
        this.router.navigate(['my-learning']);
      } else {
        this.router.navigate(['dashboard'])
      }
    }
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }

  backToQuiz() {
    $('#myModal').modal('hide');
    if (this.userDetails.role == 'Aspirant') {
      this.router.navigate(['my-learning']);
    } else {
      this.router.navigate(['dashboard'])
    }
  }

  closeQuiz(quiz_stop: boolean, event) {
    if (quiz_stop != undefined) {
      if (quiz_stop == true) {
        this.afterQuizSummary(this.i, true, event);
      } else {
        if (this.result_details == true) {
          this.afterQuizSummary(this.i, true, event);
        } else {
          this.afterQuizSummary(this.i, false, false);
          $('#myModal').modal('hide');
          if (this.userDetails.role == 'Aspirant') {
            this.router.navigate(['my-learning']);
          } else {
            this.router.navigate(['dashboard'])
          }
        }
      }
    }
  }


  showConfirmation() {
    if (this.result_details != true) {
      $('#myModal2').modal('show');
    }
  }

  closeModal() {
    $('#myModal2').modal('hide');
  }
}
