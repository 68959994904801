// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //firestore prod configuartion details
  config: {
    apiKey: "AIzaSyDuuCrJc31GFmZJ9EbUPvm0JIWMUnB1Lz8",
    authDomain: "opennaukri-cms-prod.firebaseapp.com",
    databaseURL: "https://opennaukri-cms-prod.firebaseio.com",
    projectId: "opennaukri-cms-prod",
    storageBucket: "opennaukri-cms-prod.appspot.com",
    messagingSenderId: "1050865972732",
    appId: "1:1050865972732:web:ead07cb2df47d9e4ba6e79",
    measurementId: "G-NRVGV532X5"
  },


  serviceUrl: "https://us-central1-opennaukri-cms-prod.cloudfunctions.net",
  razorPayApiKeyId: "rzp_live_4TUzzb7k6S79u2",
  orderAmount: 899,
  emailUrl: 'https://app.opennaukri.com'
}

  //firestore uat configuartion details
//   config: {
//     apiKey: "AIzaSyAWWnV375l7X26NburmjReIxukKlpGbrqM",
//     authDomain: "opennaukri-webapp-uat-cms.firebaseapp.com",
//     projectId: "opennaukri-webapp-uat-cms",
//     storageBucket: "opennaukri-webapp-uat-cms.appspot.com",
//     messagingSenderId: "468037119769",
//     appId: "1:468037119769:web:f8902c0b4d3ad89fe01f88",
//     measurementId: "G-TNMBVL5CJY"
//   },

//   serviceUrl: "https://us-central1-opennaukri-webapp-uat-cms.cloudfunctions.net",
//   razorPayApiKeyId: "rzp_test_ejclfBeHRwI85c",
//   orderAmount: 899,
//   emailUrl: 'https://opennaukri-webapp-uat.web.app/'

// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
