import { Component, OnInit } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {


  userInfo: object;
  isLoading: boolean = false;
  payment_list: { id: string; }[];

  constructor(private _profileService: ManageProfileService, public router: Router) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.getOrders();
    } else {
      this.router.navigate(['home']);
    }

  }

  async getOrders() {

    this.isLoading = true;
    try {
      let orders_list = await this._profileService.getPaymentHistory('payments', this.userInfo['uid']);
      if (orders_list.length > 0) {
        this.payment_list = orders_list;
      } else {
        this.payment_list = [];
      }
      this.isLoading = false;
    } catch (errorResponse) {
      this.ngOnInit();
    }
  }

  async searchPayments(paymentid: string) {
    this.isLoading = true;
    let list = await this._profileService.getPaymentsById('payments', this.userInfo['uid'], paymentid);
    if (list.length > 0) {
      this.payment_list = list;
    } else {
      this.payment_list = [];
    }
    this.isLoading = false;
  }


  async downloadInvoice(paymentid: string) {
    this.isLoading = true;
    let list = await this._profileService.getPaymentsById('payments', this.userInfo['uid'], paymentid);
    if (list.length > 0) {

      delete list[0]['userId'];
      delete list[0]['created_at'];
      delete list[0]['updated_at'];

      list[0]['payment_date'] = new Date(list[0]['payment_date'] * 1000).toUTCString();
      list[0]['captured'] = 'true';

      //download in excel
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(list);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'payment.xlsx', { bookType: 'xlsx', type: 'buffer' });
    }
    this.isLoading = false;
  }

}
