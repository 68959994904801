import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { AuthService } from '../auth.service';
import { ManageProfileService } from '../manage-profile/manage-profile.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {

  isLoggedIn$;
  userInfo;
  userDetails;
  url;
  image;
  coursesSuggestion;
  aspirantInfo;
  examSuggestion;
  isLoading: boolean = false;
  personalDetails: any;

  constructor(public translate: TranslateService, public afAuth: AngularFireAuth, public router: Router, private authService: AuthService, private cdRef: ChangeDetectorRef, private _profileService: ManageProfileService) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }


  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (this.userInfo != null) {
      this.image = this.userInfo['photoURL'];
      setInterval(() => {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (this.userInfo != null) {
          this.image = this.userInfo['photoURL'];
        }
      }, 4000);

      //to check user status//
      this.getUserStatus(this.userInfo.uid);
    } else {
      this.router.navigate(['home']);
    }


    //while load page
    this._profileService.refreshGetNeeded$.subscribe(() => {
      this.getPersonalDetails();
    });

    //while update details
    this._profileService.refreshNameNeeded$.subscribe(() => {
      this.getPersonalDetails();
    })

  }

  async getPersonalDetails() {
    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        let result = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        if (result != null && result['aspirant_personal'] != undefined && Object.keys(result['aspirant_personal']).length != 0) {
          this.personalDetails = result['aspirant_personal'];
          this.userDetails['first_name'] = this.personalDetails['first_name'];
          this.userDetails['last_name'] = this.personalDetails['last_name'];
        }
      } else {
        this.signOut();
      }
      this.isLoading = false;
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  async signOut() {
    await this.afAuth.signOut();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userDetails');
    sessionStorage.removeItem('aspirantInfo');
    sessionStorage.removeItem('course_suggestions');
    sessionStorage.removeItem('exam_suggestions');
    //this.router.navigate(['home']);
    window.location.replace('home');
  }


  async getDetails() {

    return await this.afAuth.currentUser.then(async user => {
      if (user != null) {
        try {
          let result = await this._profileService.getPersonalDetails('aspirants', user.uid);
          if (result.length > 0) {
            let allDetails = result[0];
            delete allDetails['userId'];
            delete allDetails['created_by'];
            delete allDetails['modified_by'];
            sessionStorage.setItem('aspirantInfo', JSON.stringify(allDetails));
            return allDetails;
          }
        } catch (error) {
          console.log('error ', error);
          return error;
        }
      }
    });
  }

  async allSuggestedCourses() {
    try {
      this.isLoading = true;
      this.coursesSuggestion = JSON.parse(sessionStorage.getItem('course_suggestions'));
      let userid = JSON.parse(localStorage.getItem('userInfo'));
      if (this.coursesSuggestion != null && this.coursesSuggestion.length >= 0) {
        return this.coursesSuggestion;
      } else {
        console.log(userid['uid']);
        let result = await this._profileService.getCourseSuggestion('course_suggestion', userid['uid']);
        console.log('result ', result);
        if (result.length > 0) {
          sessionStorage.setItem('course_suggestions', JSON.stringify(result[0]['suggestion']));
          return result[0]['suggestion'];
        } else {
          this.aspirantInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
          this.aspirantInfo['userId'] = userid['uid'];
          let suggestion = await this._profileService.addCourseSuggestion(this.aspirantInfo);
          if (suggestion['statusCode'] == 200 && suggestion['message'] == 'Updated successfullyy') {

            sessionStorage.setItem('course_suggestions', JSON.stringify(suggestion['data']['suggestion']));

            return suggestion['data']['suggestion'];

          } else {
            this.isLoading = false;
            return [];
          }
        }
      }
    } catch (error) {
      console.log('errror ', error);
      this.isLoading = false;
    }
  }


  async suggestedCoursesOnFieldsUpdation() {
    this.isLoading = true;
    return await this.afAuth.currentUser.then(async user => {
      if (user != null) {

        this.aspirantInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        this.aspirantInfo['userId'] = user.uid;

        let suggestion = await this._profileService.addCourseSuggestion(this.aspirantInfo);
        if (suggestion['statusCode'] == 200) {
          if (Object.keys(suggestion['data']).length == 0) {
            sessionStorage.setItem('course_suggestions', '[]');
          } else {
            sessionStorage.setItem('course_suggestions', JSON.stringify(suggestion['data']['suggestion']));
          }
          return suggestion['data']['suggestion'];
        } else {
          return [];
        }
      }
    })
  }

  async allSuggestedExams() {

    this.isLoading = true;
    return await this.afAuth.currentUser.then(async user => {
      if (user != null) {
        this.examSuggestion = JSON.parse(sessionStorage.getItem('exam_suggestions'));
        if (this.examSuggestion != null && this.examSuggestion.length >= 0) {
          return this.examSuggestion;
        } else {
          let userid = JSON.parse(localStorage.getItem('userInfo'));
          let result = await this._profileService.getExamSuggestion('exam_suggestion', userid['uid']);
          if (result.length > 0) {
            console.log('from table se exam');
            sessionStorage.setItem('exam_suggestions', JSON.stringify(result[0]['suggestion']));
            return result[0]['suggestion'];
          } else {
            console.log('HHEERE');
            this.aspirantInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
            this.aspirantInfo['userId'] = user.uid;
            console.log('this.aspirantInfo ', this.aspirantInfo);
            let suggestion = await this._profileService.addExamSuggestion(this.aspirantInfo);

            console.log('suggestion ', suggestion);

            if (suggestion['statusCode'] == 200 && suggestion['message'] == 'Updated Exam Suggestion successfullyy') {

              sessionStorage.setItem('exam_suggestions', JSON.stringify(suggestion['data']['suggestion']));
              return suggestion['data']['suggestion'];

            } else {
              console.log('In else');
              // if (Object.keys(suggestion['data']).length == 0) {
              //   sessionStorage.setItem('exam_suggestions', '[]');
              // }
              sessionStorage.setItem('exam_suggestions', '[]');
              this.isLoading = false;
              return [];

            }
          }
        }
      }
    });
  }

  async suggestedExamsOnFieldsUpdation() {

    this.isLoading = true;
    return await this.afAuth.currentUser.then(async user => {
      if (user != null) {

        this.aspirantInfo = JSON.parse(sessionStorage.getItem('aspirantInfo'));
        this.aspirantInfo['userId'] = user.uid;

        let exam_suggestion = await this._profileService.addExamSuggestion(this.aspirantInfo);
        if (exam_suggestion['statusCode'] == 200) {
          if (Object.keys(exam_suggestion['data']).length == 0) {
            sessionStorage.setItem('exam_suggestions', '[]');
          } else {
            sessionStorage.setItem('exam_suggestions', JSON.stringify(exam_suggestion['data']['suggestion']));
          }
          return exam_suggestion['data']['suggestion'];
        } else {
          this.isLoading = false;
          return [];
        }
      }
    })
  }

  async disciplineList() {
    let result = await this._profileService.getAllDisciplineList('discipline');
    if (result.length > 0) {
      let discipline_list = result;
      return discipline_list;
    } else {
      return [];
    }
  }

  async getCounsellingAmount() {
    let configurePrice = await this._profileService.getQualificationList('configuration');
    if (configurePrice.length > 0) {
      return configurePrice[0]['counselling_price'];
    }
  }


  async getUserStatus(uid) {
    let userDetails = await this._profileService.userStatus('users', uid);
    if (userDetails.length > 0) {
      if (userDetails[0]['status'] == 'Inactive') {
        this.signOut();
      }
    }
  }

}
