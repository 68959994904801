<div class="page-heading row">
    <div class="heading col-sm-12 ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item "><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Profile</li>
            </ol>
        </nav>
        <h2>Manage Profile</h2>
    </div>
</div>


<div class="row">
    <!-- Aside container -->

    <app-sidebar class="col-md-12 col-lg-3 pt-3"></app-sidebar>

    <!-- Aside container end-->

    <!-- Profile container -->
    <div class="col-md-9 col-lg-9">

        <div class="filter-tab ">
            <ul class="list-unstyled list-inline">
                <li class="list-inline-item "><a routerLink="/manage-profile/personal">Personal Details</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/educational">Education</a></li>
                <li class="list-inline-item "><a routerLink="/manage-profile/skills">Skill</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/interest">Interest</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/fitness">Fitness</a></li>
                <li class="list-inline-item active"><a routerLink="/manage-profile/social">Social Profile</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/certificate">Certifications</a></li>
                <li class="list-inline-item"><a routerLink="/manage-profile/language">Language</a></li>
            </ul>
        </div>

        <div class="data-list p-3 mb-3 ">
            <div class="row " *ngIf="show==false">
                <div class="col-md-12">
                    <div class="pull-right"><a class="btn btn-primary-outline m-0 " (click)="addSocialProfile()"
                            href="javascript:void(0)">+ Add
                            Online Profile</a>
                    </div>
                    <h5 class="font-weight-bold">Online Profiles</h5>
                    <p class="form-msg">Add links to your social profiles (e.g. Linkedin Profile,Facebook Profile etc)
                    </p>
                </div>
            </div>

            <div class="accordion mt-3" id="accordionskill" *ngIf="show==false">

                <div class="card bg-form mb-3" *ngFor="let socialProfile of details;let k=index">
                    <div class="cardHeader ">
                        <div class="row">

                            <div class="col-md-12">
                                <div class="pull-right"><a href="javascript:void(0)" (click)="showForm(k)"
                                        class="btn btn-secondary-outline btn-small mr-2">Edit</a>
                                </div>
                                <div class="profile-details">
                                    <h3 class="mb-0"> {{socialProfile.profile_name}} </h3>
                                    <ul>
                                        <li><a href="{{socialProfile.profile_url}}" style="color: blue;"
                                                target="_blank">{{socialProfile.profile_url}}</a></li>
                                        <li>{{socialProfile.profile_description}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <form #formDir="ngForm" name="formDir" [formGroup]="aspirant_social"
                (ngSubmit)="detailsid ? updateDetails(aspirant_social) : !detailsid ? addDetails(aspirant_social):addDetails(aspirant_social)"
                ngNativeValidate>


                <div formArrayName="SocialProfile" *ngIf="show==true">

                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div class=" panel-default">

                            <div id="collapseOne" class="panel-collapse " role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">

                                    <div [formGroupName]="i" id="socialid"
                                        *ngFor="let profile of getProfiles(aspirant_social); let i = index"
                                        [ngClass]="selectedIndex!=i?myClass:''">

                                        <div class="row">
                                            <div class="col-md-6"><label>Social Profile <span
                                                        class="required">*</span></label>
                                                <div class="form-group">
                                                    <select class="form-control" formControlName="profile_name">
                                                        <option value="">Please select profile</option>
                                                        <option *ngFor="let list of profile_list" value="{{list}}">
                                                            {{list}}</option>
                                                    </select>
                                                    <small *ngIf="profile.get('profile_name').invalid && submitted"
                                                        style="color:red">
                                                        This is required </small>

                                                </div>
                                            </div>

                                            <div class="col-md-6"><label>URL<span class="required">*</span></label>
                                                <div class="form-group"><input type="text" class="form-control"
                                                        formControlName="profile_url"
                                                        placeholder="Enter Social Profile Url">
                                                    <!-- <small *ngIf="profile.get('profile_url').invalid && submitted"
                                                        style="color:red">
                                                        This is required </small>
                                                    <small  *ngIf="profile.get('profile_url').errors?.pattern?.requiredPattern"
                                                        style="color:red">
                                                        Please enter valid url </small>     -->

                                                    <div *ngIf="submitted && profile.get('profile_url').errors"
                                                        style="color:red">
                                                        <small *ngIf="profile.get('profile_url').errors.required">This
                                                            is required</small>
                                                        <small
                                                            *ngIf="profile.get('profile_url').errors?.pattern?.requiredPattern">
                                                            Please enter valid url</small>
                                                    </div>
                                                    <small style="color:red" *ngIf="msg">
                                                        Please enter valid url of selected profile</small>
                                                </div>
                                            </div>

                                            <div class="col-md-6"><label>Description</label>
                                                <div class="form-group">
                                                    <textarea class="form-control"
                                                        placeholder="Enter Social Description" #desc
                                                        (keyup)="count(desc.value)" maxlength="500" rows="8" cols="50"
                                                        formControlName="profile_description">
                                                Enter Social Description
                                                </textarea>
                                                    <span class="pull-right char"><em>{{ characters }} characters
                                                            left</em></span>
                                                </div>
                                            </div>

                                            <input type="hidden" formControlName="external_id">
                                            <input type="hidden" formControlName="created_at">
                                        </div>

                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-12 text-right">
                                            <button class="btn btn-secondary-outline" *ngIf="cancelButton == true"
                                                (click)="cancel()">Cancel</button>
                                            <button class="btn btn-primary" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- <pre>
  {{aspirant_social.value | json}}
</pre> -->


<div class="loading" *ngIf="isLoading == true">Loading&#8230;</div>