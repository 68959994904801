import { Component, OnInit, Input } from '@angular/core';
import { ManageProfileService } from '../manage-profile/manage-profile.service';

@Component({
  selector: 'app-employee-quiz',
  templateUrl: './employee-quiz.component.html',
  styleUrls: ['./employee-quiz.component.css']
})
export class EmployeeQuizComponent implements OnInit {
  userDetails: any;
  userInfo: any;

  constructor(private _profileService: ManageProfileService) { }

  @Input()
  employeeQuizzes = [];

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

}
